import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Title, Subtitle, AddButton } from '@components/atoms';
import { Grid, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '@i18n';
import AuthContext from '@contexts/AuthContext';
import List from './List';
import Create from './Create';
import View from './View';
import Edit from './Edit';

const Presupuestos = ({ location: { search }, match: { params: { action = 'list', id = '' } } }) => {
  const { pages: { employees: { presupuestos: texts } } } = i18n;
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const handleAddClick = () => {
    history.push('/e/presupuestos/create');
  };

  const [extraInfo, setExtraInfo] = useState([]);

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      <Grid
        container
        spacing={0}
        className={classes.container}
        alignItems="center"
      >
        {texts.title && (
          <>
            <Grid item xs={12} sm={3} align="left">
              <Title>{texts.title}</Title>
            </Grid>
          </>
        )}
        {action === 'list' && user && user.permissions.presupuesto.create && (
          <Grid item xs={12} sm={9} align="right">
            <AddButton onClick={handleAddClick}>{texts.add}</AddButton>
          </Grid>
        )}
        {(action === 'view' || action === 'edit') && (
          <Grid item xs={12} sm={9} align="right">
            {extraInfo.map((info) => <Typography key={info}>{info}</Typography>)}
          </Grid>
        )}
      </Grid>
      {texts.subtitle && (
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={12} align="left">
            <Subtitle>{texts.subtitle}</Subtitle>
          </Grid>
        </Grid>
      )}
      <Divider />
      <div className={classes.container}>
        {action === 'list' && user && user.permissions.presupuesto.read && <List search={search} />}
        {action === 'create' && user && user.permissions.presupuesto.create && <Create />}
        {action === 'view' && user && user.permissions.presupuesto.read && <View id={id} setExtraInfo={setExtraInfo} />}
        {action === 'edit' && user && user.permissions.presupuesto.update && <Edit id={id} setExtraInfo={setExtraInfo} />}
      </div>
      <Divider />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
}));

Presupuestos.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

Presupuestos.defaultProps = {
  location: {
    search: '',
  },
  match: {
    params: {
      action: 'list',
      id: '',
    },
  },
};

export default Presupuestos;
