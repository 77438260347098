import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServiceEmployees(data = '') {
  try {
    const response = await API.get(`/employee${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/employee/', message: `Hubo un problema al intentar buscar colaboradores. ${message}` });
  }
}

export async function getServiceEmployee(data) {
  try {
    const response = await API.get(`/employee/${data.id}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/employee/', message: `Hubo un problema al intentar buscar colaboradores. ${message}` });
  }
}

export async function postServiceEmployee(data) {
  try {
    const response = await API.post('/employee', data);
    if (response && response.headers && response.headers.location) {
      return response.headers.location;
    }
    return '';
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/employee/', message: `Hubo un problema al intentar crear colaborador. ${message}` });
  }
}

export async function putServiceEmployee(data) {
  try {
    const response = await API.put('/employee', data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/employee/', message: `Hubo un problema al intentar editar colaborador. ${message}` });
  }
}

export async function changeDisabledServiceEmployee(data) {
  try {
    const response = await API.put('/employee/disabled', data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/employee/', message: `Hubo un problema al intentar habilitar/inhabilitar colaborador. ${message}` });
  }
}
