import React, { useContext, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import i18n from '@i18n';
import { useAuth } from '@hooks';
import {
  Alert, Title, Subtitle, Divider,
} from '@components/atoms';
import AuthContext from '@contexts/AuthContext';
import Form from './Form';

const Profile = () => {
  const { pages: { employees: { profile: texts } } } = i18n;
  const classes = useStyles();
  const { logout, sendEmailVerification, sendPasswordResetEmail } = useAuth();
  const { user, auth } = useContext(AuthContext);
  const [sent, setSent] = useState(false);
  const [changed, setChanged] = useState(false);

  const handleClickSendEmail = () => {
    sendEmailVerification();
    setSent(true);
  };

  const handleClickChangePassword = () => {
    sendPasswordResetEmail(user.email);
    setChanged(true);
  };

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={12} align="left">
          <Title>{texts.title}</Title>
        </Grid>
        <Grid item xs={12} align="left">
          <Subtitle>{texts.subtitle}</Subtitle>
        </Grid>
        <Grid item xs={12} align="left">
          <Divider />
        </Grid>
        {user && !user.error && (
        <Grid item xs={12} align="left">
          <Form user={user} auth={auth} texts={texts.form} />
        </Grid>
        )}
      </Grid>
      <Grid container spacing={1} className={classes.changeContainer} justifyContent="flex-end">
        {user && user.error && <Alert>{user.error.message}</Alert>}
      </Grid>
      <Grid container spacing={1} className={classes.changeContainer} justifyContent="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleClickChangePassword} disabled={changed} className={changed ? classes.disabledButton : null}>
            {changed ? texts.changedPassword : texts.changePassword}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.container} justifyContent="flex-end">
        {auth && !auth.email_verified && (
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleClickSendEmail} disabled={sent} className={sent ? classes.disabledButton : null}>
            {sent ? texts.sentEmail : texts.resendEmail}
          </Button>
        </Grid>
        )}
        <Grid item>
          <Button variant="contained" color="primary" onClick={logout}>{texts.logout}</Button>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
  changeContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
}));

export default Profile;
