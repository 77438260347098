import * as validator from 'yup';

import i18n from '@i18n';

const editPresupuestoSchema = validator.object({
  customer: validator.object({
    id: validator.string().required(i18n.validationSchemas.customer.required),
    name: validator.string(),
  }),
  contact: validator.object({
    id: validator.string().required(i18n.validationSchemas.contact.required),
    name: validator.string(),
    lastname: validator.string(),
  }),
  number: validator
    .string()
    .required(i18n.validationSchemas.number.required),
  description: validator
    .string(),
  state: validator.object({
    id: validator.string().required(i18n.validationSchemas.state.required),
    name: validator.string(),
  }),
});

export default editPresupuestoSchema;
