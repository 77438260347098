import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, TextField, LinearProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import { useEmployees, useAnalisis } from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, MultiFileUpload, Stepper, Divider, DatePicker, DataGrid,
} from '@components/atoms';
import { TranferList } from '@components/molecules';
import { createAsignacionAnalisisSchema } from '@helpers/schemas';
import constants from '@helpers/constants';
import AuthContext from '@contexts/AuthContext';
import moment from 'moment';
import EnsayosModal from './ensayosModal';

const Asignacion = ({ data }) => {
  const { pages: { employees: { analisis: { asignacion: texts } } } } = i18n;
  const history = useHistory();
  const { getEmployees, isEmployeeLoading, employeeError } = useEmployees();
  const { user } = useContext(AuthContext);
  const [showButtonContinue, setShowButtonContinue] = useState(false);
  const [showButtonArchive, setShowButtonArchive] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const {
    id, sample, area, limitDate,
  } = data;
  const [ensayos, setEnsayos] = useState([]);
  const {
    editAnalisis, isAnalisisLoading, analisisError, getFiles, archiveAnalisis,
  } = useAnalisis();
  const [analisis, setAnalisis] = useState({
    customer: '',
    presupuesto: {},
    docsFichaIngreso: [],
    docsPlantilla: [],
    number: '',
    limitDate,
    members: [],
    executeDate: moment().format('YYYY-MM-DD'),
  });
  const [listEmployees, setEmployees] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const form = useFormik({
    initialValues: analisis,
    enableReinitialize: true,
    validationSchema: createAsignacionAnalisisSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const {
        docsPlantilla, members, customer, executeDate,
      } = values;
      if (ensayos.length === 0) {
        enqueueSnackbar('Debe seleccionar al menos un ensayo', { variant: 'warning' });
        return;
      }
      const updated = await editAnalisis({
        id,
        files: docsPlantilla,
        filesType: 'plantilla',
        step: 'ejecucion',
        executeDate,
        limitDate: moment(values.limitDate.toString().replace('Z', '')).format('YYYY-MM-DD'),
        members,
        customer,
        sample,
        area,
        ensayos,
      });

      if (updated) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/analisis');
      }
    },
  });

  const handleAgain = async () => {
    const updated = await editAnalisis({
      id,
      step: 'fichaingreso',
      sample,
      area,
    });

    if (updated) {
      enqueueSnackbar(texts.success, { variant: 'success' });
      history.push('/e/analisis');
    }
  };

  useEffect(async () => {
    setEmployees(await getEmployees('?roles.name=Laboratorista'));
    const docsPlantilla = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'plantilla' });
    const docsFichaIngreso = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'ingreso' });
    setAnalisis({
      ...data,
      limitDate: limitDate ? moment(limitDate.toString().replace('Z', '')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      executeDate: moment().format('YYYY-MM-DD'),
      docsFichaIngreso, // es necesario?
      docsPlantilla: docsPlantilla || [],
      members: listEmployees,
    });
    setEnsayos(data.ensayos || []);
    const isAdministrador = user.roles.find((rol) => (rol.id === constants.ROLES.ADMINISTRADOR));
    const isJefeLaboratorio = user.roles.find((rol) => (rol.id === constants.ROLES.JEFE_LABORATORIO));
    const isSecretaria = user.roles.find((rol) => (rol.id === constants.ROLES.SECRETARIA));
    const isAsistente = user.roles.find((rol) => (rol.id === constants.ROLES.ASISTENTE_GERENCIA));
    setShowButtonContinue(isAdministrador || isJefeLaboratorio || isAsistente);
    setShowButtonArchive(isAdministrador || isSecretaria);
  }, []);

  const handleArchived = async () => {
    const response = await archiveAnalisis(analisis.id);
    if (response) {
      enqueueSnackbar(texts.archived, { variant: 'warning' });
      history.push('/e/analisis');
    }
  };

  return (
    <>
      {analisisError && <Alert>{analisisError.message}</Alert>}
      {employeeError && <Alert>{employeeError.message}</Alert>}
      {openDialog && (
        <EnsayosModal
          title={texts.selectEnsayos}
          setShowDialog={setOpenDialog}
          showDialog={openDialog}
          dataSelected={ensayos}
          setDataSelected={setEnsayos}
        />
      )}

      { (isAnalisisLoading || isEmployeeLoading) ? <LinearProgress /> : (
        <>
          <Stepper activeStep="asignacionlab" steps={constants.STEPS_ANALISIS} />
          <Grid container spacing={2}>
            {texts.subtitleData && (
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            )}
            {analisis.presupuesto
            && (
            <Grid item xs={12} sm={3} md={3}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label={texts.presupuesto}
                  variant="outlined"
                  value={analisis.presupuesto.number}
                  disabled
                />
              </FormControl>
            </Grid>
            )}

            <Grid item xs={12} sm={3} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.number}
                  variant="outlined"
                  value={analisis.number}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docsFichaIngreso}
                  urls={analisis.docsFichaIngreso}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                label={texts.limitDate}
                value={form.values.limitDate}
                onChange={(date) => form.setFieldValue('limitDate', date.target.value)}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TranferList
                itemList={listEmployees}
                onChange={(value) => {
                  form.setFieldValue('members', value);
                }}
                error={form.touched.members && Boolean(form.errors.members)}
                helperText={form.touched.members && form.errors.members && form.errors.members}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docsPlantilla}
                  name="docsPlantilla"
                  onChange={form.handleChange}
                  value={form.values.docsPlantilla}
                  error={form.touched.docsPlantilla && Boolean(form.errors.docsPlantilla)}
                  helperText={form.touched.docsPlantilla && form.errors.docsPlantilla && form.errors.docsPlantilla}
                  multiple
                  disabled={isAnalisisLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={5} md={5}>
              <DatePicker
                label={texts.executeDate}
                value={form.values.executeDate}
                onChange={(date) => form.setFieldValue('executeDate', date.target.value)}
                error={form.touched.executeDate && Boolean(form.errors.executeDate)}
              />
            </Grid>
            {/* #region Ensayos */}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={6} style={{ alignContent: 'center', padding: '10px' }}>
                  <Subtitle>Metodos de Ensayos Seleccionados:</Subtitle>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'end', padding: '10px' }}>
                  <ButtonAdorned onClick={() => setOpenDialog(true)}>
                    {texts.addEnsayos}
                  </ButtonAdorned>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                { ensayos.length === 0 ? <Subtitle color="secondary">No hay ensayos seleccionados</Subtitle>
                  : (
                    <DataGrid
                      columns={[
                        { field: 'material', headerName: 'Material', flex: 0.3 },
                        { field: 'ensayo', headerName: 'Ensayo', flex: 1 },
                        { field: 'metodo', headerName: 'Metodo', flex: 1 },
                      ]}
                      rows={ensayos}
                      key="id"
                    />
                  )}
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent="space-between" direction="row-reverse" alignItems="center" style={{ marginTop: '20px' }}>
              <Grid item>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <ButtonAdorned color="secondary" onClick={handleAgain}>
                      {texts.again}
                    </ButtonAdorned>
                  </Grid>
                  <Grid item>
                    <ButtonAdorned color="default" onClick={() => history.push('/e/analisis')}>
                      {texts.list}
                    </ButtonAdorned>
                  </Grid>
                  {showButtonContinue && (
                    <Grid item>
                      <form onSubmit={form.handleSubmit}>
                        <ButtonAdorned loading={isAnalisisLoading} disabled={isAnalisisLoading} type="submit">
                          {texts.save}
                        </ButtonAdorned>
                      </form>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {showButtonArchive
              && (
              <Grid item>
                <ButtonAdorned color="secondary" onClick={handleArchived}>
                  {texts.archive}
                </ButtonAdorned>
              </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Asignacion.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape(),
};

Asignacion.defaultProps = {
  id: '',
  data: {},
};

export default Asignacion;
