const getSortedArrayOfObjects = (array = [], index = 'none') => {
  const newArray = array.sort((a, b) => {
    const fa = a[index].toLowerCase();
    const fb = b[index].toLowerCase();
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return newArray;
};

export default getSortedArrayOfObjects;
