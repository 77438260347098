export default {
  title: 'Iniciar sesión',
  subtitle: '',
  emailInput: 'Correo electrónico',
  passwordInput: 'Contraseña',
  emailForgotPasswordInput: 'Ingresa aquí tu correo',
  continue: 'Siguiente',
  forgotPassword: '¿Olvidaste tu contraseña?',
  textForgotPassword: 'Te enviaremos un correo para que puedas reestablecer tu contraseña',
  textSentForgotPassword: 'Un correo de recuperación de contraseña ha sido enviado',
  buttonForgotPassword: 'Enviar correo',
  validation: 'Valida tu informe aqui',
};
