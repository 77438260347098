import React from 'react';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';

const ChipStep = ({ label, backgroundColor, color }) => (
  <>
    {label && (
    <Chip
      style={{
        width: '100%',
        height: '2.3rem',
        backgroundColor,
        color,
      }}
      label={label}
    />
    )}
  </>
);

ChipStep.propTypes = {
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

ChipStep.defaultProps = {
  label: '',
  backgroundColor: '#000000',
  color: '#fff',
};

export default ChipStep;
