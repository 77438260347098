const employees = {
  title: 'Clientes',
  add: 'Agregar cliente',
  list: {
    title: 'Cliente',
    columns: {
      name: 'Nombre',
      rut: 'Rut',
    },
  },
  create: {
    name: 'Nombre *',
    namePlaceholder: 'Ej: Eyzaguirre y Asociados SpA',
    address: 'Dirección *',
    addressPlaceholder: 'Ej: Gran Avenida 1111, San Miguel, RM',
    rut: 'Rut *',
    rutPlaceholder: 'Ej: 17111111-0',
    giro: 'Giro',
    giroPlaceholder: 'Ej: Servicios de seguridad privada',
    save: 'Crear',
    list: 'Volver a la lista',
    subtitleData: 'Datos del cliente',
    success: 'Cliente creado exitosamente',
    agreement: 'Convenio',
  },
  view: {
    name: 'Nombre',
    address: 'Dirección',
    rut: 'Rut',
    giro: 'Giro',
    edit: 'Editar',
    list: 'Volver a la lista',
    subtitleData: 'Datos del cliente',
    contacts: 'Ver contactos',
    agreement: 'Convenio',
  },
  edit: {
    name: 'Nombre *',
    namePlaceholder: 'Ej: Eyzaguirre y Asociados SpA',
    address: 'Dirección *',
    addressPlaceholder: 'Ej: Gran Avenida 1111, San Miguel, RM',
    rut: 'Rut *',
    rutPlaceholder: 'Ej: 17111111-0',
    giro: 'Giro',
    giroPlaceholder: 'Ej: Servicios de seguridad privada',
    save: 'Guardar',
    list: 'Volver a la lista',
    todelete: 'Eliminar',
    deleteSuccess: 'Cliente eliminado exitosamente!',
    subtitleData: 'Datos del cliente',
    success: 'Cliente editado exitosamente',
    contacts: 'Contactos',
    agreement: 'Convenio',
  },
};

export default employees;
