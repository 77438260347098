import * as validator from 'yup';

import i18n from '@i18n';
import emailSchema from './emailSchema';
import passwordSchema from './passwordSchema';

export default validator.object({
  customer: validator.object({
    id: validator.string().required(i18n.validationSchemas.customer.required),
    name: validator.string(),
  }),
  title: validator
    .string(),
  name: validator
    .string()
    .required(i18n.validationSchemas.name.required),
  lastname: validator
    .string()
    .required(i18n.validationSchemas.lastname.required),
  phoneNumber: validator
    .string().min(7, i18n.validationSchemas.phoneNumber.required)
    .max(14, i18n.validationSchemas.phoneNumber.required),
  email: emailSchema,
  password: passwordSchema,
});
