import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getAuth() {
  try {
    const response = await API.get('/auth');
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    throw ApiError({ code: 'api/auth/', message: 'Hubo un problema al intentar buscar datos' });
  }
}
