import React from 'react';
import { Switch as MuiSwitch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const Switch = (props) => (
  <SwitchStyled {...props} />
);

const SwitchStyled = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(MuiSwitch);

export default Switch;
