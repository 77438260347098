import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField, InputLabel, Select, MenuItem, ListItemText, Checkbox, InputAdornment, IconButton, OutlinedInput,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import {
  Alert, ButtonAdorned, Subtitle, ChipDisabled,
} from '@components/atoms';
import { editEmployeeSchema } from '@helpers/schemas';
import { getRutNotFormatted, getRutFormatted } from '@helpers/utils';
import { useEmployees, useRoles } from '@hooks';

const Edit = ({ id }) => {
  const classes = useStyles();
  const { pages: { employees: { employees: { edit: texts } } } } = i18n;
  const history = useHistory();
  const { getRoles, rolesError, isRolesLoading } = useRoles();
  const {
    getEmployee, editEmployee, isEmployeeLoading, employeeError, changeDisabledEmployee,
  } = useEmployees();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [employee, setEmployee] = useState({
    id,
    title: '',
    name: '',
    lastname: '',
    rut: '',
    phoneNumber: '',
    roles: [],
    email: '',
    password: '',
    disabled: false,
  });
  const [roles, setRoles] = useState([]);

  const refreshEmployee = async () => {
    const serviceEmployee = await getEmployee({ id });
    setEmployee({
      ...employee,
      title: serviceEmployee.title || '',
      name: serviceEmployee.name || '',
      lastname: serviceEmployee.lastname || '',
      rut: getRutFormatted(serviceEmployee.rut) || '',
      phoneNumber: serviceEmployee.phoneNumber || '',
      roles: serviceEmployee.roles || [],
      email: serviceEmployee.email || '',
      disabled: serviceEmployee.disabled || false,
    });
  };

  useEffect(async () => {
    setRoles(await getRoles());
    refreshEmployee();
  }, []);

  const form = useFormik({
    initialValues: employee,
    enableReinitialize: true,
    validationSchema: editEmployeeSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const edited = await editEmployee({
        id: values.id,
        title: values.title,
        name: values.name,
        lastname: values.lastname,
        rut: getRutNotFormatted(values.rut),
        phoneNumber: values.phoneNumber,
        roles: values.roles,
        email: values.email && values.email !== '' ? values.email : undefined,
        password: values.password && values.password !== '' ? values.password : undefined,
      });
      if (edited) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/colaboradores');
      }
    },
  });

  const onClickDisable = async () => {
    await changeDisabledEmployee({ id: employee.id });
    await refreshEmployee();
  };

  return (
    <>
      {rolesError && <Alert>{rolesError.message}</Alert>}
      {employeeError && <Alert>{employeeError.message}</Alert>}
      {(isRolesLoading || isEmployeeLoading) ? <LinearProgress /> : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.rut}
                  variant="outlined"
                  value={getRutFormatted(form.values.rut)}
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="title"
                  label={texts.title}
                  placeholder={texts.titlePlaceholder}
                  variant="outlined"
                  value={form.values.title}
                  onChange={form.handleChange}
                  error={form.touched.title && Boolean(form.errors.title)}
                  helperText={form.touched.title && form.errors.title}
                  disabled={isEmployeeLoading}
                  autoComplete="new-title"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="name"
                  label={texts.name}
                  placeholder={texts.namePlaceholder}
                  variant="outlined"
                  value={form.values.name}
                  onChange={form.handleChange}
                  error={form.touched.name && Boolean(form.errors.name)}
                  helperText={form.touched.name && form.errors.name}
                  disabled={isEmployeeLoading}
                  autoComplete="new-name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="lastname"
                  label={texts.lastname}
                  placeholder={texts.lastnamePlaceholder}
                  variant="outlined"
                  value={form.values.lastname}
                  onChange={form.handleChange}
                  error={form.touched.lastname && Boolean(form.errors.lastname)}
                  helperText={form.touched.lastname && form.errors.lastname}
                  disabled={isEmployeeLoading}
                  autoComplete="new-lastname"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="phoneNumber"
                  label={texts.phoneNumber}
                  placeholder={texts.phoneNumberPlaceholder}
                  variant="outlined"
                  value={form.values.phoneNumber}
                  onChange={form.handleChange}
                  error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
                  helperText={form.touched.phoneNumber && form.errors.phoneNumber}
                  disabled={isEmployeeLoading}
                  autoComplete="new-phoneNumber"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id={texts.rol}>{texts.rol}</InputLabel>
                <Select
                  name="roles"
                  label={texts.rol}
                  value={form.values.roles}
                  multiple
                  onChange={form.handleChange}
                  error={form.touched.roles && Boolean(form.errors.roles)}
                  disabled={isEmployeeLoading}
                  renderValue={(rolesForm) => rolesForm.map((rol) => rol.name).join(', ')}
                >
                  {roles.map((rol) => (
                    <MenuItem key={rol.id} value={form.values.roles.find((rolForm) => rolForm.id === rol.id) || rol}>
                      <Checkbox checked={!!form.values.roles.find((rolForm) => rolForm.id === rol.id)} />
                      <ListItemText primary={rol.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleAccount}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.email}
                  variant="outlined"
                  value={form.values.email}
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="contact-password">{texts.password}</InputLabel>
                <OutlinedInput
                  id="contact-password"
                  name="password"
                  type={!showPassword ? 'text' : 'password'}
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  placeholder={texts.passwordPlaceholder}
                  value={form.values.password}
                  onChange={form.handleChange}
                  error={form.touched.password && Boolean(form.errors.password)}
                  helperText={form.touched.password && form.errors.password}
                  disabled={isEmployeeLoading}
                  autoComplete="new-password"
                  labelWidth={200}
                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
              {employee.disabled && (
                <Grid item>
                  <ChipDisabled />
                </Grid>
              )}
              <Grid item>
                <ButtonAdorned color="default" onClick={onClickDisable}>
                  {employee.disabled ? texts.toenable : texts.todisable}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push('/e/colaboradores')}>
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <form onSubmit={form.handleSubmit}>
                  <ButtonAdorned loading={isEmployeeLoading} disabled={isEmployeeLoading} type="submit">
                    {texts.save}
                  </ButtonAdorned>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string,
};

Edit.defaultProps = {
  id: '',
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
}));

export default Edit;
