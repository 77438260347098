import { createTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/data-grid';

const Theme = createTheme({
  palette: {
    primary: { main: '#003566' },
    secondary: { main: '#cb2129' },
    error: { main: '#f44336' },
    success: { main: '#007f5f' },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'initial',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px',
      },
    },
    MuiListSubheader: {
      root: {
        fontWeight: 'bold',
        color: '#000',
        opacity: '.7',
        fontSize: '1em',
        lineHeight: '32px',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '13px',
      },
      head: {
        fontWeight: 'bold',
      },
    },
  },
}, esES);

export default Theme;
