import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getDateCustom } from '@helpers/utils';

const DatePicker = ({
  label, value, onChange, disabled, ...rest
}) => {
  const classes = useStyles();
  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        label={label}
        type="date"
        variant="outlined"
        disabled={disabled}
        onChange={onChange}
        value={value || getDateCustom(new Date(), 'yyyy-MM-D')}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      />
    </form>
  );
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
}));

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  disabled: false,
  onChange: () => {},
};

export default DatePicker;
