import {
  Grid, TextField, FormControl, IconButton, useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useInformes } from '@hooks';
import { ButtonAdorned, DataGrid } from '@components/atoms';
import MainHeaderNoAuth from '@components/organisms/MainHeaderNoAuth';
import { useFormik } from 'formik';
import { readInformeSchema } from '@helpers/schemas';
import { getDate } from '@helpers/utils';
import GetAppIcon from '@material-ui/icons/GetApp';

const ValidationPage = () => {
  const [informe, setInforme] = useState([]);
  const {
    isInformeLoading, getFiles, getFilesCert, getInformeNoAuth,
  } = useInformes();
  const theme = useTheme();
  const onlyxsSize = useMediaQuery(theme.breakpoints.only('xs'));

  const columnsSm = [
    {
      field: 'contact',
      headerName: 'Cliente', // texts.columns.customer,
      valueGetter: (values) => values.row.data.contact.customer.name,
    },
    {
      field: 'analisis',
      headerAlign: 'center',
      headerName: 'Ingreso', // texts.columns.number,
      align: 'center',
      valueGetter: (values) => values.row.data.analisis.number,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: 'Creado', // texts.columns.createdAt,
      align: 'center',
      type: 'date',
      valueFormatter: (values) => getDate(values.row.data.createdAt),
    },
    {
      field: 'file',
      headerAlign: 'center',
      headerName: 'Informe', // texts.columns.createdAt,
      align: 'center',
      valueFormatter: (values) => values.row.name,
    },
    {
      field: 'download',
      headerAlign: 'center',
      headerName: 'Descarga', // texts.columns.createdAt,
      align: 'center',
      // valueFormatter: (values) => getDate(values.row.data.createdAt),
      renderCell: (value) => (
        <IconButton onClick={() => getURLPDF(value)}>
          {' '}
          <GetAppIcon />
          {' '}
        </IconButton>
      ),
    },
  ];

  const columns = [
    {
      field: 'contact',
      headerName: 'Cliente', // texts.columns.customer,
      flex: 0.5,
      valueGetter: (values) => values.row.data.contact.customer.name,
    },
    {
      field: 'analisis',
      headerAlign: 'center',
      headerName: 'Ingreso', // texts.columns.number,
      align: 'center',
      flex: 0.4,
      valueGetter: (values) => values.row.data.analisis.number,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: 'Creado', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.row.data.createdAt),
    },
    {
      field: 'file',
      headerAlign: 'center',
      headerName: 'Informe', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      valueFormatter: (values) => values.row.name,
    },
    {
      field: 'download',
      headerAlign: 'center',
      headerName: 'Descarga', // texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      // valueFormatter: (values) => getDate(values.row.data.createdAt),
      renderCell: (value) => (
        <IconButton onClick={() => getURLPDF(value)}>
          {' '}
          <GetAppIcon />
          {' '}
        </IconButton>
      ),
    },
  ];

  const getURLPDF = async (value) => {
    const fileURL = await getFilesCert({ customerId: value.row.data.contact.customer.id, facturacionId: value.row.data.id, name: value.row.name });
    if (fileURL) {
      const t = await fetch(fileURL);
      const b = await t.blob();
      const blobURL = URL.createObjectURL(b);
      const fileLink = document.createElement('a');
      fileLink.href = blobURL;

      // it forces the name of the downloaded file
      fileLink.download = value.row.name;

      // triggers the click event
      fileLink.click();

      // window.open(blobURL);
    }
    return fileURL;
  };

  const getFilePDF = async (customerId, facturacionId) => {
    try {
      const doc = await getFiles({ customerId, facturacionId });
      form.setFieldValue('docInforme', doc);
      return doc;
    } catch (error) {
      return [];
    }
  };

  const form = useFormik({
    initialValues: {
      docInforme: [],
      codigo: '',
      informe: informe || '',
    },
    enableReinitialize: true,
    validationSchema: readInformeSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      setInforme([]);
      const result = await getInformeNoAuth(values.codigo);
      if (result) {
        const files = await getFilePDF(result.contact.customer.id, result.id);
        if (files) setInforme(files.map((_files) => ({ ..._files, data: result })));
      }
    },
  });

  return (
    <>
      <MainHeaderNoAuth />
      <Grid container justifyContent="center" spacing={2} style={{ paddingTop: 100 }}>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              placeholder="Código de validación"
              name="codigo"
              onChange={form.handleChange}
              value={form.values.codigo}
              error={form.touched.codigo && Boolean(form.errors.codigo)}
              helperText={form.touched.codigo && form.errors.codigo && form.errors.codigo}
            />
          </FormControl>
        </Grid>
        <Grid item fullWidth>
          <form onSubmit={form.handleSubmit}>
            <ButtonAdorned variant="outlined" loading={isInformeLoading} type="submit">Buscar</ButtonAdorned>
          </form>
        </Grid>
        { !isInformeLoading && informe.length !== 0
          && (
            <Grid item xs={12} style={{ padding: 60 }}>
              <DataGrid
                columns={onlyxsSize ? columnsSm : columns}
                rows={informe}
              />
            </Grid>
          )}

      </Grid>
    </>
  );
};

export default ValidationPage;
