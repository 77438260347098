import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, IconButton, Title,
} from '@components/atoms';
import { Messaging } from '@components/molecules';
import { Grid, LinearProgress, Badge } from '@material-ui/core';
import { Mail as MailIcon, ArrowBack as ArrowBackIcon, RefreshTwoTone as RefreshTwoToneIcon } from '@material-ui/icons';
import { useChatEmployees } from '@hooks';

const DrawerChat = ({ analisisId }) => {
  const [openDrawerChat, setOpenDrawerChat] = useState(false);
  const [content, setContent] = useState('');
  const [messaging, setMessaging] = useState([]);
  const {
    sendMessage, getMessaging, isStateLoading,
  } = useChatEmployees();

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawerChat(open);
  };

  const refreshMessaging = async () => {
    setMessaging(await getMessaging(analisisId));
    setContent('');
  };

  useEffect(async () => {
    refreshMessaging();
  }, []);

  const handleClickMessage = async () => {
    if (content) {
      await sendMessage(analisisId, { content });
      await refreshMessaging();
    }
  };

  return (
    <>
      <IconButton onClick={toggleDrawer(true)}>
        <Badge color="primary" variant="dot" invisible={!isStateLoading && !messaging}>
          <MailIcon color="secondary" />
        </Badge>
      </IconButton>
      <Drawer
        open={openDrawerChat}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="baseline">
              <Grid item>
                <Title>
                  <IconButton onClick={toggleDrawer(false)}>
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                  Registro de mensajería
                  <IconButton onClick={() => refreshMessaging()}>
                    <RefreshTwoToneIcon color="secondary" fontSize="small" />
                  </IconButton>
                </Title>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {isStateLoading ? <LinearProgress /> : (
              <Messaging
                title=" "
                placeholder="Nuevo mensaje"
                items={messaging?.messages || []}
                state={{ editAllowed: true }}
                message={content}
                setMessage={setContent}
                loading={isStateLoading}
                handleClickMessage={handleClickMessage}
              />
            ) }
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

DrawerChat.propTypes = {
  analisisId: PropTypes.string,
};

DrawerChat.defaultProps = {
  analisisId: '',
};
export default DrawerChat;
