import React, { useEffect, useState } from 'react';
import { FormControl, Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { forgotPasswordSchema } from '@helpers/schemas';
import { useAuth } from '@hooks';
import i18n from '@i18n';
import { AlertDialog } from '@components/atoms';

const ForgotPassword = ({ email }) => {
  const { pages: { login: texts } } = i18n;
  const [dialog, setDialog] = useState({ open: false });
  const { sendPasswordResetEmail } = useAuth();
  const form = useFormik({
    initialValues: {
      emailForgotPassword: email,
    },
    validationSchema: forgotPasswordSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      setDialog({ ...dialog, loading: true });
      await sendPasswordResetEmail(values.emailForgotPassword);
      setDialog({
        ...dialog, loading: false, text: texts.textSentForgotPassword, sent: true,
      });
    },
  });

  useEffect(() => {
    form.values.emailForgotPassword = email;
  }, [email]);

  const handleClickForgotPassword = async () => {
    setDialog({ text: texts.textForgotPassword, open: true });
  };

  return (
    <>
      <AlertDialog
        {...dialog}
        extraOption={!dialog.sent && (
        <form onSubmit={form.handleSubmit}>
          <Button variant="contained" color="primary" disabled={dialog.loading} type="submit">
            {texts.buttonForgotPassword}
          </Button>
        </form>
        )}
        onClose={() => { setDialog({ ...dialog, open: false }); }}
      >
        {!dialog.sent && (
          <FormControl margin="dense" fullWidth style={{ marginTop: '1em' }}>
            <TextField
              name="emailForgotPassword"
              label={texts.emailForgotPasswordInput}
              variant="outlined"
              value={form.values.emailForgotPassword}
              onChange={form.handleChange}
              error={form.touched.emailForgotPassword && Boolean(form.errors.emailForgotPassword)}
              helperText={form.touched.emailForgotPassword && form.errors.emailForgotPassword}
            />
          </FormControl>
        )}
      </AlertDialog>
      <Button onClick={handleClickForgotPassword} color="primary">
        {texts.forgotPassword}
      </Button>
    </>
  );
};

ForgotPassword.propTypes = {
  email: PropTypes.string,
};

ForgotPassword.defaultProps = {
  email: '',
};

export default ForgotPassword;
