import React, { useEffect, useState } from 'react';
import {
  Grid, LinearProgress, FormControl, TextField, InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import i18n from '@i18n';
import { useCustomers } from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle,
} from '@components/atoms';
import { getRutFormatted } from '@helpers/utils';
import { CheckCircleRounded as CheckIcon, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';

const View = ({ id }) => {
  const classes = useStyles();
  const { pages: { employees: { customers: { view: texts } } } } = i18n;
  const history = useHistory();
  const { getCustomer, isCustomerLoading, customerError } = useCustomers();
  const [customer, setCustomer] = useState(null);

  useEffect(async () => {
    setCustomer(await getCustomer({ id }));
  }, []);

  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {isCustomerLoading && <LinearProgress /> }
      {customer && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.rut}
                  value={customer.rut ? getRutFormatted(customer.rut) : ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.name}
                  value={customer.name || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.giro}
                  value={customer.giro || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.address}
                  value={customer.address || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.agreement}
                  value={customer.agreement ? 'Con convenio' : 'Sin convenio'}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {customer.agreement ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push('/e/clientes')}>
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push(`/e/contactos?customer.id=${customer.id}`)}>
                  {texts.contacts}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <ButtonAdorned loading={isCustomerLoading} disabled={isCustomerLoading} onClick={() => history.push(`/e/clientes/edit/${customer.id}`)}>
                  {texts.edit}
                </ButtonAdorned>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

View.propTypes = {
  id: PropTypes.string,
};

View.defaultProps = {
  id: '',
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
}));

export default View;
