import React from 'react';
import PropTypes from 'prop-types';
import { InsertDriveFileOutlined as InsertDriveFileOutlinedIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/es';
import { DataGrid } from '@components/atoms';

moment.locale('es');
const History = ({ history }) => {
  const classes = useStyles();
  const columns = [
    {
      field: 'url',
      headerName: 'PDF',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (values) => (
        <IconButton
          className={classes.actionIcons}
          onClick={() => window.open(values.row.url, '_blank')}
        >
          <InsertDriveFileOutlinedIcon />
        </IconButton>
      ),
    },
    {
      field: 'timeCreated',
      headerName: 'Fecha Creacion',
      flex: 2,
      valueGetter: (values) => moment(values.row.timeCreated).format('LLLL'),
    },
  ];

  return (
    <DataGrid
      className={classes.dataGrid}
      columns={columns}
      rows={history}
    />
  );
};
History.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};

History.defaultProps = {
  history: [{}],
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
  dataGrid: {
    marginTop: '16px',
  },
});

export default History;
