import React, { useEffect, useState } from 'react';
import {
  Grid, FormControl, TextField, TextareaAutosize,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import {
  useContacts, useCustomers, useSolicitudes, useStates,
} from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, Select, MultiFileUpload, Autocomplete,
} from '@components/atoms';
import { createSolicitudSchema } from '@helpers/schemas';
import constants from '@helpers/constants';

const Create = () => {
  const { pages: { employees: { solicitudes: { create: texts } } } } = i18n;
  const history = useHistory();
  const { getContacts, isContactLoading, contactError } = useContacts();
  const { getCustomers, isCustomerLoading, customerError } = useCustomers();
  const { getStates, isStateLoading, stateError } = useStates();
  const { createSolicitud, isSolicitudLoading, solicitudError } = useSolicitudes();

  const [customers, setCustomers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [states, setStates] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: {
      customer: '',
      contact: '',
      files: [],
      title: '',
      description: '',
      state: states.length > 0 ? states.find((state) => state.id === constants.STATE.DISPONIBLE) : '',
    },
    enableReinitialize: true,
    validationSchema: createSolicitudSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { customer, ...rest } = values;
      const created = await createSolicitud(rest);
      if (created) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/solicitudes');
      }
    },
  });

  useEffect(async () => {
    const serviceStates = await getStates();
    setStates(serviceStates.filter((state) => state.newStateAllowed.employee));
    setCustomers(await getCustomers());
  }, []);

  const onChangeCustomer = async (_, value) => {
    form.setFieldValue('contact', '');
    form.setFieldValue('customer', value);
    setContacts([]);
    if (value && value.id) {
      setContacts(await getContacts(`?customer.id=${value.id}`));
    }
  };

  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {contactError && <Alert>{contactError.message}</Alert>}
      {stateError && <Alert>{stateError.message}</Alert>}
      {solicitudError && <Alert>{solicitudError.message}</Alert>}
      <Grid container spacing={2}>
        {texts.subtitleData && (
          <Grid item xs={12} align="left">
            <Subtitle>{texts.subtitleData}</Subtitle>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
              label={texts.customer}
              key={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
              defaultValue={form.values.customer}
              onChange={onChangeCustomer}
              error={form.touched.customer && Boolean(form.errors.customer)}
              disabled={isCustomerLoading}
              helperText={form.touched.customer && form.errors.customer && form.errors.customer.id}
              items={customers}
              close
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              key={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
              label={texts.contact}
              defaultValue={form.values.contact}
              onChange={(_, value) => form.setFieldValue('contact', value)}
              error={form.touched.contact && Boolean(form.errors.contact)}
              disabled={isContactLoading}
              helperText={(form.values.customer && contacts.length === 0 && !isContactLoading && texts.noContacts)
                || (form.touched.contact && form.errors.contact && form.errors.contact.id)}
              items={contacts}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <TextField
              name="title"
              placeholder={texts.title}
              value={form.values.title}
              variant="outlined"
              onChange={form.handleChange}
              error={form.touched.title && Boolean(form.errors.title)}
              helperText={form.touched.title && form.errors.title}
              disabled={isContactLoading}
              autoComplete="new-title"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl fullWidth>
            <TextareaAutosize
              style={{ borderColor: '#e0e0e0', fontFamily: 'Arial, Roboto', overflow: 'auto' }}
              name="description"
              placeholder={texts.descriptionPlaceholder}
              minRows={10}
              value={form.values.description}
              onChange={form.handleChange}
              error={form.touched.description && Boolean(form.errors.description)}
              helperText={form.touched.description && form.errors.description}
              disabled={isContactLoading}
              autoComplete="new-description"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <MultiFileUpload
              title={texts.file}
              name="files"
              onChange={form.handleChange}
              value={form.values.files}
              multiple
              error={form.touched.files && Boolean(form.errors.files)}
              helperText={form.touched.files && form.errors.files && form.errors.files}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <Select
              name="state"
              label={texts.state}
              value={form.values.state}
              onChange={form.handleChange}
              error={form.touched.state && Boolean(form.errors.state)}
              disabled={isStateLoading}
              helperText={form.touched.state && form.errors.state && form.errors.state.id}
              items={states}
            />
          </FormControl>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <ButtonAdorned color="default" onClick={() => history.push('/e/solicitudes')}>
              {texts.list}
            </ButtonAdorned>
          </Grid>
          <Grid item>
            <form onSubmit={form.handleSubmit}>
              <ButtonAdorned loading={isSolicitudLoading} disabled={isSolicitudLoading} type="submit">
                {texts.save}
              </ButtonAdorned>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Create.propTypes = {
};

Create.defaultProps = {
};

export default Create;
