import React, { useContext } from 'react';
import {
  makeStyles, Hidden, Drawer, useTheme, List, ListItem, ListItemIcon, ListItemText, ListSubheader,
} from '@material-ui/core';
import {
  AccountCircleOutlined as PerfilIcon,
  ExitToAppOutlined as LogoutIcon,
  InsertDriveFileOutlined as PresupuestoIcon,
  ReceiptOutlined as FacturacionIcon,
  VerifiedUser as VerifiedUserIcon,
  PostAdd as SolicitudIcon,
} from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import { useAuth } from '@hooks';
import AuthContext from '@contexts/AuthContext';

const CustomerNavBar = ({ mobileOpen, setMobileOpen }) => {
  const { components: { customerNavBar: texts } } = i18n;
  const { logout } = useAuth();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const ListMenu = () => (
    <div>
      <List subheader={(
        <ListSubheader component="div">
          General
        </ListSubheader>
      )}
      >
        <ListItem button component={Link} to="/c/perfil" selected={location.pathname === '/c/perfil'}>
          <ListItemIcon><PerfilIcon color="primary" /></ListItemIcon>
          {user == null ? '' : <ListItemText primary={`${user.name} ${user.lastname}`} secondary={user.customer.name} /> }
        </ListItem>
      </List>

      <List subheader={(
        <ListSubheader component="div">
          Gestión
        </ListSubheader>
      )}
      >
        <ListItem button component={Link} to="/c/solicitudes" selected={location.pathname.includes('/c/solicitudes')}>
          <ListItemIcon><SolicitudIcon color="primary" /></ListItemIcon>
          <ListItemText primary={texts.solicitudes} />
        </ListItem>
        <ListItem button component={Link} to="/c/presupuestos" selected={location.pathname.includes('/c/presupuestos')}>
          <ListItemIcon><PresupuestoIcon color="primary" /></ListItemIcon>
          <ListItemText primary={texts.presupuestos} />
        </ListItem>
        <ListItem button component={Link} to="/c/facturacion" selected={location.pathname.includes('/c/facturacion')}>
          <ListItemIcon><FacturacionIcon color="primary" /></ListItemIcon>
          <ListItemText primary={texts.facturacion} />
        </ListItem>
        <ListItem button component={Link} to="/c/validacion" selected={location.pathname.includes('/c/validacion')}>
          <ListItemIcon><VerifiedUserIcon color="primary" /></ListItemIcon>
          <ListItemText primary={texts.validacion} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          container={undefined}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaperMobile,
          }}
          ModalProps={{ keepMounted: true }}
        >
          <ListMenu />
          <ListItem button component="label" onClick={logout} className={classes.logout}>
            <ListItemIcon><LogoutIcon color="primary" /></ListItemIcon>
            <ListItemText primary={texts.logout} />
          </ListItem>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          <ListMenu />
        </Drawer>
      </Hidden>
    </nav>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    top: 64,
    paddingTop: 8,
  },
  logout: {
    paddingBottom: 15,
    cursor: 'pointer',
  },
  drawerPaperMobile: {
    width: drawerWidth,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

CustomerNavBar.propTypes = {
  mobileOpen: PropTypes.bool,
  setMobileOpen: PropTypes.func,
  auth: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
  }),
};

CustomerNavBar.defaultProps = {
  mobileOpen: false,
  setMobileOpen: () => {},
  auth: {
    displayName: '',
    email: '',
  },
};

export default CustomerNavBar;
