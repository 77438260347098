import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, TextField, TextareaAutosize, LinearProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import i18n from '@i18n';
import {
  useContacts, useCustomers, useSolicitudes, useStates,
} from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, MultiFileUpload,
} from '@components/atoms';

const View = ({ id }) => {
  const { pages: { employees: { solicitudes: { view: texts } } } } = i18n;
  const history = useHistory();
  const { isContactLoading, contactError } = useContacts();
  const { isCustomerLoading, customerError } = useCustomers();
  const { getStates, isStateLoading, stateError } = useStates();
  const {
    isSolicitudLoading, solicitudError, getFiles, getSolicitud,
  } = useSolicitudes();
  const [solicitud, setSolicitud] = useState({
    customer: '',
    contact: '',
    files: [],
    title: '',
    description: '',
    state: '',
    comment: '',
  });

  const refreshSolicitud = async () => {
    const serviceStates = await getStates();
    const serviceSolicitud = await getSolicitud({ id });
    const currentState = serviceStates.find((state) => (state.id === serviceSolicitud.state.id));
    const docsFiles = await getFiles({ customerId: serviceSolicitud.contact.customer.id, solicitudId: id });

    setSolicitud({
      ...solicitud,
      id: serviceSolicitud.id,
      customer: serviceSolicitud.contact ? serviceSolicitud.contact.customer : '',
      contact: serviceSolicitud.contact || '',
      files: docsFiles || [],
      title: serviceSolicitud.title || '',
      description: serviceSolicitud.description || '',
      state: currentState || {},
    });
  };

  useEffect(async () => {
    await refreshSolicitud();
  }, []);

  return (
    <>
      {solicitudError && <Alert>{solicitudError.message}</Alert>}
      {contactError && <Alert>{contactError.message}</Alert>}
      {customerError && <Alert>{customerError.message}</Alert>}
      {stateError && <Alert>{stateError.message}</Alert>}
      { (isCustomerLoading || isContactLoading || isStateLoading || isSolicitudLoading) ? <LinearProgress /> : (
        <>
          <Grid container spacing={2}>
            {texts.subtitleData && (
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label={texts.customer}
                  value={solicitud.customer.name || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label={texts.contact}
                  value={`${solicitud.contact.name} ${solicitud.contact.lastname}`
                  || ' - '}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  label={texts.title}
                  value={solicitud.title}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField disabled value={texts.titles.history} />
              <FormControl fullWidth>
                <TextareaAutosize
                  style={{ borderColor: '#e0e0e0', fontFamily: 'Arial, Roboto', overflow: 'auto' }}
                  name="description"
                  minRows={10}
                  value={solicitud.description}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.file}
                  urls={solicitud.files}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label={texts.state}
                  value={solicitud.state.name}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push('/e/solicitudes')}>
                  {texts.list}
                </ButtonAdorned>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

View.propTypes = {
  id: PropTypes.string,
};

View.defaultProps = {
  id: '',
};

export default View;
