import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url, ...props }) => {
  const classes = useStyles();

  return (
    <ReactPlayer
      className={classes.root}
      url={url}
      controls
      playing
      loop
      {...props}
    />
  );
};

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    weight: '100%',
  },
}));

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
