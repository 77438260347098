import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import i18n from '@i18n';
import { GridBorderContainer } from '@components/atoms';
import { useHistory } from 'react-router-dom';

const Forbidden = () => {
  const { pages: { general: { forbidden: texts } } } = i18n;
  const classes = useStyles();
  const history = useHistory();

  const onClick = () => {
    history.push('/');
  };

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      <Grid container spacing={0} className={classes.container}>
        <GridBorderContainer>
          <Grid item xs={12} align="center">
            <Typography className={classes.title}>{texts.title}</Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Button variant="contained" color="primary" onClick={onClick}>
              {texts.root}
            </Button>
          </Grid>
        </GridBorderContainer>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
}));

export default Forbidden;
