import { useState, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import constants from '@helpers/constants';
import {
  getServiceFacturaciones, getServiceFacturacion, putServiceFacturacion, getServiceFacturacionCount,
} from '../services/facturacion';

const stateFacturacionId = 'stateFacturacion.id';
const pendientePago = `${stateFacturacionId}=${constants.STATE_FACTURACION.PENDIENTE_PAGO.id}`;
const pagado = `${stateFacturacionId}=${constants.STATE_FACTURACION.PAGADO.id}`;
const convenio = `${stateFacturacionId}=${constants.STATE_FACTURACION.CONVENIO.id}`;
const queryFacturacion = `?${pendientePago}&${pagado}&${convenio}`;

const useFacturacion = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const uploadFiles = useCallback(async ({
    files, customerId, facturacionId,
  }) => {
    const promises = files.map(async (file) => {
      const refStorage = firebase.storage().ref(`${customerId}/facturacion/${facturacionId}/${file.name}`);
      await refStorage.put(file);
      const fileURL = await refStorage.getDownloadURL();
      return fileURL;
    });
    const fileURLs = await Promise.all(promises);
    return fileURLs;
  }, []);

  const removeFiles = useCallback(async ({
    customerId, facturacionId,
  }) => {
    const refStorage = firebase.storage().ref(`${customerId}/facturacion/${facturacionId}`);
    const listResults = await refStorage.listAll();
    const promises = listResults.items.map((item) => item.delete());
    Promise.all(promises);
  }, []);

  const getFacturaciones = useCallback(async () => {
    setState({ loading: true, error: null });
    try {
      const facturaciones = await getServiceFacturaciones(queryFacturacion);
      setState({ loading: false, error: null });
      return facturaciones;
    } catch (e) {
      setState({ loading: false, error: e });
      return [];
    }
  });

  const getFacturacion = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const facturacion = await getServiceFacturacion(data);
      setState({ loading: false, error: null });
      return facturacion;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const getFiles = useCallback(async ({ customerId, facturacionId }) => {
    try {
      setState({ loading: true, error: null });
      const refStorage = firebase.storage().ref();
      const folder = refStorage.child(`${customerId}/facturacion/${facturacionId}`);
      const getData = (await folder.listAll()).items.map(async (detail) => {
        const metaData = await detail.getMetadata();
        const url = await detail.getDownloadURL();
        return {
          timeCreated: metaData.timeCreated, url, id: url, name: metaData.name,
        };
      });
      const result = await Promise.all(getData);
      setState({ loading: false, error: null });
      return result;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  }, []);

  const editFacturacion = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const {
        docFactura,
        facturacion, stateFacturacion,
        folio, amount, expiredDate, accepted, message,
      } = data;
      if (docFactura && docFactura.length > 0 && docFactura[0].size) {
        await removeFiles({
          facturacionId: facturacion.id,
          customerId: facturacion.contact.customer.id,
        });
        await uploadFiles({
          files: docFactura,
          facturacionId: facturacion.id,
          customerId: facturacion.contact.customer.id,
        });
      }
      await putServiceFacturacion({
        id: facturacion.id,
        stateFacturacion,
        folio,
        amount,
        expiredDate,
        accepted,
        message,
      });
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const getFacturacionCount = useCallback(async () => {
    setState({ loading: true, error: null });
    try {
      const facturacionCount = await getServiceFacturacionCount(queryFacturacion);
      setState({ loading: false, error: null });
      return facturacionCount;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  return {
    isFacturacionLoading: state.loading,
    facturacionError: state.error,
    getFacturaciones,
    getFacturacion,
    editFacturacion,
    getFiles,
    getFacturacionCount,
  };
};

export default useFacturacion;
