const contacts = {
  title: 'Contactos',
  add: 'Agregar contacto',
  list: {
    title: 'Contactos',
    subtitleCustomer: 'Cliente',
    columns: {
      customer: 'Cliente',
      title: 'Título',
      name: 'Nombre',
      lastname: 'Apellido',
      completeName: 'Nombre completo',
      email: 'Email',
      phoneNumber: 'Teléfono',
    },
  },
  create: {
    customer: 'Cliente',
    customerPlaceholder: 'Ej: Eyzaguirre y Asociados SpA',
    title: 'Título',
    titlePlaceholder: 'Ej: Sr, Sra',
    name: 'Nombre *',
    namePlaceholder: 'Ej: Juan',
    lastname: 'Apellido *',
    lastnamePlaceholder: 'Ej: González',
    phoneNumber: 'Teléfono',
    phoneNumberPlaceholder: 'Ej: 971111111',
    email: 'Email *',
    emailPlaceholder: 'Ej: juan@eyz.cl',
    password: 'Contraseña *',
    passwordPlaceholder: 'Procure que sea segura',
    save: 'Crear',
    list: 'Volver a la lista',
    subtitleData: 'Datos del contacto',
    subtitleAccount: 'Cuenta del contacto',
    success: 'Contacto creado exitosamente',
  },
  view: {
    customer: 'Cliente',
    title: 'Título',
    name: 'Nombre',
    lastname: 'Apellido',
    phoneNumber: 'Teléfono',
    email: 'Email',
    password: 'Contraseña',
    edit: 'Editar',
    list: 'Volver a la lista',
    subtitleData: 'Datos del contacto',
    subtitleAccount: 'Cuenta del contacto',
  },
  edit: {
    customer: 'Cliente',
    customerPlaceholder: 'Ej: Eyzaguirre y Asociados SpA',
    title: 'Título',
    titlePlaceholder: 'Ej: Sr, Sra',
    name: 'Nombre *',
    namePlaceholder: 'Ej: Juan',
    lastname: 'Apellido *',
    lastnamePlaceholder: 'Ej: González',
    phoneNumber: 'Teléfono',
    phoneNumberPlaceholder: 'Ej: 971111111',
    email: 'Email *',
    emailPlaceholder: 'Ej: juan@caltex.cl',
    password: 'Contraseña (solo si desea cambiarla)',
    passwordPlaceholder: 'Procure que sea segura',
    todisable: 'Inhabilitar',
    toenable: 'Habilitar',
    save: 'Guardar',
    todelete: 'Eliminar',
    deleteSuccess: 'Contacto eliminado exitosamente!',
    list: 'Volver a la lista',
    subtitleData: 'Datos del contacto',
    subtitleAccount: 'Cuenta del contacto',
    success: 'Contacto editado exitosamente',
  },
};

export default contacts;
