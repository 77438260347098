import * as validator from 'yup';

import i18n from '@i18n';

const createFacturacionSchema = validator.object({
  customer: validator.object({
    id: validator.string().required(i18n.validationSchemas.customer.required),
    name: validator.string(),
  }),
  facturacion: validator.object({
    id: validator.string().required(i18n.validationSchemas.facturacion.required),
    number: validator.string(),
  }),
  docFactura: validator
    .array()
    .min(1, i18n.validationSchemas.file.required),
  folio: validator
    .string()
    .required(i18n.validationSchemas.folio.required),
  amount: validator
    .number()
    .moreThan(0, i18n.validationSchemas.number.moreThan)
    .required(i18n.validationSchemas.number.required),
  expiredDate: validator
    .date()
    .required(i18n.validationSchemas.date.required),
  stateFacturacion: validator.object({
    id: validator.string().required(i18n.validationSchemas.state.required),
    name: validator.string(),
  }),
});

export default createFacturacionSchema;
