import React, { useEffect, useState } from 'react';
import {
  ListItem, ListItemText, ListItemSecondaryAction, IconButton, FormHelperText, Chip, CircularProgress,
} from '@material-ui/core';
import {
  PublishOutlined as UploadIcon,
  InsertDriveFileOutlined as PreviewIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import constants from '@helpers/constants';

const MultiFileUpload = (props) => {
  const {
    title, value, onChange, name, disabled, error, helperText, urls, multiple, loading, accepted,
  } = props;
  const [acceptedString, setAcceptedString] = useState('');
  const classes = useStyles();
  const uploadIcon = <UploadIcon />;
  const previewIcon = <PreviewIcon />;
  const handleDelete = () => {
    onChange({
      target: {
        value: [],
        name,
      },
    });
  };

  const handlePreview = (file) => {
    window.open(file.url, '_blank');
  };

  const ListItemChips = () => (
    <>
      {value && value.map((item) => (
        <Chip
          color="secondary"
          className={`${classes.chip} ${classes.files}`}
          label={item.name}
          key={item.name}
          onDelete={() => handleDelete(item)}
          disabled={disabled}
        />
      ))}
    </>
  );

  const onInternalChange = (event) => {
    if (event.target.files.length === 0) {
      onChange(event); return;
    }
    const selectedValues = Array.from(event.target.files);
    onChange({
      ...event,
      target: {
        ...event.target,
        value: selectedValues,
        name,
      },
    });
  };

  useEffect(() => {
    setAcceptedString(accepted.reduce((acc, accept) => acc + (acc.length ? ',' : '') + constants.FILE_TYPES[accept], ''));
  }, [accepted]);

  return (
    <>
      <ListItem className={classes.root} style={error ? { border: '1px solid #f44336' } : null}>
        <ListItemText
          primary={(
            <div className={classes.listItemTextPrimary} style={disabled ? { color: 'rgba(0, 0, 0, 0.38)' } : null}>
              {title}
            </div>
          )}
        />

        <ListItemSecondaryAction>
          {name && (
          <>
            <input
              disabled={disabled}
              accept={acceptedString}
              name={name}
              className={classes.input}
              id={name}
              multiple={multiple}
              type="file"
              onChange={onInternalChange}
            />
            <label htmlFor={name}>
              <IconButton edge="end" aria-label={title} component="span" disabled={disabled} className={classes.icon}>
                {uploadIcon}
              </IconButton>
            </label>
          </>
          )}
        </ListItemSecondaryAction>
        {loading && <CircularProgress color="secondary" size={25} thickness={4} />}
      </ListItem>
      {urls && urls.map((files) => (
        <IconButton edge="end" aria-label={title} component="span" key={files.id} className={classes.icon} onClick={() => handlePreview(files)}>
          <Chip
            color="secondary"
            className={`${classes.chip} ${classes.files}`}
            label={files.name}
            key={files.name}
            onDelete={() => handleDelete(files)}
            disabled={disabled}
            deleteIcon={previewIcon}
          />
        </IconButton>
      ))}
      {error && <FormHelperText variant="outlined" error>{helperText}</FormHelperText>}
      <ListItemChips />

    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    background: '#F4F2F2',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
    marginTop: '2px',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  input: {
    display: 'none',
  },
  listItemTextPrimary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  files: {
    borderRadius: '7px',
    margin: '.1rem 0',
    padding: '0px .5rem',
    justifyContent: 'space-between',
    width: '100%',
  },
  icon: {
    margin: '0px',
    padding: '3px 3px 0px 0px',
  },

}));

MultiFileUpload.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  urls: PropTypes.arrayOf(PropTypes.shape()),
  value: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  loading: PropTypes.bool,
  accepted: PropTypes.arrayOf(PropTypes.string),
};

MultiFileUpload.defaultProps = {
  title: 'Archivo',
  name: undefined,
  urls: [],
  value: [],
  onChange: undefined,
  disabled: false,
  error: false,
  helperText: undefined,
  multiple: false,
  loading: false,
  accepted: ['pdf', 'word'],
};

export default MultiFileUpload;
