import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const ChipMessage = ({ title, align, label }) => {
  const classes = useStyles();
  const stylesBox = {
    right: { borderTopRightRadius: '4px' },
    left: { borderTopLeftRadius: '4px' },
  };

  return (
    <Grid container align={align} spacing={0}>
      <Grid item xs={12}>
        {title && <Typography variant="subtitle2">{title}</Typography>}
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box} style={stylesBox[align]}>{label}</Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  box: {
    display: 'inline-block',
    padding: '8px 16px',
    borderRadius: '24px',
    backgroundColor: '#e0e0e0',
  },
}));

ChipMessage.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  align: PropTypes.string,
};

ChipMessage.defaultProps = {
  label: 'small',
  title: undefined,
  align: 'left',
};

export default ChipMessage;
