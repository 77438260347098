import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServiceEnsayosAll(data = '') {
  try {
    const response = await API.get(`/metodosEnsayo${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/metodosEnsayo/', message: `Hubo un problema al intentar buscar los metodos de ensayo. ${message}` });
  }
}

export async function postServiceEnsayos(data) {
  try {
    const response = await API.post('/metodosEnsayo', data);
    if (response && response.headers && response.headers.location) {
      return response.headers.location;
    }
    return '';
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/metodosEnsayo/', message: `Hubo un problema al intentar crear un metodo de ensayo. ${message}` });
  }
}

export async function putServiceEnsayos(data) {
  try {
    const response = await API.put('/metodosEnsayo/', data);
    if (response && response.headers && response.headers.location) {
      return response.headers.location;
    }
    return '';
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/metodosEnsayo/', message: `Hubo un problema al intentar actualizar un metodo de ensayo. ${message}` });
  }
}

export async function deleteServiceEnsayos(id) {
  try {
    const response = await API.delete('/metodosEnsayo/', { data: { id } });
    if (response && response.headers && response.headers.location) {
      return response.headers.location;
    }
    return '';
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/metodosEnsayo/', message: `Hubo un problema al intentar eliminar un metodo de ensayo. ${message}` });
  }
}
