import customers from './customers';
import employees from './employees';
import general from './general';
import login from './login';

export default {
  customers,
  employees,
  general,
  login,
};
