import { useState, useCallback } from 'react';
import { getServiceRoles } from '../services/roles';

const useRoles = () => {
  const [state, setState] = useState({ loading: false, error: null, roles: [] });

  const getRoles = useCallback(async () => {
    setState({ loading: true, error: null, roles: [] });
    try {
      const rolesService = await getServiceRoles();
      const roles = rolesService.map(({ permissions, ...rest }) => ({ ...rest }));
      setState({ loading: false, error: null, roles });
      return roles;
    } catch (e) {
      setState({ loading: false, error: e, roles: [] });
      return [];
    }
  });

  return {
    isRolesLoading: state.loading,
    rolesError: state.error,
    getRoles,
  };
};

export default useRoles;
