import { useState, useCallback } from 'react';
import {
  getServiceReportIngxarea, getServiceReportEnsayosByQty, getServiceReportEnsayosByLaboratorista, getServiceReportEnsayosByDesviacion, getServiceReportMuestrasByLaboratorista,
} from '../services/reports';

const userReports = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const getIngresosxarea = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const analisisAll = await getServiceReportIngxarea(data);
      setState({ loading: false, error: null });
      return analisisAll;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const getEnsayosByQty = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const analisisAll = await getServiceReportEnsayosByQty(data);
      setState({ loading: false, error: null });
      return analisisAll;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const getEnsayosByLaboratorista = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const analisisAll = await getServiceReportEnsayosByLaboratorista(data);
      setState({ loading: false, error: null });
      return analisisAll;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const getMetodosByLaboratorista = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const analisisAll = await getServiceReportMuestrasByLaboratorista(data);
      setState({ loading: false, error: null });
      return analisisAll;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const getEnsayosByDesviacion = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const analisisAll = await getServiceReportEnsayosByDesviacion(data);
      setState({ loading: false, error: null });
      return analisisAll;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  return {
    isReportLoading: state.loading,
    reportError: state.error,
    getIngresosxarea,
    getEnsayosByQty,
    getEnsayosByLaboratorista,
    getEnsayosByDesviacion,
    getMetodosByLaboratorista,
  };
};

export default userReports;
