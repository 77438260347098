import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField, InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import {
  useFacturacion, useStatesFacturacion, useAnalisis, useInformes,
} from '@hooks';
import { CheckCircleRounded as CheckIcon, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';
import {
  Alert, ButtonAdorned, Select, MultiFileUpload, DatePicker, Subtitle,
} from '@components/atoms';
import { editFacturacionSchema } from '@helpers/schemas';
import { getCompleteName, getDate } from '@helpers/utils';

const Edit = ({ id, setExtraInfo }) => {
  const { pages: { employees: { facturacion: { edit: texts } } } } = i18n;
  const history = useHistory();
  const { getStatesFacturacion, isStateLoading, stateError } = useStatesFacturacion();
  const {
    getFacturacion, editFacturacion, isFacturacionLoading, facturacionError, getFiles: getFilesFacturacion,
  } = useFacturacion();
  const { getFiles: getFileInformeCliente, isInformeLoading } = useInformes();

  const { getFiles: getFilesInformeAnalisis, isAnalisisLoading } = useAnalisis();

  const [states, setStates] = useState([]);
  const [facturacion, setFacturacion] = useState({
    number: '',
    expiredDate: '',
    description: '',
    stateFacturacion: '',
    docInforme: [],
    docInformeCliente: [],
    docFactura: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: facturacion,
    enableReinitialize: true,
    validationSchema: editFacturacionSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const updated = await editFacturacion({
        facturacion,
        docFactura: values.docFactura,
        folio: values.folio,
        amount: values.amount,
        expiredDate: values.expiredDate,
        stateFacturacion: values.stateFacturacion,
      });
      if (updated) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/facturacion');
      }
    },
  });

  useEffect(async () => {
    setExtraInfo([]);
    const serviceFacturacion = await getFacturacion({ id });
    setFacturacion({
      ...serviceFacturacion,
      expiredDate: serviceFacturacion.expiredDate?.substring(0, 10),
    });
  }, []);

  useEffect(async () => {
    if (facturacion?.id) {
      const serviceStates = await getStatesFacturacion();
      const currentState = serviceStates.find((state) => (state.id === facturacion.stateFacturacion.id));
      if (currentState?.editAllowed) {
        setStates(serviceStates);
        const docFactura = await getFilesFacturacion({
          customerId: facturacion?.contact?.customer?.id,
          facturacionId: id,
        });
        const docInforme = await getFilesInformeAnalisis({
          customerId: facturacion?.contact?.customer?.id,
          analisisId: facturacion?.analisis?.id,
          filesType: 'redaccion',
        });
        const docInformeCliente = await getFileInformeCliente({
          customerId: facturacion?.contact?.customer?.id,
          facturacionId: facturacion.id,
        });
        form.setFieldValue('docFactura', docFactura);
        form.setFieldValue('docInforme', docInforme);
        form.setFieldValue('docInformeCliente', docInformeCliente);

        const updated = `Última actualización por: ${getCompleteName(facturacion.updatedBy)} | ${getDate(facturacion.updatedAt)}`;
        const created = `Creado por: ${getCompleteName(facturacion.createdBy)} | ${getDate(facturacion.createdAt)}`;
        setExtraInfo([created || '', updated || '']);
      }
    }
  }, [facturacion]);

  return (
    <>
      {facturacionError && <Alert>{facturacionError.message}</Alert>}
      {stateError && <Alert>{stateError.message}</Alert>}
      { (isStateLoading || isFacturacionLoading || isInformeLoading || isAnalisisLoading) ? <LinearProgress /> : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleInforme}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  name="customer"
                  label={texts.customer}
                  value={form.values.contact?.customer?.name}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  name="contact"
                  variant="outlined"
                  value={`${form.values.contact?.name} ${form.values.contact?.lastname}`}
                  label={texts.contact}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  name="analisis"
                  variant="outlined"
                  label={texts.analisis}
                  value={form.values.analisis && form.values.analisis?.number}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              { form.values.analisis && (
              <FormControl fullWidth>
                <TextField
                  label={texts.agreement}
                  variant="outlined"
                  disabled
                  value={form.values.analisis?.agreement ? 'Con convenio' : 'Sin convenio'}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {(form.values.analisis?.agreement ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />)}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docInforme}
                  urls={form.values.docInforme}
                  disabled
                  loading={isAnalisisLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docInformeCliente}
                  urls={form.values.docInformeCliente}
                  disabled
                  loading={isInformeLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleFacturacion}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docFactura}
                  name="docFactura"
                  onChange={form.handleChange}
                  value={form.values.docFactura}
                  error={form.touched.docFactura && Boolean(form.errors.docFactura)}
                  helperText={form.touched.docFactura && form.errors.docFactura && form.errors.docFactura}
                  multiple
                  loading={isFacturacionLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="folio"
                  label={texts.folio}
                  placeholder={texts.folioPlaceholder}
                  variant="outlined"
                  value={form.values.folio}
                  onChange={form.handleChange}
                  error={form.touched.folio && Boolean(form.errors.folio)}
                  helperText={form.touched.folio && form.errors.folio}
                  disabled={isFacturacionLoading}
                  autoComplete="new-folio"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="amount"
                  label={texts.amount}
                  placeholder={texts.amountPlaceholder}
                  type="number"
                  variant="outlined"
                  value={form.values.amount}
                  onChange={form.handleChange}
                  error={form.touched.amount && Boolean(form.errors.amount)}
                  helperText={form.touched.amount && form.errors.amount}
                  disabled={isFacturacionLoading}
                  autoComplete="new-amount"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <DatePicker
                  label={texts.fechaExp}
                  value={form.values.expiredDate}
                  onChange={(value) => form.setFieldValue('expiredDate', value.target.value)}
                  disabled={isFacturacionLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  name="stateFacturacion"
                  label={texts.state}
                  onChange={form.handleChange}
                  value={form.values.stateFacturacion}
                  error={form.touched.stateFacturacion && Boolean(form.errors.stateFacturacion)}
                  disabled={!form.values.analisis || isFacturacionLoading}
                  helperText={form.touched.stateFacturacion && form.errors.stateFacturacion && form.errors.stateFacturacion.id}
                  items={states}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <ButtonAdorned color="default" onClick={() => history.push('/e/facturacion')}>
                {texts.list}
              </ButtonAdorned>
            </Grid>
            <Grid item>
              <form onSubmit={form.handleSubmit}>
                <ButtonAdorned loading={isFacturacionLoading} disabled={isFacturacionLoading} type="submit">
                  {texts.save}
                </ButtonAdorned>
              </form>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string,
  setExtraInfo: PropTypes.func,
};

Edit.defaultProps = {
  id: '',
  setExtraInfo: () => {},
};

export default Edit;
