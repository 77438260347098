import * as validator from 'yup';

import i18n from '@i18n';

const createInformeSchema = validator.object({
  customer: validator.object({
    id: validator.string().required(i18n.validationSchemas.customer.required),
    name: validator.string(),
  }),
  contact: validator.object({
    id: validator.string().required(i18n.validationSchemas.contact.required),
    name: validator.string(),
    lastname: validator.string(),
  }),
  analisis: validator.object({
    id: validator.string().required(i18n.validationSchemas.informe.required),
    number: validator.string(),
  }),
  docInformeCliente: validator
    .array()
    .min(1, i18n.validationSchemas.informe.required),
});

export default createInformeSchema;
