import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, LinearProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import { useEmployees, useAnalisis } from '@hooks';
import {
  Alert, ButtonAdorned, MultiFileUpload, Stepper, Divider, DatePicker,
} from '@components/atoms';
import { createEjecucionAnalisisSchema } from '@helpers/schemas';
import constants from '@helpers/constants';
import { AnalisisDetails } from '@components/molecules';
import AuthContext from '@contexts/AuthContext';
import moment from 'moment';

const Ejecucion = ({ data }) => {
  const { pages: { employees: { analisis: { ejecucion: texts } } } } = i18n;
  const history = useHistory();
  const { isEmployeeLoading, employeeError } = useEmployees();
  const {
    editAnalisis, isAnalisisLoading, analisisError, getFiles, archiveAnalisis,
  } = useAnalisis();
  const { user } = useContext(AuthContext);
  const [showButtonContinue, setShowButtonContinue] = useState(false);
  const [showButtonArchive, setShowButtonArchive] = useState(false);

  const [analisis, setAnalisis] = useState({
    customer: '',
    presupuesto: {},
    docsResultado: [],
    docsPlantilla: [],
    anexos: [],
    number: '',
    members: [],
  });
  const { id, sample, area } = data;

  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: analisis,
    enableReinitialize: true,
    validationSchema: createEjecucionAnalisisSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { docsResultado, anexos, customer } = values;
      const updated = await editAnalisis({
        id,
        files: docsResultado,
        anexos,
        filesType: 'resultado',
        step: 'revision',
        customer,
        sample,
        area,
      });

      if (updated) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/analisis');
      }
    },
  });

  useEffect(async () => {
    const docsPlantilla = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'plantilla' });
    const docsResultado = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'resultado' });
    const docsIngreso = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'ingreso' });
    const anexos = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'anexos' });
    setAnalisis({
      ...data,
      docsIngreso,
      executeDate: data.executeDate ? moment(data.executeDate.toString().replace('Z', '')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      limitDate: data.limitDate ? moment(data.limitDate.toString().replace('Z', '')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      docsPlantilla,
      anexos: anexos || [],
      docsResultado: docsResultado || [],
    });
    const isAdministrador = user.roles.find((rol) => (rol.id === constants.ROLES.ADMINISTRADOR));
    const isJefeLaboratorio = user.roles.find((rol) => (rol.id === constants.ROLES.JEFE_LABORATORIO));
    const isLaboratorista = user.roles.find((rol) => (rol.id === constants.ROLES.LABORATORISTA));
    const isSecretaria = user.roles.find((rol) => (rol.id === constants.ROLES.SECRETARIA));
    const isAsistente = user.roles.find((rol) => (rol.id === constants.ROLES.ASISTENTE_GERENCIA));

    setShowButtonContinue(isAdministrador || isJefeLaboratorio || isLaboratorista || isAsistente);
    setShowButtonArchive(isAdministrador || isSecretaria);
  }, []);

  const handleAgain = async () => {
    const updated = await editAnalisis({
      id,
      step: 'asignacionlab',
      sample,
      area,
    });

    if (updated) {
      enqueueSnackbar(texts.success, { variant: 'success' });
      history.push('/e/analisis');
    }
  };

  const handleArchived = async () => {
    const response = await archiveAnalisis(analisis.id);
    if (response) {
      enqueueSnackbar(texts.archived, { variant: 'warning' });
      history.push('/e/analisis');
    }
  };

  return (
    <>
      {analisisError && <Alert>{analisisError.message}</Alert>}
      {employeeError && <Alert>{employeeError.message}</Alert>}
      { (isAnalisisLoading || isEmployeeLoading) ? <LinearProgress /> : (
        <>
          <Stepper activeStep="ejecucion" steps={constants.STEPS_ANALISIS} />
          <AnalisisDetails analisis={analisis} />
          <Grid container spacing={2} style={{ marginTop: '0.8rem' }}>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                label={texts.limitDate}
                value={analisis.limitDate}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                label={texts.executeDate}
                value={analisis.executeDate}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.anexos}
                  name="anexos"
                  onChange={form.handleChange}
                  value={form.values.anexos}
                  error={form.touched.anexos && Boolean(form.errors.anexos)}
                  helperText={form.touched.anexos && form.errors.anexos && form.errors.anexos}
                  multiple
                  accepted={['images']}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docsResultado}
                  name="docsResultado"
                  onChange={form.handleChange}
                  value={form.values.docsResultado}
                  error={form.touched.docsResultado && Boolean(form.errors.docsResultado)}
                  helperText={form.touched.docsResultado && form.errors.docsResultado && form.errors.docsResultado}
                  multiple
                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="space-between" direction="row-reverse" alignItems="center" style={{ marginTop: '20px' }}>
              <Grid item>
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <ButtonAdorned color="secondary" onClick={handleAgain}>
                      {texts.again}
                    </ButtonAdorned>
                  </Grid>
                  <Grid item>
                    <ButtonAdorned color="default" onClick={() => history.push('/e/analisis')}>
                      {texts.list}
                    </ButtonAdorned>
                  </Grid>
                  {showButtonContinue && (
                  <Grid item>
                    <form onSubmit={form.handleSubmit}>
                      <ButtonAdorned loading={isAnalisisLoading} disabled={isAnalisisLoading} type="submit">
                        {texts.save}
                      </ButtonAdorned>
                    </form>
                  </Grid>
                  )}
                </Grid>
              </Grid>
              {showButtonArchive
              && (
              <Grid item>
                <ButtonAdorned color="secondary" onClick={handleArchived}>
                  {texts.archive}
                </ButtonAdorned>
              </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Ejecucion.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape(),
};

Ejecucion.defaultProps = {
  id: '',
  data: {},
};

export default Ejecucion;
