import React, { useState } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GridBorderContainer } from '@components/atoms';
import i18n from '@i18n';
import { useAuth } from '@hooks';

const NotEmailVerified = () => {
  const { components: { notEmailVerified: texts } } = i18n;
  const { sendEmailVerification } = useAuth();
  const [sent, setSent] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    sendEmailVerification();
    setSent(true);
  };

  return (
    <div className={classes.root}>
      <GridBorderContainer>
        <Grid item xs={12} align="center">
          <Typography className={classes.title}>{texts.title}</Typography>
          <Typography className={classes.subtitle}>{texts.subtitle}</Typography>
          <Grid item align="center">
            <Button variant="contained" color="primary" onClick={handleClick} disabled={sent} className={sent && classes.disabledButton}>
              {sent ? texts.sentEmail : texts.resendEmail}
            </Button>
          </Grid>
        </Grid>
      </GridBorderContainer>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '2em',
  },
  title: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '1em',
  },
  subtitle: {
    marginBottom: '1em',
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
}));

export default NotEmailVerified;
