export default {

  STATES_SOLICITUDES: [
    { step: 'Pendiente', color: '#FFF', backgroundColor: '#727272' },
    { step: 'Disponible', color: '#FFF', backgroundColor: '#add8e6' },
    { step: 'Aprobado', color: '#FFF', backgroundColor: '#4B992F' },
    { step: 'Rechazado', color: '#FFF', backgroundColor: '#B00020' },
    { step: 'Cerrado', color: '#FFF', backgroundColor: '#2a9d8f' },
  ],

};
