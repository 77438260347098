import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  TextField,
  LinearProgress,
  Divider,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import i18n from '@i18n';
import { usePresupuestos, useStates } from '@hooks';
import {
  Alert,
  ButtonAdorned,
  Subtitle,
  MultiFileUpload,
  ChipStep,
} from '@components/atoms';
import { Messaging } from '@components/molecules';
import constants from '@helpers/constants';
import { getCompleteName, getDate, getStatePresupuesto } from '@helpers/utils';
import History from './History';

const View = ({ id, setExtraInfo }) => {
  const {
    pages: {
      employees: {
        presupuestos: { view: texts },
      },
    },
  } = i18n;
  const history = useHistory();
  const {
    getPresupuesto,
    editPresupuesto,
    isPresupuestoLoading,
    presupuestoError,
    getHistory,
  } = usePresupuestos();
  const { getStates, isStateLoading, stateError } = useStates();
  const [presupuesto, setPresupuesto] = useState(null);
  const [message, setMessage] = useState('');
  const [historyFiles, setHistoryFiles] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [showMessaging, setShowMessaging] = useState(false);

  const handleClickMessage = async () => {
    if (message) {
      await editPresupuesto({ id: presupuesto.id, message });
      setMessage('');
      await refreshPresupuesto();
    }
  };
  const refreshPresupuesto = async () => {
    setExtraInfo([]);

    const serviceStates = await getStates();
    const servicePresupuesto = await getPresupuesto({ id });
    const currentState = serviceStates.find(
      (state) => state.id === servicePresupuesto.state.id,
    );

    const updated = `Última actualización por: ${getCompleteName(servicePresupuesto.updatedBy)} | ${getDate(servicePresupuesto.updatedAt)}`;
    const created = `Creado por: ${getCompleteName(servicePresupuesto.createdBy)} | ${getDate(servicePresupuesto.createdAt)}`;
    setExtraInfo([created || '', updated || '']);

    setPresupuesto({
      ...presupuesto,
      id: servicePresupuesto.id,
      customer: servicePresupuesto.contact
        ? servicePresupuesto.contact.customer
        : '',
      contact: servicePresupuesto.contact || '',
      fileURL: servicePresupuesto.fileURL || '',
      fileBkpURL: servicePresupuesto.fileBkpURL || '',
      number: servicePresupuesto.number || '',
      description: servicePresupuesto.description || '',
      state: currentState || {},
      comment: servicePresupuesto.comment || '',
      messaging: servicePresupuesto.messaging || [],
    });
  };

  const refreshHistory = async () => {
    const serviceHistory = await getHistory({
      customerId: presupuesto.contact.customer.id,
      presupuestoId: id,
    });
    setHistoryFiles(serviceHistory);
  };

  useEffect(async () => {
    await refreshPresupuesto();
  }, []);

  useEffect(async () => {
    if (!presupuesto) {
      return;
    }
    await refreshHistory();
    const hasMessaging = presupuesto.messaging && presupuesto.messaging.length > 0;
    const isStateToMessage = presupuesto.state
      && presupuesto.state.id === constants.STATE.DISPONIBLE;
    setShowMessaging(isStateToMessage || hasMessaging);
  }, [presupuesto]);
  const Form = () => (
    <Grid container spacing={2} style={{ marginBottom: '16px' }}>
      {texts.subtitleData && (
        <Grid item xs={12} align="left">
          <Subtitle>{texts.subtitleData}</Subtitle>
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={4}>
        <FormControl fullWidth>
          <TextField
            label={texts.customer}
            value={presupuesto.contact.customer.name || ''}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth>
          <TextField
            label={texts.contact}
            value={
              `${presupuesto.contact.name} ${presupuesto.contact.lastname}`
              || ' - '
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth>
          <TextField
            label={texts.number}
            value={presupuesto.number || ' - '}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <FormControl fullWidth>
          <TextField
            label={texts.description}
            value={presupuesto.description || ' - '}
            InputProps={{
              readOnly: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth>
          <MultiFileUpload
            name="Presupuesto"
            title={texts.file}
            urls={[{ name: 'Presupuesto', url: presupuesto.fileURL }]}
            disabled
          />
        </FormControl>
      </Grid>
      {presupuesto.fileBkpURL && (
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth>
          <MultiFileUpload
            name="Aprobacion"
            title={texts.fileBkp}
            urls={[{ name: 'Aprobacion', url: presupuesto.fileBkpURL }]}
            disabled
          />
        </FormControl>
      </Grid>
      )}
      <Grid item xs={12} md={6} lg={4}>
        <FormControl fullWidth>
          <ChipStep
            label={getStatePresupuesto(presupuesto.state.name).step}
            backgroundColor={getStatePresupuesto(presupuesto.state.name).backgroundColor}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <FormControl fullWidth>
          {presupuesto.comment && (
            <TextField
              label={texts.comment}
              value={presupuesto.comment}
              InputProps={{
                readOnly: true,
              }}
            />
          )}
        </FormControl>
      </Grid>
    </Grid>
  );

  const Buttons = () => (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <ButtonAdorned
          color="default"
          onClick={() => setShowHistory(!showHistory)}
        >
          {showHistory ? texts.history.hide : texts.history.show}
        </ButtonAdorned>
      </Grid>
      <Grid item>
        <ButtonAdorned
          color="default"
          onClick={() => history.push('/e/presupuestos')}
        >
          {texts.list}
        </ButtonAdorned>
      </Grid>
      {presupuesto.state.editAllowed && (
        <Grid item>
          <ButtonAdorned
            onClick={() => history.push(
              `/e/presupuestos/edit/${presupuesto.id}`,
            )}
          >
            {texts.edit}
          </ButtonAdorned>
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {presupuestoError && <Alert>{presupuestoError.message}</Alert>}
      {stateError && <Alert>{stateError.message}</Alert>}
      {(isPresupuestoLoading || isStateLoading) && <LinearProgress />}
      {!isPresupuestoLoading && !isStateLoading && presupuesto && (
        <>
          <Grid container spacing={2}>
            {showMessaging && (
              <>
                <Grid item xs={12} md={4}>
                  <Messaging
                    items={presupuesto.messaging}
                    state={presupuesto.state}
                    message={message}
                    setMessage={setMessage}
                    handleClickMessage={handleClickMessage}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md={1}
                  justifyContent="center"
                >
                  <Divider orientation="vertical" />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={showMessaging ? 7 : 12}>
              <Form />
              <Buttons />
              {showHistory ? (
                <History history={historyFiles} />
              ) : null}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

View.propTypes = {
  id: PropTypes.string,
  setExtraInfo: PropTypes.func,
};

View.defaultProps = {
  id: '',
  setExtraInfo: () => { },
};

export default View;
