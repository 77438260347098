import * as validator from 'yup';
import i18n from '@i18n';

const editSolicitudesSchema = validator.object({
  customer: validator.object({
    id: validator.string().required(i18n.validationSchemas.customer.required),
    name: validator.string(),
  }),
  contact: validator.object({
    id: validator.string().required(i18n.validationSchemas.contact.required),
    name: validator.string(),
    lastname: validator.string(),
  }),
  title: validator.string().required(i18n.validationSchemas.title.required),
  description: validator.string().required(i18n.validationSchemas.description.required),
  state: validator.object({
    id: validator.string().required(i18n.validationSchemas.state.required),
    name: validator.string(),
  }),
  comment: validator
    .string()
    .when(['state'], (state, schema) => (state.name === 'Cerrado' ? schema.required(i18n.validationSchemas.comment.required) : schema.optional())),
});

export default editSolicitudesSchema;
