import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Divider } from '..';

const DialogMui = ({
  open, onClose, title, content, actions, size, ...rest
}) => (
  <Dialog open={open} onClose={onClose} maxWidth={size} keepMounted {...rest}>
    <DialogTitle>{title}</DialogTitle>
    <Divider />
    <DialogContent>{content}</DialogContent>
    <DialogActions>
      {actions.map((action) => (
        <Button
          key={action.label}
          onClick={action.onClick}
          color={action.color}
          disabled={action.disabled}
          variant="contained"
        >
          {action.label}
        </Button>
      ))}
    </DialogActions>
  </Dialog>
);

DialogMui.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

DialogMui.defaultProps = {
  size: 'md',
  actions: [],
};

export default DialogMui;
