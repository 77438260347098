import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = ({ severity, children, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiAlert severity={severity} {...props}>{children}</MuiAlert>
    </div>
  );
};

Alert.propTypes = {
  severity: PropTypes.string,
  children: PropTypes.node,
};

Alert.defaultProps = {
  severity: 'error',
  children: {},
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

export default Alert;
