const getRutFormatted = (value = '') => {
  const cleaned = value.replaceAll('.', '').replaceAll('-', '');

  const body = cleaned.slice(0, -1);
  const dv = cleaned.slice(-1).toUpperCase();

  return `${body}-${dv}`;
};

export default getRutFormatted;
