import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '@i18n';
import { Alert } from '@components/atoms';
import { Chart } from 'react-google-charts';
import { Grid, LinearProgress } from '@material-ui/core';
import { useReports } from '@hooks';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Filtros from './Filtros';

const EnsayosByQty = () => {
  const { pages: { employees: { reporteria: SubReportsTexts } } } = i18n;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { getEnsayosByQty, reportError, isReportLoading } = useReports();
  const [report, setReport] = useState(null);
  const [filters, setFilters] = useState({
    dateInit: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    all: false,
    archivados: false,
    generate: false,
  });

  useEffect(async () => {
    if (filters.generate) {
      if (moment(filters.dateEnd).isBefore(filters.dateInit) && !filters.all) {
        enqueueSnackbar('La fecha de inicio no puede ser mayor a la fecha final', { variant: 'error' });
      } else {
        const result = await getEnsayosByQty(filters);
        setReport(result);
      }
    }
    setFilters({ ...filters, generate: false });
  },
  [filters.generate]);
  const options = {
    title: 'Cantidad de Ensayos',
  };

  const optionsTable = {
    pageSize: 10,
    width: '90%',
    height: '100%',
    sortAscending: false,
    sortColumn: 1,
  };

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${SubReportsTexts.subreports.ingresosxarea.title}`}</title>
      </Helmet>
      <Filtros filters={filters} setFilters={setFilters} />
      {reportError && <Alert>{reportError.message}</Alert>}
      {isReportLoading && <LinearProgress />}
      {report && (
        <Grid container spacing={0} classes={classes.container} style={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={report}
              options={options}
            />
          </Grid>
          <Grid item xs={12}>
            <Chart
              chartType="Table"
              width="100%"
              data={report}
              options={optionsTable}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
  changeContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
}));

EnsayosByQty.propTypes = {
};

EnsayosByQty.defaultProps = {
};

export default EnsayosByQty;
