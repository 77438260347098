import { useState, useCallback } from 'react';
import { getServiceMessagings, postServiceMessaging } from 'src/services/messaging';

const useChatEmployees = () => {
  const [state, setState] = useState({ loading: false, error: null, open: false });

  const openChat = useCallback(async (status) => {
    setState({ loading: true, error: null });
    try {
      setState({ loading: false, error: null, open: status });
      return true;
    } catch (e) {
      setState({ loading: false, error: e, open: false });
      return false;
    }
  });

  const getMessaging = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const messaging = await getServiceMessagings(data);
      setState({ ...state, loading: false, error: null });
      return messaging;
    } catch (e) {
      setState({ ...state, loading: false, error: e });
      return null;
    }
  });

  const sendMessage = useCallback(async (id, data) => {
    setState({ loading: true, error: null });
    try {
      await postServiceMessaging(id, data);
      setState({ ...state, loading: false, error: null });
      return true;
    } catch (e) {
      setState({ ...state, loading: false, error: e });
      return false;
    }
  });

  return {
    isStateLoading: state.loading,
    statesError: state.error,
    openChat,
    getMessaging,
    sendMessage,
  };
};

export default useChatEmployees;
