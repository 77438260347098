import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField, InputLabel, Select, MenuItem, FormHelperText, InputAdornment, IconButton, OutlinedInput,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import { useContacts, useCustomers } from '@hooks';
import { Alert, ButtonAdorned, Subtitle } from '@components/atoms';
import { createContactSchema } from '@helpers/schemas';

const Create = () => {
  const classes = useStyles();
  const { pages: { employees: { contacts: { create: texts } } } } = i18n;
  const history = useHistory();
  const { createContact, isContactLoading, contactError } = useContacts();
  const { getCustomers, isCustomerLoading, customerError } = useCustomers();
  const [customerSelected, setCustomerSelected] = useState(null);
  const [customers, setCustomers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { customer } = history.location.state;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(async () => {
    setCustomers(await getCustomers());
  }, []);

  useEffect(async () => {
    if (customer && customer.id && customers.length !== 0) {
      setCustomerSelected(customers.find((item) => item.id === customer.id));
    }
  }, [customers]);

  const form = useFormik({
    initialValues: {
      customer: customerSelected || '',
      title: '',
      name: '',
      lastname: '',
      phoneNumber: '',
      email: '',
      password: '',
    },
    enableReinitialize: true,
    validationSchema: createContactSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const created = await createContact({
        ...values,
        customer: {
          id: values.customer.id,
          name: values.customer.name,
        },
      });
      if (created) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/contactos');
      }
    },
  });

  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {contactError && <Alert>{contactError.message}</Alert>}
      {isCustomerLoading ? <LinearProgress /> : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id={texts.customer}>{texts.customer}</InputLabel>
                <Select
                  name="customer"
                  label={texts.customer}
                  value={form.values.customer}
                  onChange={form.handleChange}
                  error={form.touched.customer && Boolean(form.errors.customer)}
                  disabled={isCustomerLoading || !!customerSelected}
                >
                  {customers.map((item) => (
                    <MenuItem key={item.id} value={item}>{item.name}</MenuItem>
                  ))}
                </Select>
                {form.errors.customer && form.errors.customer.id && <FormHelperText error>{form.errors.customer.id}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="title"
                  label={texts.title}
                  placeholder={texts.titlePlaceholder}
                  variant="outlined"
                  value={form.values.title}
                  onChange={form.handleChange}
                  error={form.touched.title && Boolean(form.errors.title)}
                  helperText={form.touched.title && form.errors.title}
                  disabled={isContactLoading}
                  autoComplete="new-title"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="name"
                  label={texts.name}
                  placeholder={texts.namePlaceholder}
                  variant="outlined"
                  value={form.values.name}
                  onChange={form.handleChange}
                  error={form.touched.name && Boolean(form.errors.name)}
                  helperText={form.touched.name && form.errors.name}
                  disabled={isContactLoading}
                  autoComplete="new-name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="lastname"
                  label={texts.lastname}
                  placeholder={texts.lastnamePlaceholder}
                  variant="outlined"
                  value={form.values.lastname}
                  onChange={form.handleChange}
                  error={form.touched.lastname && Boolean(form.errors.lastname)}
                  helperText={form.touched.lastname && form.errors.lastname}
                  disabled={isContactLoading}
                  autoComplete="new-lastname"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="phoneNumber"
                  label={texts.phoneNumber}
                  placeholder={texts.phoneNumberPlaceholder}
                  variant="outlined"
                  value={form.values.phoneNumber}
                  onChange={form.handleChange}
                  error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
                  helperText={form.touched.phoneNumber && form.errors.phoneNumber}
                  disabled={isContactLoading}
                  autoComplete="new-phoneNumber"
                />
              </FormControl>
            </Grid>

          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleAccount}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  type="email"
                  name="email"
                  label={texts.email}
                  placeholder={texts.emailPlaceholder}
                  variant="outlined"
                  value={form.values.email}
                  onChange={form.handleChange}
                  error={form.touched.email && Boolean(form.errors.email)}
                  helperText={form.touched.email && form.errors.email}
                  disabled={isContactLoading}
                  autoComplete="new-email"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="contact-password">{texts.password}</InputLabel>
                <OutlinedInput
                  id="contact-password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  placeholder={texts.passwordPlaceholder}
                  value={form.values.password}
                  onChange={form.handleChange}
                  error={form.touched.password && Boolean(form.errors.password)}
                  helperText={form.touched.password && form.errors.password}
                  disabled={isContactLoading}
                  autoComplete="new-password"
                  labelWidth={200}

                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push('/e/contactos')}>
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <form onSubmit={form.handleSubmit}>
                  <ButtonAdorned loading={isContactLoading} disabled={isContactLoading} type="submit">
                    {texts.save}
                  </ButtonAdorned>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Create.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Create.defaultProps = {
  customer: {
    id: '',
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
}));

export default Create;
