import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField, InputLabel, Select, MenuItem, FormHelperText, InputAdornment, IconButton, OutlinedInput,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import { useContacts, useCustomers } from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, ChipDisabled,
} from '@components/atoms';
import { editContactSchema } from '@helpers/schemas';

const Edit = ({ id }) => {
  const classes = useStyles();
  const { pages: { employees: { contacts: { edit: texts } } } } = i18n;
  const history = useHistory();
  const {
    getContact, editContact, isContactLoading, contactError, changeDisabledContact, deleteContact,
  } = useContacts();
  const { getCustomers, isCustomerLoading, customerError } = useCustomers();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [contact, setContact] = useState({
    id,
    customer: '',
    title: '',
    name: '',
    lastname: '',
    phoneNumber: '',
    email: '',
    password: '',
    disabled: false,
  });
  const [customers, setCustomers] = useState([]);

  const refreshContact = async () => {
    const serviceContact = await getContact({ id });
    setContact({
      ...contact,
      customer: serviceContact.customer || '',
      title: serviceContact.title || '',
      name: serviceContact.name || '',
      lastname: serviceContact.lastname || '',
      phoneNumber: serviceContact.phoneNumber || '',
      email: serviceContact.email || '',
      disabled: serviceContact.disabled || false,
    });
  };

  useEffect(async () => {
    setCustomers(await getCustomers());
    refreshContact();
  }, []);

  const form = useFormik({
    initialValues: contact,
    enableReinitialize: true,
    validationSchema: editContactSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const edited = await editContact({
        id: values.id,
        customer: {
          id: values.customer.id,
          name: values.customer.name,
        },
        title: values.title,
        name: values.name,
        lastname: values.lastname,
        phoneNumber: values.phoneNumber,
        email: values.email,
        password: values.password,
        active: values.active,
      });
      if (edited) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/contactos');
      }
    },
  });

  const onClickDisable = async () => {
    await changeDisabledContact({ id: contact.id });
    await refreshContact();
  };

  const onClickDelete = async () => {
    const deleteResponse = await deleteContact(id);
    if (deleteResponse) {
      history.push('/e/contactos');
      enqueueSnackbar(texts.deleteSuccess, { variant: 'success' });
    }
  };
  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {contactError && <Alert>{contactError.message}</Alert>}
      {(isCustomerLoading || isContactLoading) ? <LinearProgress /> : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id={texts.customer}>{texts.customer}</InputLabel>
                <Select
                  name="customer"
                  label={texts.customer}
                  value={form.values.customer}
                  onChange={form.handleChange}
                  error={form.touched.customer && Boolean(form.errors.customer)}
                  disabled={isCustomerLoading}
                >
                  {customers.map((customer) => (
                    <MenuItem
                      key={customer.id}
                      value={form.values.customer.id === customer.id ? form.values.customer : customer}
                    >
                      {customer.name}
                    </MenuItem>
                  ))}
                </Select>
                {form.errors.customer && form.errors.customer.id && <FormHelperText error>{form.errors.customer.id}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="title"
                  label={texts.title}
                  placeholder={texts.titlePlaceholder}
                  variant="outlined"
                  value={form.values.title}
                  onChange={form.handleChange}
                  error={form.touched.title && Boolean(form.errors.title)}
                  helperText={form.touched.title && form.errors.title}
                  disabled={isContactLoading}
                  autoComplete="new-title"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="name"
                  label={texts.name}
                  placeholder={texts.namePlaceholder}
                  variant="outlined"
                  value={form.values.name}
                  onChange={form.handleChange}
                  error={form.touched.name && Boolean(form.errors.name)}
                  helperText={form.touched.name && form.errors.name}
                  disabled={isContactLoading}
                  autoComplete="new-name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="lastname"
                  label={texts.lastname}
                  placeholder={texts.lastnamePlaceholder}
                  variant="outlined"
                  value={form.values.lastname}
                  onChange={form.handleChange}
                  error={form.touched.lastname && Boolean(form.errors.lastname)}
                  helperText={form.touched.lastname && form.errors.lastname}
                  disabled={isContactLoading}
                  autoComplete="new-lastname"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  name="phoneNumber"
                  label={texts.phoneNumber}
                  placeholder={texts.phoneNumberPlaceholder}
                  variant="outlined"
                  value={form.values.phoneNumber}
                  onChange={form.handleChange}
                  error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
                  helperText={form.touched.phoneNumber && form.errors.phoneNumber}
                  disabled={isContactLoading}
                  autoComplete="new-phoneNumber"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleAccount}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.email}
                  variant="outlined"
                  value={form.values.email}
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="contact-password">{texts.password}</InputLabel>
                <OutlinedInput
                  id="contact-password"
                  name="password"
                  type={!showPassword ? 'text' : 'password'}
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  placeholder={texts.passwordPlaceholder}
                  value={form.values.password}
                  onChange={form.handleChange}
                  error={form.touched.password && Boolean(form.errors.password)}
                  helperText={form.touched.password && form.errors.password}
                  disabled={isContactLoading}
                  autoComplete="new-password"
                  labelWidth={200}

                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
              <Grid item>
                <ButtonAdorned color="secondary" onClick={onClickDelete}>
                  {texts.todelete}
                </ButtonAdorned>
              </Grid>
              {contact.disabled && (
              <Grid item>
                <ChipDisabled />
              </Grid>
              )}
              <Grid item>
                <ButtonAdorned color="default" onClick={onClickDisable}>
                  {contact.disabled ? texts.toenable : texts.todisable}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push('/e/contactos')}>
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <form onSubmit={form.handleSubmit}>
                  <ButtonAdorned loading={isContactLoading} disabled={isContactLoading} type="submit">
                    {texts.save}
                  </ButtonAdorned>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string,
};

Edit.defaultProps = {
  id: '',
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
}));

export default Edit;
