import React from 'react';
import {
  Grid, FormControl, TextField, FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import { useCustomers } from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, Switch,
} from '@components/atoms';
import { createCustomerSchema } from '@helpers/schemas';
import { getRutNotFormatted } from '@helpers/utils';

const Create = () => {
  const classes = useStyles();
  const { pages: { employees: { customers: { create: texts } } } } = i18n;
  const history = useHistory();
  const { createCustomer, isCustomerLoading, customerError } = useCustomers();
  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: {
      rut: '',
      name: '',
      giro: '',
      address: '',
      agreement: false,
    },
    validationSchema: createCustomerSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const created = await createCustomer({
        ...values,
        rut: getRutNotFormatted(values.rut),
      });
      if (created) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/clientes');
      }
    },
  });

  const onChangeAgreement = async (_, value) => form.setFieldValue('agreement', value);

  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} align="left">
            <Subtitle>{texts.subtitleData}</Subtitle>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                name="rut"
                label={texts.rut}
                placeholder={texts.rutPlaceholder}
                variant="outlined"
                value={form.values.rut}
                onChange={form.handleChange}
                error={form.touched.rut && Boolean(form.errors.rut)}
                helperText={form.touched.rut && form.errors.rut}
                disabled={isCustomerLoading}
                autoComplete="new-rut"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                name="name"
                label={texts.name}
                placeholder={texts.namePlaceholder}
                variant="outlined"
                value={form.values.name}
                onChange={form.handleChange}
                error={form.touched.name && Boolean(form.errors.name)}
                helperText={form.touched.name && form.errors.name}
                disabled={isCustomerLoading}
                autoComplete="new-name"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                name="giro"
                label={texts.giro}
                placeholder={texts.giroPlaceholder}
                variant="outlined"
                value={form.values.giro}
                onChange={form.handleChange}
                error={form.touched.giro && Boolean(form.errors.giro)}
                helperText={form.touched.giro && form.errors.giro}
                disabled={isCustomerLoading}
                autoComplete="new-giro"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <TextField
                name="address"
                label={texts.address}
                placeholder={texts.addressPlaceholder}
                variant="outlined"
                value={form.values.address}
                onChange={form.handleChange}
                error={form.touched.address && Boolean(form.errors.address)}
                helperText={form.touched.address && form.errors.address}
                disabled={isCustomerLoading}
                autoComplete="new-address"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.switch}>
            <FormControlLabel
              control={(
                <Switch
                  onChange={onChangeAgreement}
                  disabled={isCustomerLoading}
                />
              )}
              label={texts.agreement}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.container}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <ButtonAdorned color="default" onClick={() => history.push('/e/clientes')}>
                {texts.list}
              </ButtonAdorned>
            </Grid>
            <Grid item>
              <form onSubmit={form.handleSubmit}>
                <ButtonAdorned loading={isCustomerLoading} disabled={isCustomerLoading} type="submit">
                  {texts.save}
                </ButtonAdorned>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </>
    </>
  );
};

Create.propTypes = {
};

Create.defaultProps = {
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  switch: {
    alignSelf: 'center',
  },
}));

export default Create;
