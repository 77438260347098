import React from 'react';
import {
  InputLabel, Select as MuiSelect, MenuItem, FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const Select = ({
  label, name, value, onChange, error, disabled, items, helperText, variant,
}) => (
  <>
    <InputLabel>{label}</InputLabel>
    <MuiSelect
      name={name}
      label={label}
      value={value}
      variant={variant}
      onChange={onChange}
      error={error}
      disabled={disabled || items.length === 0}
    >
      {items.map((item) => (
        <MenuItem key={item.id} value={value.id === item.id ? value : item}>
          {`${item.name ? item.name : item.number} ${item.lastname ? item.lastname : ''}`}
        </MenuItem>
      ))}
    </MuiSelect>
    {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </>
);

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  helperText: PropTypes.string,
};

Select.defaultProps = {
  value: {},
  variant: 'standard',
  onChange: () => {},
  error: false,
  disabled: false,
  items: [],
  helperText: undefined,
};

export default Select;
