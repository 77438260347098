const solicitudes = {
  title: 'Solicitudes',
  add: 'Agregar Solicitud',
  list: {
    title: 'Solicitudes',
    columns: {
      customer: 'Cliente',
      contact: 'Contacto',
      file: 'Archivo',
      number: 'Número',
      description: 'Descripción',
      state: 'Estado',
      createdBy: 'Creado por',
      createdAt: 'Fecha creación',
    },
  },
  create: {
    customer: 'Cliente *',
    contact: 'Contacto *',
    noContacts: 'Cliente no tiene contactos',
    file: 'Archivo',
    description: 'Descripción',
    state: 'Estado',
    save: 'Crear',
    list: 'Volver a la lista',
    success: 'solicitud creada exitosamente',
    descriptionPlaceholder: 'Ingrese descripción de su solicitud.',
    title: 'Titulo *',
    titles: {
      history: 'Historial',
      comments: 'Comentarios',
    },
  },
  view: {
    customer: 'Cliente',
    contact: 'Contacto',
    file: 'Archivo',
    number: 'Nro de presupuesto',
    description: 'Descripción',
    state: 'Estado',
    edit: 'Editar',
    comment: 'Comentario del cambio de estado',
    list: 'Volver a la lista',
    subtitleData: 'Datos del presupuesto',
    history: {
      show: 'Mostrar todos los archivos',
      hide: 'Ocultar todos los archivos',
    },
    updatedAt: 'Ultima actualizacion',
  },
  edit: {
    customer: 'Cliente *',
    contact: 'Contacto *',
    noContacts: 'Cliente no tiene contactos',
    file: 'Archivo *',
    title: 'Titulo',
    description: 'Descripción',
    state: 'Estado',
    save: 'Guardar',
    list: 'Volver a la lista',
    comment: 'Comentario del cambio de estado',
    subtitleData: 'Datos de la solicitud',
    success: 'Solicitud editada exitosamente',
    titles: {
      history: 'Historial',
      comments: 'Comentarios',
    },
  },

};

export default solicitudes;
