import React, { useEffect, useState, useContext } from 'react';
import {
  Grid, Menu, MenuItem, LinearProgress, IconButton, Hidden, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  EditOutlined as EditarIcon,
  VisibilityOutlined as VerIcon,
  ContactPhoneOutlined as ContactosIcon,
  ListOutlined as MenuIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton,
} from '@material-ui/data-grid';
import i18n from '@i18n';
import { useCustomers } from '@hooks';
import { Alert, DataGrid } from '@components/atoms';
import { getRutFormatted } from '@helpers/utils';
import AuthContext from '@contexts/AuthContext';

const List = () => {
  const classes = useStyles();
  const history = useHistory();
  const { pages: { employees: { customers: { list: texts } } } } = i18n;
  const { getCustomers, customerError, isCustomerLoading } = useCustomers();
  const [customers, setCustomers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const ltSmSize = useMediaQuery(theme.breakpoints.down('sm'));
  const onlyxsSize = useMediaQuery(theme.breakpoints.only('xs'));

  const menuActions = (values) => (
    <>
      <Hidden xsDown>
        {user && user.permissions.admin.update && (
        <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/clientes/edit/${values.row.id}`)}>
          <EditarIcon />
        </IconButton>
        )}
        {user && user.permissions.admin.read && (
        <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/clientes/view/${values.row.id}`)}>
          <VerIcon />
        </IconButton>
        )}
        <IconButton
          className={classes.actionIcons}
          onClick={() => history.push(
            {
              pathname: '/e/contactos',
              search: `customer.id=${values.row.id}`,
            },
          )}
        >
          <ContactosIcon />
        </IconButton>
      </Hidden>
      <Hidden smUp>
        <IconButton className={classes.actionIcons} onClick={(event) => setAnchorEl(event.currentTarget)}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {user && user.permissions.admin.update && (
          <MenuItem onClick={() => history.push(`/e/clientes/edit/${values.row.id}`)}>Editar</MenuItem>
          )}
          {user && user.permissions.admin.read && (
          <MenuItem onClick={() => history.push(`/e/clientes/view/${values.row.id}`)}>Ver</MenuItem>
          )}
          <MenuItem onClick={() => history.push(`/e/contactos?customer.id=${values.row.id}`)}>Contactos</MenuItem>
        </Menu>
      </Hidden>
    </>
  );

  const columnsSm = [
    {
      field: 'rut',
      headerName: texts.columns.rut,
      valueGetter: (values) => getRutFormatted(values.row.rut),
    },
    {
      field: 'name',
      width: 200,
      headerName: texts.columns.name,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => menuActions(values),
    },
  ];

  const columns = [
    {
      field: 'rut',
      headerName: texts.columns.rut,
      flex: 0.5,
      valueGetter: (values) => getRutFormatted(values.row.rut),
    },
    {
      field: 'name',
      headerName: texts.columns.name,
      flex: 2,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => menuActions(values),
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(async () => {
    setCustomers(await getCustomers());
  }, []);

  const CustomToolbar = () => (
    <>
      <Hidden xsDown>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
      <Hidden smUp>
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
        </GridToolbarContainer>
      </Hidden>
    </>
  );
  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {isCustomerLoading ? <LinearProgress /> : (
        <Grid container spacing={2}>
          <DataGrid
            columns={onlyxsSize ? columnsSm : columns}
            rows={customers}
            pageSize={ltSmSize ? 8 : 25}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
    </>
  );
};

List.propTypes = {
};

List.defaultProps = {
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

export default List;
