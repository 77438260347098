import constants from '@helpers/constants';

const getColorPresupuesto = (step) => constants.STATES_PRESUPUESTO.find((item) => (item.step === step)).backgroundColor;
const getColorFacturacion = (id) => Object.values(constants.STATE_FACTURACION).find((item) => (item.id === id)).backgroundColor;
const getColorAnalisis = (id) => constants.STEPS_ANALISIS.find((item) => (item.step === id)).backgroundColor;

export default {
  getColorPresupuesto,
  getColorFacturacion,
  getColorAnalisis,
};
