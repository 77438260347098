import * as validator from 'yup';

import i18n from '@i18n';

const createEjecucionAnalisisSchema = validator.object({
  docsResultado: validator
    .array()
    .min(1, i18n.validationSchemas.file.required),
});

export default createEjecucionAnalisisSchema;
