import presupuesto from './presupuesto';

export default {
  STATE: {
    PENDIENTE: '60e0f74b5c337ece40c2ad38',
    DISPONIBLE: '60e0f7595c337ece40c2ad39',
    APROBADO: '60e0f8315c337ece40c2ad3a',
    RECHAZADO: '60e0f8395c337ece40c2ad3b',
    CERRADO: '60e0f8415c337ece40c2ad3c',
  },
  FILE_TYPES: {
    pdf: 'application/pdf',
    word: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    images: 'image/png, image/gif, image/jpeg',
  },
  STEPS_ANALISIS: [
    { step: 'fichaingreso', label: 'Ficha Ingreso', backgroundColor: '#727272' },
    { step: 'asignacionlab', label: 'Asignación Lab.', backgroundColor: '#264653' },
    { step: 'ejecucion', label: 'Ejecución Análisis', backgroundColor: '#e9c46a' },
    { step: 'revision', label: 'Revisión', backgroundColor: '#f4a261' },
    { step: 'redaccion', label: 'Redacción Informe', backgroundColor: '#e76f51' },
    { step: 'completado', label: 'Completado', backgroundColor: '#2a9d8f' },
  ],
  STATE_FACTURACION: {
    PENDIENTE_PAGO: { id: '6166587fac44bed53cac8bfc', backgroundColor: '#f4a261' },
    PAGADO: { id: '616658efac44bed53cac8bfd', backgroundColor: '#2a9d8f' },
    CONVENIO: { id: '619d766965936657b353c5d0', backgroundColor: '#007086' },
  },
  STATES_PRESUPUESTO: presupuesto.STATES_PRESUPUESTO,
  ROLES: {
    JEFE_LABORATORIO: '6098685bc94a0e80b63a631f',
    ADMINISTRADOR: '60986805c94a0e80b63a631d',
    LABORATORISTA: '609868a4c94a0e80b63a6320',
    INGENIERO: '609868b5c94a0e80b63a6321',
    SECRETARIA: '60986851c94a0e80b63a631e',
    ASISTENTE_GERENCIA: '663048def4781f58b87e2df6',
  },
  TYPE_AREA: [
    { id: 'textil', name: 'Textil' },
    { id: 'cuero_calzado', name: 'Cuero y Calzado' },
  ],
  STATE_DATES: [
    { step: 'fichaingreso', dateName: 'ingresoDate' },
    { step: 'asignacionlab', dateName: 'ingresoDate' },
    { step: 'ejecucion', dateName: 'asignacionDate' },
    { step: 'revision', dateName: 'ejecucionDate' },
    { step: 'redaccion', dateName: 'revisionDate' },
    { step: 'completado', dateName: 'redaccionDate' },
  ],
};
