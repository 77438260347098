import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, LinearProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import i18n from '@i18n';
import { useAnalisis } from '@hooks';
import { useSnackbar } from 'notistack';
import {
  Alert, ButtonAdorned, MultiFileUpload, Stepper, Divider, DatePicker,
} from '@components/atoms';
import constants from '@helpers/constants';
import { AnalisisDetails, InformationBox } from '@components/molecules';
import AuthContext from '@contexts/AuthContext';
import moment from 'moment';

const Completado = ({ data }) => {
  const { pages: { employees: { analisis: { completado: texts } } } } = i18n;
  const history = useHistory();
  const {
    isAnalisisLoading, analisisError, getFiles, editAnalisis, archiveAnalisis,
  } = useAnalisis();
  const [analisis, setAnalisis] = useState({
    customer: '',
    presupuesto: {},
    docsResultado: [],
    docsPlantilla: [],
    docsRedaccion: [],
    anexos: [],
    number: '',
    members: [],
  });
  const [showButtonArchive, setShowButtonArchive] = useState(false);
  const { user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const { id, sample, area } = data;

  useEffect(async () => {
    const docsPlantilla = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'plantilla' });
    const docsResultado = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'resultado' });
    const docsRedaccion = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'redaccion' });
    const docsIngreso = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'ingreso' });
    const anexos = await getFiles({ customerId: data.customer.id, analisisId: id, filesType: 'anexos' });
    setAnalisis({
      ...data,
      docsIngreso,
      docsPlantilla,
      docsResultado,
      docsRedaccion,
      anexos: anexos || [],
      executeDate: data.executeDate ? moment(data.executeDate.toString().replace('Z', '')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      limitDate: data.limitDate ? moment(data.limitDate.toString().replace('Z', '')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    });

    const isAdministrador = user.roles.find((rol) => (rol.id === constants.ROLES.ADMINISTRADOR));
    const isSecretaria = user.roles.find((rol) => (rol.id === constants.ROLES.SECRETARIA));
    setShowButtonArchive(isAdministrador || isSecretaria);
  }, []);

  const handleAgain = async () => {
    const updated = await editAnalisis({
      id,
      step: 'redaccion',
      sample,
      area,
    });

    if (updated) {
      enqueueSnackbar(texts.success, { variant: 'success' });
      history.push('/e/analisis');
    }
  };
  const handleArchived = async () => {
    const response = await archiveAnalisis(analisis.id);
    if (response) {
      enqueueSnackbar(texts.archived, { variant: 'warning' });
      history.push('/e/analisis');
    }
  };

  return (
    <>
      {analisisError && <Alert>{analisisError.message}</Alert>}
      { isAnalisisLoading ? <LinearProgress /> : (
        <>
          <Stepper activeStep="completado" steps={constants.STEPS_ANALISIS} />
          <AnalisisDetails analisis={analisis} />
          <Grid container spacing={2} style={{ marginTop: '0.8rem' }}>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                label={texts.limitDate}
                value={analisis.limitDate}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <DatePicker
                label={texts.executeDate}
                value={analisis.executeDate}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.anexos}
                  name="anexos"
                  // onChange={form.handleChange}
                  // value={form.values.anexos}
                  // error={form.touched.anexos && Boolean(form.errors.anexos)}
                  // helperText={form.touched.anexos && form.errors.anexos && form.errors.anexos}
                  // multiple
                  disabled
                  urls={analisis.anexos}
                  // accepted={['images']}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docsResultado}
                  urls={analisis.docsResultado}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InformationBox
                updatedAt={analisis.docsResultado && analisis.docsResultado.length !== 0 && analisis.docsResultado[0].timeCreated}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docsRedaccion}
                  urls={analisis.docsRedaccion}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InformationBox
                updatedAt={analisis.docsRedaccion && analisis.docsRedaccion.length !== 0 && analisis.docsRedaccion[0].timeCreated}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={1} justifyContent="space-between" alignItems="center" direction="row-reverse">
            <Grid item>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <ButtonAdorned color="secondary" onClick={handleAgain}>
                    {texts.again}
                  </ButtonAdorned>
                </Grid>
                <Grid item>
                  <ButtonAdorned color="default" onClick={() => history.push('/e/analisis')}>
                    {texts.list}
                  </ButtonAdorned>
                </Grid>
              </Grid>
            </Grid>
            {showButtonArchive
              && (
              <Grid item>
                <ButtonAdorned color="secondary" onClick={handleArchived}>
                  {texts.archive}
                </ButtonAdorned>
              </Grid>
              )}
          </Grid>
        </>
      )}
    </>
  );
};

Completado.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape(),
};

Completado.defaultProps = {
  id: '',
  data: {},
};

export default Completado;
