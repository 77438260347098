import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '@i18n';
import { Alert } from '@components/atoms';
import { Chart } from 'react-google-charts';
import { Grid, LinearProgress } from '@material-ui/core';
import { useReports } from '@hooks';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Filtros from './Filtros';

const IngresosByArea = () => {
  const { pages: { employees: { reporteria: SubReportsTexts } } } = i18n;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { getIngresosxarea, reportError, isReportLoading } = useReports();
  const [report, setReport] = useState(null);
  const [filters, setFilters] = useState({
    dateInit: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    all: false,
    archivados: false,
    generate: false,
  });

  useEffect(async () => {
    if (filters.generate) {
      if (moment(filters.dateEnd).isBefore(filters.dateInit) && !filters.all) {
        enqueueSnackbar('La fecha de inicio no puede ser mayor a la fecha final', { variant: 'error' });
      } else {
        const result = await getIngresosxarea(filters);
        setReport(result);
      }
    }
    setFilters({ ...filters, generate: false });
  },
  [filters.generate]);

  const options = {
    title: 'Gráfico de Ingresos por Área',
    legend: { position: 'none' },
    chartArea: { width: '80%', height: '75%' },
    dataOpacity: 0.8,
    tooltip: {
      trigger: 'focus',
    },
    vAxis: {
      title: 'Cantidad de Ingresos',
    },
    hAxis: {
      title: 'Área',
    },

    animation: {
      startup: true,
      duration: 800,
      easing: 'out',
    },
    DataView: {
      columns: [0, 1, {
        calc: 'stringify',
        sourceColumn: 1,
        type: 'string',
        role: 'annotation',
      }],
    },
  };

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${SubReportsTexts.subreports.ingresosxarea.title}`}</title>
      </Helmet>
      <Filtros filters={filters} setFilters={setFilters} />
      {reportError && <Alert>{reportError.message}</Alert>}

      {isReportLoading && <LinearProgress />}
      {report && (
        <>
          <Grid container spacing={2} classes={classes.container}>
            <Grid item xs={12}>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="600px"
                data={report}
                options={options}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
  changeContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
}));

IngresosByArea.propTypes = {
};

IngresosByArea.defaultProps = {
};

export default IngresosByArea;
