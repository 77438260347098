import React from 'react';
import { Grid } from '@material-ui/core';
import VideoPlayer from '@components/atoms/VideoPlayer/VideoPlayer.component';
import PropTypes from 'prop-types';
import { Title } from '@components/atoms';

const VideoTutorials = ({ url, title, description }) => (
  <Grid container>
    <Grid container alignItems="center" direction="column">
      <Grid item>
        <VideoPlayer
          url={url}
        />
      </Grid>
    </Grid>
    <Grid container style={{ paddingTop: '10px' }}>
      <Grid item>
        <Title>{title}</Title>
        <p>{description}</p>
      </Grid>
    </Grid>
  </Grid>
);

VideoTutorials.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default VideoTutorials;
