import * as validator from 'yup';

import i18n from '@i18n';

const editInformeSchema = validator.object({
  customer: validator.object({
    id: validator.string().required(i18n.validationSchemas.customer.required),
    name: validator.string(),
  }),
  contact: validator.object({
    id: validator.string().required(i18n.validationSchemas.contact.required),
    name: validator.string(),
    lastname: validator.string(),
  }),
  docInformeCliente: validator
    .array()
    .min(1, i18n.validationSchemas.file.required),
  analisis: validator.object({
    id: validator.string().required(i18n.validationSchemas.presupuesto.required),
    number: validator.string(),
  }),
});

export default editInformeSchema;
