import * as validator from 'yup';

import i18n from '@i18n';

const readInformeSchema = validator.object({
  codigo: validator.string()
    .min(24, i18n.validationSchemas.codeValidation.min)
    .required(i18n.validationSchemas.codeValidation.required),

});

export default readInformeSchema;
