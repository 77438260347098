export default {
  email: {
    notValid: 'El correo debe ser válido, ej: juan@mail.com.',
    required: 'Debe ingresar un correo.',
  },
  password: {
    notMatches: 'La contraseña debe tener mínimo 6 caracteres, incluyendo al menos un número.',
    required: 'Debe ingresar una contraseña.',
    repeatNotMatches: 'Las contraseñas no coinciden.',
  },
  name: {
    required: 'Debe ingresar un nombre',
  },
  lastname: {
    required: 'Debe ingresar un apellido',
  },
  rut: {
    required: 'Debe ingresar un rut',
    notValid: 'Debe ingresar un rut válido',
  },
  customer: {
    required: 'Debe seleccionar un cliente',
  },
  contact: {
    required: 'Debe seleccionar un contacto',
  },
  address: {
    required: 'Debe ingresar una dirección',
  },
  file: {
    required: 'Debe seleccionar un archivo',
  },
  number: {
    required: 'Debe ingresar un número',
    moreThan: 'Debe ingresar un número mayor que 0',
  },
  state: {
    required: 'Debe seleccionar un estado',
  },
  phoneNumber: {
    required: 'Debe ingresar un teléfono válido',
  },
  presupuesto: {
    required: 'Debe seleccionar un Presupuesto',
  },
  informe: {
    required: 'Debe seleccionar un documento',
  },
  analisis: {
    required: 'Debe seleccionar un código de ingreso',
  },
  members: {
    required: 'Debe seleccionar al menos un Laboratorista',
  },
  folio: {
    required: 'Debe ingresar un numero de folio',
  },
  date: {
    required: 'Debe ingresar una fecha',
  },
  facturacion: {
    required: 'Debe seleccionar análisis con informe',
  },
  title: {
    required: 'Debe ingresar un titulo',
  },
  description: {
    required: 'Debe ingresar una descripción',
  },
  comment: {
    required: 'Debe ingresar un comentario',
  },
  codeValidation: {
    required: 'Debe ingresar un código para la validación.',
    min: 'El código debe tener minimo 24 caracteres.',
  },
  sample: {
    required: 'Debe ingresar identificación de muestra',
  },
  typeArea: {
    required: 'Debe seleccionar un área',
  },
  initialDate: {
    required: 'Debe ingresar una fecha de entrega',
  },
  executeDate: {
    required: 'Debe ingresar una fecha de termino de ejecución de los análisis',
  },
  ensayo: {
    required: 'Debe ingresar un ensayo',
  },
  metodo: {
    required: 'Debe ingresar un método',
  },
  material: {
    required: 'Debe ingresar un material',
  },
  qtyMuestras: {
    required: 'Debe ingresar una cantidad de muestras',
  },
};
