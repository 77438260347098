import React, { useEffect, useState } from 'react';
import {
  Grid, FormControl, TextField, TextareaAutosize,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import PropTypes from 'prop-types';
import {
  useSolicitudes, useStates,
} from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, MultiFileUpload,
} from '@components/atoms';
import { createSolicitudSchema } from '@helpers/schemas';
import constants from '@helpers/constants';

const Create = ({ user }) => {
  const { pages: { employees: { solicitudes: { create: texts } } } } = i18n;
  const history = useHistory();
  const { getStates, stateError } = useStates();
  const { createSolicitud, isSolicitudLoading, solicitudError } = useSolicitudes();

  const [states, setStates] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: {
      customer: user.customer,
      contact: user,
      files: [],
      title: '',
      description: '',
      state: states.length > 0 ? states.find((state) => state.id === constants.STATE.DISPONIBLE) : '',

    },
    enableReinitialize: true,
    validationSchema: createSolicitudSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { customer, ...rest } = values;
      const created = await createSolicitud(rest);
      if (created) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/c/solicitudes');
      }
    },
  });

  useEffect(async () => {
    const serviceStates = await getStates();
    setStates(serviceStates.filter((state) => state.newStateAllowed.employee));
  }, []);

  return (
    <>
      {stateError && <Alert>{stateError.message}</Alert>}
      {solicitudError && <Alert>{solicitudError.message}</Alert>}
      <Grid container spacing={2}>
        {texts.subtitleData && (
          <Grid item xs={12} align="left">
            <Subtitle>{texts.subtitleData}</Subtitle>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <TextField
              name="title"
              placeholder={texts.title}
              value={form.values.title}
              variant="outlined"
              onChange={form.handleChange}
              error={form.touched.title && Boolean(form.errors.title)}
              helperText={form.touched.title && form.errors.title}
              autoComplete="new-title"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl fullWidth>
            <TextareaAutosize
              style={{ borderColor: '#e0e0e0', fontFamily: 'Arial, Roboto', overflow: 'auto' }}
              name="description"
              placeholder={texts.descriptionPlaceholder}
              minRows={10}
              value={form.values.description}
              onChange={form.handleChange}
              error={form.touched.description && Boolean(form.errors.description)}
              helperText={form.touched.description && form.errors.description}
              autoComplete="new-description"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <MultiFileUpload
              title={texts.file}
              name="files"
              onChange={form.handleChange}
              value={form.values.files}
              multiple
              error={form.touched.files && Boolean(form.errors.files)}
              helperText={form.touched.files && form.errors.files && form.errors.files}
            />
          </FormControl>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <ButtonAdorned color="default" onClick={() => history.push('/e/solicitudes')}>
              {texts.list}
            </ButtonAdorned>
          </Grid>
          <Grid item>
            <form onSubmit={form.handleSubmit}>
              <ButtonAdorned loading={isSolicitudLoading} disabled={isSolicitudLoading} type="submit">
                {texts.save}
              </ButtonAdorned>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Create.propTypes = {
  user: PropTypes.shape({
    customer: PropTypes.shape({}),
  }),
};

Create.defaultProps = {
  user: null,
};

export default Create;
