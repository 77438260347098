import React from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';
import PropTypes from 'prop-types';

const IconButton = ({ children, disable, ...props }) => (
  <MuiIconButton
    disable={disable}
    {...props}
  >
    {children}
  </MuiIconButton>
);

IconButton.propTypes = {
  disable: PropTypes.bool,
  children: PropTypes.node,
};

IconButton.defaultProps = {
  disable: false,
  children: {},
};
export default IconButton;
