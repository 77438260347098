import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem, ListItemIcon, ListItemText, Collapse, List, Grid,
} from '@material-ui/core';
import {
  QuestionAnswer as QuestionIcon,
  StarBorder,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import i18n from '@i18n';
import { VideoTutorials } from '@components/molecules';
import { Divider } from '@components/atoms';

const ETutorials = () => {
  const [open, setOpen] = useState({
    general: true,
    administrador: true,
  });
  const [video, setVideo] = useState(null);
  const { tutorials: texts } = i18n;
  const classes = useStyles();

  const handleClick = (rol) => {
    setOpen({ [rol]: !open[rol] });
  };

  const tutorials = [
    {
      title: 'General',
      rol: 'general',
      details: [
        {
          title: texts.general.cambioContrasena.title,
          description: texts.general.cambioContrasena.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fadministracion%2FCrear%20contacto.mp4?alt=media&token=62ab47b6-19c9-4e09-9958-58f3def90c82',
        },
        {
          title: texts.general.validarMailUsuario.title,
          description: texts.general.validarMailUsuario.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fgeneral%2FValidar%20usuario.mp4?alt=media&token=4344aeed-0f55-491b-93dd-bb2e0f2e410d',
        },
      ],
    },
    {
      title: 'Administración',
      rol: 'administrador',
      details: [
        {
          title: texts.administracion.crearContacto.title,
          description: texts.administracion.crearContacto.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fadministracion%2FCrear%20contacto.mp4?alt=media&token=62ab47b6-19c9-4e09-9958-58f3def90c82',
        },
        {
          title: texts.administracion.verContacto.title,
          description: texts.administracion.verContacto.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fadministracion%2FVer%20contactos.mp4?alt=media&token=797966f4-7313-4455-a9a1-fd4d8f978a15',
        },
        {
          title: texts.administracion.inhabilitarContacto.title,
          description: texts.administracion.inhabilitarContacto.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fadministracion%2FInhabilitar%20habilitar%20contacto.mp4?alt=media&token=082a415c-94b2-454b-94a5-3e741645daef',
        },
        {
          title: texts.administracion.crearColaborador.title,
          description: texts.administracion.crearColaborador.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fadministracion%2FCrear%20Colaborador.mp4?alt=media&token=32989bc9-d70c-4181-871b-da6e776a79d7',
        },
        {
          title: texts.administracion.crearCliente.title,
          description: texts.administracion.crearCliente.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fadministracion%2FCrear%20Cliente.mp4?alt=media&token=86d262fb-8190-4c49-8e4a-be9fee157d5b',
        },
        {
          title: texts.administracion.editarCliente.title,
          description: texts.administracion.editarCliente.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fadministracion%2FEditar%20Clientes.mp4?alt=media&token=5e5df9af-78dc-4bc1-a0e9-f970b2030780',
        },
        {
          title: texts.administracion.editarInfoCambioPassword.title,
          description: texts.administracion.editarInfoCambioPassword.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fadministracion%2FEditar%20Clientes.mp4?alt=media&token=5e5df9af-78dc-4bc1-a0e9-f970b2030780',
        },
      ],
    },
    {
      title: 'Secretaria',
      rol: 'Secretaria',
      details: [
        {
          title: texts.secretaria.crearPresupuesto.title,
          description: texts.secretaria.crearPresupuesto.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fsecretaria%2FCrear%20Presupuesto.mp4?alt=media&token=12244d23-27ec-4904-a396-47f606198e99',
        },
        {
          title: texts.secretaria.comunicaciones.title,
          description: texts.secretaria.comunicaciones.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fsecretaria%2FMensajeria.mp4?alt=media&token=ce8f88ff-8fff-4ea9-a1df-70e06afc0f49',
        },
        {
          title: texts.secretaria.agregarIngreso.title,
          description: texts.secretaria.agregarIngreso.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fsecretaria%2FAgregar%20Ingreso.mp4?alt=media&token=2f890011-61bd-4856-9881-58f402b5f490',
        },
      ],
    },
    {
      title: 'Cliente',
      rol: 'cliente',
      details: [
        {
          title: texts.cliente.aprobarPresupuesto.title,
          description: texts.cliente.aprobarPresupuesto.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fcliente%2FAprobar%20Presupuesto.mp4?alt=media&token=5f677db5-c8f2-48d7-9e74-a268e2364f5c',
        },
      ],
    },
    {
      title: 'Jefe Laboratorio',
      rol: 'jefe_laboratorio',
      details: [
        {
          title: texts.jefeLaboratorio.asignarLaboratorista.title,
          description: texts.jefeLaboratorio.asignarLaboratorista.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fjefe_laboratorio%2FAsignar%20Laboratoristas.mp4?alt=media&token=0cf64463-ea33-45ce-9bd1-39d5a6cb8110',
        },
        {
          title: texts.jefeLaboratorio.revisionAnalisis.title,
          description: texts.jefeLaboratorio.revisionAnalisis.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fjefe_laboratorio%2FRevision%20%20Analisis.mp4?alt=media&token=55106057-b96d-46c0-97a1-61c7d7d5ec33',
        },
      ],
    },
    {
      title: 'Laboratorista',
      rol: 'laboratorista',
      details: [
        {
          title: texts.laboratoristas.ejecucionAnalisis.title,
          description: texts.laboratoristas.ejecucionAnalisis.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Flaboratorista%2FEjecucion%20Analisis.mp4?alt=media&token=4c558216-70a0-45f2-9d22-675a75bb429e',
        },
      ],
    },
    {
      title: 'Ingenieros',
      rol: 'ingeniero',
      details: [
        {
          title: texts.ingenieros.redaccionInforme.title,
          description: texts.ingenieros.redaccionInforme.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fingeniero%2FRedaccion%20Informe.mp4?alt=media&token=839c4fe4-cfb2-4748-94e1-f2a5e0a7f494',
        },
      ],
    },
  ];

  return (
    <Grid container>
      {tutorials.map((tutorial) => (
        <Grid item xs={12} sm={12} md={12} key={tutorial.rol}>
          <ListItem button onClick={() => handleClick(tutorial.rol)}>
            <ListItemIcon>
              <QuestionIcon />
            </ListItemIcon>
            <ListItemText primary={tutorial.title} />
            {open[tutorial.rol] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open[tutorial.rol]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {tutorial.details.map((detail) => (
                <ListItem button className={classes.nested} key={detail.title}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText
                    primary={detail.title}
                    onClick={() => { setVideo(detail); handleClick(tutorial.rol); }}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Grid>
      ))}
      <Divider />
      {video && (
        <Grid item xs={12}>
          <VideoTutorials {...video} />
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export default ETutorials;
