/* eslint-disable no-param-reassign */
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

import { API_URL } from '../configs';

const API = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

API.interceptors.request.use(async (config) => {
  config.headers.get.token = await firebase.auth().currentUser.getIdToken(true);
  config.headers.post.token = await firebase.auth().currentUser.getIdToken(true);
  config.headers.put.token = await firebase.auth().currentUser.getIdToken(true);
  config.headers.delete.token = await firebase.auth().currentUser.getIdToken(true);
  return config;
});

export default API;
