import React, { useState, useEffect } from 'react';
import {
  Grid, Hidden, IconButton, Menu, MenuItem, LinearProgress,
} from '@material-ui/core';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton,
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  VisibilityOutlined as ViewIcon,
  ListOutlined as MenuIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { usePresupuestos } from '@hooks';
import { Alert, ChipStep, DataGrid } from '@components/atoms';
import getDate from '@helpers/utils/getDate';
import constantsPresupuesto from '@helpers/constants/presupuesto';

import i18n from '@i18n';

const List = () => {
  const { pages: { customers: { presupuestos: { list: texts } } } } = i18n;
  const history = useHistory();
  const classes = useStyles();
  const { getPresupuestos, isPresupuestoLoading, presupuestoError } = usePresupuestos();
  const [presupuestos, setPresupuestos] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(async () => {
    setPresupuestos(await getPresupuestos());
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const CustomToolbar = () => (
    <>
      <Hidden xsDown>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
      <Hidden smUp>
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
        </GridToolbarContainer>
      </Hidden>
    </>
  );

  const columns = [
    {
      field: 'contact',
      headerName: texts.columns.customer,
      flex: 0.3,
      valueGetter: (values) => values.row.contact.customer.name,
    },
    {
      field: 'number',
      headerAlign: 'center',
      headerName: texts.columns.number,
      align: 'center',
      flex: 0.1,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: texts.columns.createdAt,
      align: 'center',
      flex: 0.2,
      type: 'date',
      valueFormatter: (params) => getDate(params.value),
    },
    {
      field: 'state',
      headerName: texts.columns.state,
      renderCell: (values) => {
        const { row: { state: { name } } } = values;
        const color = constantsPresupuesto.STATES_PRESUPUESTO.find((item) => item.step === name);

        return (name ? (
          <ChipStep label={name} backgroundColor={color.backgroundColor} />
        ) : null);
      },
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => {
        const { row: { fileURL } } = values;
        return (
          <>
            <Hidden xsDown>
              <IconButton className={classes.actionIcons} onClick={() => history.push(`/c/presupuestos/view/${values.row.id}`)}>
                <ViewIcon />
              </IconButton>
              <IconButton
                className={classes.actionIcons}
                onClick={() => window.open(fileURL, '_blank')}
              >
                <InsertDriveFileOutlinedIcon />
              </IconButton>
            </Hidden>
            <Hidden smUp>
              <IconButton className={classes.actionIcons} onClick={(event) => setAnchorEl(event.currentTarget)}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => history.push(`/c/presupuestos/view/${values.row.id}`)}>Ver</MenuItem>
                <MenuItem onClick={() => window.open(fileURL, '_blank')}>PDF</MenuItem>
              </Menu>
            </Hidden>
          </>
        );
      },
    },
  ];

  return (
    <>
      {presupuestoError && <Alert>{presupuestoError.message}</Alert>}
      {isPresupuestoLoading && <LinearProgress />}
      {presupuestos && (
        <Grid container spacing={2}>
          <DataGrid
            sortModel={[{ field: 'createdAt', sort: 'desc' }]}
            columns={columns}
            rows={presupuestos}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

export default List;
