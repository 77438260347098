import React, { useState } from 'react';
import {
  DataGrid as MuiDataGrid,
  esES,
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '@i18n';
import PropTypes from 'prop-types';

const DataGrid = (props) => {
  const { components: { dataGrid: { labelRowsPerPage } } } = i18n;
  const classes = useStyles();
  const { rowsPerPage } = props;

  const [pageSize, setPageSize] = useState(rowsPerPage || 25);

  return (
    <MuiDataGrid
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      classes={classes.actionIcons}
      autoHeight
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      // rowsPerPageOptions={[5, 10, 20]}
      disableColumnMenu
      componentsProps={{
        pagination: {
          labelRowsPerPage,
        },
      }}
      {...props}
    />
  );
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

DataGrid.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
};

export default DataGrid;
