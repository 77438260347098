const validacion = {
  title: 'Validación de Informe',
  list: {
    title: 'Informe',
    columns: {
      customer: 'Cliente',
      number: 'Número',
      createdAt: 'Fecha creación',
    },
  },
  create: {
    title: 'Validación de Informe',
    customer: 'Cliente *',
    noContacts: 'Cliente no tiene contactos',
    contact: 'Contacto *',
    analisis: 'Analisis *',
    agreement: 'Tipo Acuerdo',
    save: 'Crear',
    list: 'Volver a la lista',
    success: 'Ingreso creado exitosamente',
    noAnalisis: 'Cliente no tiene analisis',
    docInforme: 'Redacción Informe',
    fechaExp: 'Fecha Expiración *',
    docInformeCliente: 'Informe Cliente *',
  },
};

export default validacion;
