import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Title, Subtitle } from '@components/atoms';
import { Grid, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '@i18n';
import List from './List';
import View from './View';

const Presupuestos = ({ location: { search }, match: { params: { action = 'list', id = '' } } }) => {
  const { pages: { customers: { presupuestos: texts } } } = i18n;
  const classes = useStyles();

  const [extraInfo, setExtraInfo] = useState([]);
  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      <Grid
        container
        spacing={0}
        className={classes.container}
        alignItems="center"
      >
        {texts.title && (
          <Grid item xs={12} sm={3} align="left">
            <Title>{texts.title}</Title>
          </Grid>
        )}
        {action === 'view' && (
          <Grid item xs={12} sm={9} align="right">
            {extraInfo.map((info) => <Typography noWrap>{info}</Typography>)}
          </Grid>
        )}
      </Grid>
      {texts.subtitle && (
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={12} align="left">
          <Subtitle>{texts.subtitle}</Subtitle>
        </Grid>
      </Grid>
      )}
      <Divider />
      <div className={classes.container}>
        {action === 'list' && <List search={search} />}
        {action === 'view' && <View id={id} setExtraInfo={setExtraInfo} />}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
}));

Presupuestos.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

Presupuestos.defaultProps = {
  location: {
    search: '',
  },
  match: {
    params: {
      action: 'list',
      id: '',
    },
  },
};

export default Presupuestos;
