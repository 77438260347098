import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import i18n from '@i18n';
import { getDate } from '@helpers/utils';

const InformationBox = ({ updatedBy, updatedAt }) => {
  const { components: { informationBox: texts } } = i18n;
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {updatedAt && (
      <Grid item xs={12} sm={12} md={12}>
        {texts.updatedAt}
        <strong>{` ${getDate(updatedAt)}`}</strong>
      </Grid>
      )}
      {updatedBy && (
      <Grid item xs={12} sm={12} md={12}>
        {texts.updatedBy}
        <strong>{` ${updatedBy.name} ${updatedBy.lastname}`}</strong>
      </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
  },
}));

InformationBox.propTypes = {
  updatedBy: PropTypes.shape({
    name: PropTypes.string,
    lastname: PropTypes.string,
  }),
  updatedAt: PropTypes.string,
};

InformationBox.defaultProps = {
  updatedBy: undefined,
  updatedAt: undefined,
};

export default InformationBox;
