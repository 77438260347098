import * as React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const Card = ({ title, count, ...props }) => {
  const classes = useStyles(props);
  const { onClick } = props;
  return (
    <Box className={classes.container}>
      <Box className={classes.card} onClick={onClick}>
        <h2 className={classes.h3}>{title}</h2>
        <h1 className={classes.number}>{count}</h1>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {

    background: '#eee',
    paddingBottom: '1em',
  },
  card: {
    padding: '1.5em',
    borderRadius: '4px',
    boxShadow: '2px 2px 7px 0px rgba(0,0,0,0.3)',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: (props) => props.color,
    transition: 'box-shadow 0.2s ease',

    '&:hover': {
      boxShadow: '4px 4px 7px 0px rgba(21,30,63,0.3)',
      border: '1px 10px solid #151E3F',
    },
  },

  h3: {
    fontSize: '1.2em',
    letterSpacing: '-1px',
    color: '#333',
    fontWeight: '600',
    marginBottom: '.2rem',
    // textTransform: 'uppercase',
    // textShadow: '2px 2px 20px #333, 0px -2px 1px #fff',
  },
  number: {
    fontSize: '3em',
    color: '#333',
    margin: '5px 0',
  },
});

Card.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  onClick: () => {},
  count: 0,
};

export default Card;
