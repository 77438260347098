import axios from 'axios';
import ApiError from './apiError';
import { API_URL } from '../infraestructure/configs';

export async function getServiceFacturacionNoAuth(data) {
  try {
    const response = await axios.get(`${API_URL}/validation?id=${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/facturacion/', message: `Hubo un problema al intentar buscar el informe. ${message}` });
  }
}

export async function getServiceDocumentNoAuth({ id, docPath }) {
  try {
    const response = await axios.get(`${API_URL}/documentURL?id=${id}&docPath=${docPath}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/document/', message: `Hubo un problema al intentar buscar el documento. ${message}` });
  }
}
