import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import {
  useContacts, useCustomers, usePresupuestos, useStates,
} from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, Select, FileUpload, Autocomplete,
} from '@components/atoms';
import { editPresupuestoSchema } from '@helpers/schemas';
import { getCompleteName, getDate } from '@helpers/utils';

const Edit = ({ id, setExtraInfo }) => {
  const { pages: { employees: { presupuestos: { edit: texts } } } } = i18n;
  const history = useHistory();
  const { getContacts, isContactLoading, contactError } = useContacts();
  const { getCustomers, isCustomerLoading, customerError } = useCustomers();
  const { getStates, isStateLoading, stateError } = useStates();
  const {
    getPresupuesto, editPresupuesto, isPresupuestoLoading, presupuestoError,
  } = usePresupuestos();

  const [customers, setCustomers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [states, setStates] = useState([]);
  const [presupuesto, setPresupuesto] = useState({
    customer: '',
    contact: '',
    fileBkp: [],
    file: [],
    number: '',
    description: '',
    state: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: presupuesto,
    enableReinitialize: true,
    validationSchema: editPresupuestoSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { customer, ...rest } = values;
      const updated = await editPresupuesto(rest);
      if (updated) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/presupuestos');
      }
    },
  });

  useEffect(async () => {
    setExtraInfo([]);
    const servicePresupuesto = await getPresupuesto({ id });
    const serviceStates = await getStates();
    const currentState = serviceStates.find((state) => (state.id === servicePresupuesto.state.id));

    const updated = `Última actualización por: ${getCompleteName(servicePresupuesto.updatedBy)} | ${getDate(servicePresupuesto.updatedAt)}`;
    const created = `Creado por: ${getCompleteName(servicePresupuesto.createdBy)} | ${getDate(servicePresupuesto.createdAt)}`;
    setExtraInfo([created || '', updated || '']);

    if (currentState.editAllowed) {
      setPresupuesto({
        ...presupuesto,
        id: servicePresupuesto.id,
        customer: servicePresupuesto.contact ? servicePresupuesto.contact.customer : '',
        contact: servicePresupuesto.contact || '',
        fileURL: servicePresupuesto.fileURL || '',
        fileBkpURL: servicePresupuesto.fileBkpURL || '',
        number: servicePresupuesto.number || '',
        description: servicePresupuesto.description || '',
        state: currentState || {},
      });
      setStates(serviceStates.filter((state) => {
        const isCurrentState = state.id === presupuesto.state.id;
        const isNewStateAllowed = state.newStateAllowed.employee;
        return (isCurrentState || isNewStateAllowed);
      }));
      setCustomers(await getCustomers());
    }
  }, []);

  useEffect(async () => {
    if (presupuesto.contact.customer) {
      setContacts(await getContacts(`?customer.id=${presupuesto.contact.customer.id}`));
    }
  }, [customers]);

  return (
    <>
      {presupuestoError && <Alert>{presupuestoError.message}</Alert>}
      {contactError && <Alert>{contactError.message}</Alert>}
      {customerError && <Alert>{customerError.message}</Alert>}
      {stateError && <Alert>{stateError.message}</Alert>}
      { (isCustomerLoading || isContactLoading || isStateLoading || isPresupuestoLoading) ? <LinearProgress /> : (
        <>
          <Grid container spacing={2}>
            {texts.subtitleData && (
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  name="customer"
                  label={texts.customer}
                  value={form.values.customer}
                  error={form.touched.customer && Boolean(form.errors.customer)}
                  disabled
                  helperText={form.touched.customer && form.errors.customer && form.errors.customer.id}
                  items={customers}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  value={form.values.contact}
                  defaultValue={form.values.contact}
                  label={texts.contact}
                  onChange={(_, value) => form.setFieldValue('contact', value)}
                  error={form.touched.contact && Boolean(form.errors.contact)}
                  disabled={isContactLoading}
                  helperText={(form.values.customer && contacts.length === 0 && !isContactLoading && texts.noContacts)
                || (form.touched.contact && form.errors.contact && form.errors.contact.id)}
                  items={contacts}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <TextField
                  name="number"
                  label={texts.number}
                  placeholder={texts.number}
                  variant="outlined"
                  value={form.values.number}
                  onChange={form.handleChange}
                  error={form.touched.number && Boolean(form.errors.number)}
                  helperText={form.touched.number && form.errors.number}
                  disabled={isPresupuestoLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  name="description"
                  label={texts.description}
                  placeholder={texts.description}
                  variant="outlined"
                  value={form.values.description}
                  onChange={form.handleChange}
                  error={form.touched.description && Boolean(form.errors.description)}
                  helperText={form.touched.description && form.errors.description}
                  disabled={isPresupuestoLoading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FileUpload
                  title={texts.file}
                  name="file"
                  onChange={form.handleChange}
                  value={form.values.file}
                  error={form.touched.file && Boolean(form.errors.file)}
                  helperText={form.touched.file && form.errors.file && form.errors.file}
                  url={form.values.fileURL}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  name="state"
                  label={texts.state}
                  value={form.values.state}
                  onChange={form.handleChange}
                  error={form.touched.state && Boolean(form.errors.state)}
                  disabled={isStateLoading}
                  items={states}
                />
              </FormControl>
            </Grid>
            {form.values.state.id === '60e0f8315c337ece40c2ad3a' && (
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FileUpload
                  title={texts.fileBkp}
                  name="fileBkp"
                  onChange={form.handleChange}
                  value={form.values.fileBkp}
                  error={form.touched.fileBkp && Boolean(form.errors.fileBkp)}
                  helperText={form.touched.fileBkp && form.errors.fileBkp && form.errors.fileBkp}
                  url={form.values.fileBkpURL}
                />
              </FormControl>
            </Grid>
            )}
          </Grid>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <ButtonAdorned color="default" onClick={() => history.push('/e/presupuestos')}>
                {texts.list}
              </ButtonAdorned>
            </Grid>
            <Grid item>
              <form onSubmit={form.handleSubmit}>
                <ButtonAdorned loading={isPresupuestoLoading} disabled={isPresupuestoLoading} type="submit">
                  {texts.save}
                </ButtonAdorned>
              </form>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string,
  setExtraInfo: PropTypes.func,
};

Edit.defaultProps = {
  id: '',
  setExtraInfo: () => {},
};

export default Edit;
