import { useState, useCallback } from 'react';
import { getServiceStates } from '../services/states';

const useStates = () => {
  const [state, setState] = useState({ loading: false, error: null, states: [] });

  const getStates = useCallback(async () => {
    setState({ loading: true, error: null, roles: [] });
    try {
      const states = await getServiceStates();
      setState({ loading: false, error: null, states });
      return states;
    } catch (e) {
      setState({ loading: false, error: e, roles: [] });
      return [];
    }
  });

  return {
    isStateLoading: state.loading,
    statesError: state.error,
    getStates,
  };
};

export default useStates;
