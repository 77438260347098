import React, { useState, useEffect } from 'react';
import { loginSchema } from '@helpers/schemas';
import { useAuth } from '@hooks';
import {
  FormControl, Grid, Typography, TextField, InputAdornment, IconButton, OutlinedInput, InputLabel,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { AlertDialog, ButtonAdorned, GridBorderContainer } from '@components/atoms';
import i18n from '@i18n';
import { useHistory } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import images from '../../../assets/images';

const Login = () => {
  const { pages: { login: texts } } = i18n;
  const [dialog, setDialog] = useState({ open: false });
  const classes = useStyles();
  const {
    isAuthLoading, userError, signInWithEmailAndPassword,
  } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      await signInWithEmailAndPassword(values.email, values.password);
    },
  });

  useEffect(() => {
    if (userError && userError.type === 'dialog') {
      setDialog({ open: true, text: userError.text });
    }
  }, [userError]);

  const handleOnKeyPress = (key) => {
    if (key.charCode === 13) form.submitForm();
  };

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      <AlertDialog
        {...dialog}
        onClose={() => { setDialog({ ...dialog, open: false }); }}
      />
      <GridBorderContainer>
        <Grid item xs={12} align="center">
          <img
            src={images.logo}
            align="center"
            alt={i18n.appName}
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={12} align="center">
          <Typography className={classes.title}>{texts.title}</Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Typography className={classes.subtitle}>{texts.subtitle}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl margin="dense" fullWidth>
            <TextField
              name="email"
              label={texts.emailInput}
              variant="outlined"
              value={form.values.email}
              onChange={form.handleChange}
              error={form.touched.email && Boolean(form.errors.email)}
              helperText={form.touched.email && form.errors.email}
              disabled={isAuthLoading}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '10px' }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="password">{texts.passwordInput}</InputLabel>
            <OutlinedInput
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              value={form.values.password}
              onChange={form.handleChange}
              error={form.touched.password && Boolean(form.errors.password)}
              helperText={form.touched.password && form.errors.password}
              disabled={isAuthLoading}
              onKeyPress={handleOnKeyPress}
              labelWidth={80}
            />
          </FormControl>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline" className={classes.buttons}>
          <ForgotPassword email={form.values.email} />
          <form onSubmit={form.handleSubmit}>
            <ButtonAdorned loading={isAuthLoading} disabled={isAuthLoading} type="submit">
              {texts.continue}
            </ButtonAdorned>
          </form>
        </Grid>
        <Grid>
          <ButtonAdorned variant="text" onClick={() => history.push('/n/validation')}>{texts.validation}</ButtonAdorned>
        </Grid>
      </GridBorderContainer>
    </>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginTop: '1em',
  },
  subtitle: {
    marginBottom: '1em',
  },
  buttons: {
    marginTop: '1em',
  },
  logo: {
    maxHeight: '3em',
  },
}));

export default Login;
