import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem, ListItemIcon, ListItemText, Collapse, List, Grid,
} from '@material-ui/core';
import {
  QuestionAnswer as QuestionIcon,
  StarBorder,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import i18n from '@i18n';
import { VideoTutorials } from '@components/molecules';
import { Divider } from '@components/atoms';

const ETutorials = () => {
  const [open, setOpen] = useState({
    general: true,
    administrador: true,
  });
  const [video, setVideo] = useState(null);
  const { tutorials: texts } = i18n;
  const classes = useStyles();

  const handleClick = (rol) => {
    setOpen({ [rol]: !open[rol] });
  };

  const tutorials = [
    {
      title: 'Cliente',
      rol: 'cliente',
      details: [
        {
          title: texts.cliente.aprobarPresupuesto.title,
          description: texts.cliente.aprobarPresupuesto.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fcliente%2FAprobar%20Presupuesto.mp4?alt=media&token=5f677db5-c8f2-48d7-9e74-a268e2364f5c',
        },
        {
          title: texts.cliente.crearEditarSolicitudes.title,
          description: texts.cliente.crearEditarSolicitudes.description,
          url: 'https://firebasestorage.googleapis.com/v0/b/caltex-portal.appspot.com/o/tutoriales%2Fcliente%2FCreacion%20y%20edicion%20solicitudes.mp4?alt=media&token=5619d6eb-5f77-45d2-af1d-ecabb049cc1a',
        },
      ],
    },
  ];

  return (
    <Grid container>
      {tutorials.map((tutorial) => (
        <Grid item xs={12} sm={12} md={12} key={tutorial.rol}>
          <ListItem button onClick={() => handleClick(tutorial.rol)}>
            <ListItemIcon>
              <QuestionIcon />
            </ListItemIcon>
            <ListItemText primary={tutorial.title} />
            {open[tutorial.rol] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open[tutorial.rol]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {tutorial.details.map((detail) => (
                <ListItem button className={classes.nested} key={detail.title}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText
                    primary={detail.title}
                    onClick={() => { setVideo(detail); handleClick(tutorial.rol); }}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Grid>
      ))}
      <Divider />
      {video && (
        <Grid item xs={12}>
          <VideoTutorials {...video} />
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export default ETutorials;
