import * as validator from 'yup';

import i18n from '@i18n';

const emailSchema = validator
  .string()
  .email(i18n.validationSchemas.email.notValid)
  .required(i18n.validationSchemas.email.required);

export default emailSchema;
