export default {
  alertDialog: {
    close: 'Cancelar',
  },
  notEmailVerified: {
    title: 'Debes validar tu email para poder continuar',
    subtitle: 'Habrás recibido un email con un enlace para verificar que tu dirección es correcta. Si no lo tienes, comprueba tu bandeja de correo no deseado (spam).',
    resendEmail: 'Enviar email de verificación ',
    sentEmail: 'Email enviado ',
  },
  employeeNavBar: {
    perfil: 'Perfil',
    logout: 'Desconectarse',
    help: 'Ayuda',
    presupuestos: 'Presupuestos',
    analisis: 'Ingresos',
    facturacion: 'Facturación',
    informe: 'Informe',
    clientes: 'Clientes',
    contactos: 'Contactos',
    colaboradores: 'Colaboradores',
    solicitudes: 'Solicitudes',
    validacion: 'Validación',
    reporteria: 'Reportes',
  },
  chipDisabled: {
    label: 'Inhabilitado',
  },
  customerNavBar: {
    perfil: 'Perfil',
    logout: 'Desconectarse',
    presupuestos: 'Presupuestos',
    facturacion: 'Informes',
    solicitudes: 'Solicitudes',
    validacion: 'Validación',
  },
  messaging: {
    title: 'Mensajería',
    send: 'Enviar',
  },
  analisisDetails: {
    title: 'Datos del ingreso',
    number: 'Nro de ingreso',
    docsPlantilla: 'Plantilla de trabajo',
    docsIngreso: 'Ficha de Ingreso',
  },
  informationBox: {
    updatedAt: 'Última actualización archivo:',
    updatedBy: 'Última actualización archivo por:',
  },
  dataGrid: {
    labelRowsPerPage: 'Registros por página',
  },
};
