import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const SpinnerAdornment = () => {
  const classes = useStyles();
  return (
    <CircularProgress
      className={classes.circularProgress}
      size={16}
      data-testid="CircularProgress"
    />
  );
};

const ButtonAdorned = (props) => {
  const {
    children,
    loading,
    ...rest
  } = props;
  return (
    <Button {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

const useStyles = makeStyles(() => ({
  circularProgress: {
    marginLeft: 5,
  },
}));

ButtonAdorned.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  variant: PropTypes.string,
  color: PropTypes.string,
};

ButtonAdorned.defaultProps = {
  loading: false,
  children: {},
  variant: 'contained',
  color: 'primary',
};

export default ButtonAdorned;
