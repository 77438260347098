import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import i18n from '@i18n';
import { useContacts } from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, ChipDisabled,
} from '@components/atoms';

const View = ({ id }) => {
  const classes = useStyles();
  const { pages: { employees: { contacts: { view: texts } } } } = i18n;
  const history = useHistory();
  const { getContact, isContactLoading, contactError } = useContacts();
  const [contact, setContact] = useState();

  useEffect(async () => {
    setContact(await getContact({ id }));
  }, []);

  return (
    <>
      {contactError && <Alert>{contactError.message}</Alert>}
      {isContactLoading && <LinearProgress />}
      {contact && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.customer}
                  value={contact.customer.name || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.title}
                  value={contact.title || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.name}
                  value={contact.name || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.lastname}
                  value={contact.lastname || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.phoneNumber}
                  value={contact.phoneNumber || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleAccount}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.email}
                  value={contact.email || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
              {contact.disabled && (
                <Grid item>
                  <ChipDisabled />
                </Grid>
              )}
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push('/e/contactos')}>
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <ButtonAdorned loading={isContactLoading} disabled={isContactLoading} onClick={() => history.push(`/e/contactos/edit/${contact.id}`)}>
                  {texts.edit}
                </ButtonAdorned>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

View.propTypes = {
  id: PropTypes.string,
};

View.defaultProps = {
  id: '',
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
}));

export default View;
