import React from 'react';
import { Chip } from '@material-ui/core';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import i18n from '@i18n';

const ChipDisabled = (props) => (
  <Chip variant="outlined" label={i18n.components.chipDisabled.label} icon={<BlockOutlinedIcon />} {...props} />
);

ChipDisabled.propTypes = {
};

ChipDisabled.defaultProps = {
};

export default ChipDisabled;
