import pages from './pages';
import components from './components';
import validationSchemas from './validationSchemas';
import hooks from './hooks';
import tutorials from './tutorials';

export default {
  appName: 'Caltex',
  appLanguage: 'es',
  pages,
  components,
  validationSchemas,
  hooks,
  tutorials,
};
