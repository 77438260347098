import React, { useState, useEffect } from 'react';
import { Dialog, DataGrid, ButtonAdorned } from '@components/atoms';
import PropTypes from 'prop-types';
import {
  LinearProgress, Grid, TextField, IconButton,
} from '@material-ui/core';
import { useEnsayos } from '@hooks';
import {
  Clear as ClearIcon,
  Search as SearchIcon,
  DeleteForeverTwoTone as DeleteForeverTwoToneIcon,
  EditTwoTone as EditTwoToneIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import AddEnsayos from './addEnsayosModal';

const escapeRegExp = (value) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

const QuickSearchToolbar = ({ value, onChange, clearSearch }) => (
  <Grid container style={{ margin: '10px' }}>
    <Grid item xs={12}>
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder="Buscar..."
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </Grid>
  </Grid>
);

const EnsayosModal = ({
  showDialog, setShowDialog, title, setDataSelected,
}) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { getEnsayosAll, deleteEnsayo, isEnsayosLoading } = useEnsayos();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [editEnsayo, setEditEnsayo] = useState({});
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const [refresh, setRefresh] = useState(false);

  const deleteOneEnsayo = async (id) => {
    try {
      const result = await deleteEnsayo(id);
      if (result) {
        enqueueSnackbar('Ensayo eliminado', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar('Error eliminando ensayo', { variant: 'error' });
    } finally {
      setOpenConfirmDelete(false);
      setRefresh(true);
    }
  };

  const actionSelected = (action, dataEnsayo = null) => {
    if (action === 'edit') {
      setEditEnsayo({
        data: {
          id: dataEnsayo._id,
          material: dataEnsayo.material,
          ensayo: dataEnsayo.ensayo,
          metodo: dataEnsayo.metodo,
        },
        action: 'edit',
        refresh: setRefresh,
      });
    }
    if (action === 'add') {
      setEditEnsayo({
        data: {
          id: '',
          material: '',
          ensayo: '',
          metodo: '',
        },
        action: 'add',
        refresh: setRefresh,
      });
    }
    setOpenDialogAdd(true);
  };

  const columns = [
    { field: 'material', headerName: 'Material', flex: 0.3 },
    { field: 'ensayo', headerName: 'Ensayo', flex: 1 },
    { field: 'metodo', headerName: 'Metodo', flex: 1 },
    {
      field: 'actions',
      headerName: 'Acciones',
      headerAlign: 'center',
      flex: 0.5,
      align: 'center',
      renderCell: (ensayo) => (
        <>
          <IconButton onClick={() => {
            setIdSelected(ensayo.row.id);
            setOpenConfirmDelete(true);
          }}
          >
            <DeleteForeverTwoToneIcon color="secondary" />
          </IconButton>
          <IconButton onClick={() => {
            actionSelected('edit', ensayo.row);
          }}
          >
            <EditTwoToneIcon />
          </IconButton>
        </>
      ),

    },
  ];

  const getSelectedData = (rowIds) => {
    const selectedData = data.filter((row) => rowIds.selectionModel.includes(row.id));
    const selectedDataFormatted = selectedData.map(({
      id, ensayo, material, metodo,
    }) => ({
      id,
      ensayo,
      material,
      metodo,
    }));
    setDataSelected(selectedDataFormatted);
    return selectedData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getEnsayosAll();
        setData(response);
      } catch (error) {
        enqueueSnackbar('Error obteniendo datos', { variant: 'error' });
      }
    };
    if (showDialog) {
      fetchData();
    }
  }, [showDialog, refresh]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row) => Object.keys(row).some((field) => searchRegex.test(row[field].toString())));
    setFilteredData(filteredRows);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <>
      {openDialogAdd
        && (
        <AddEnsayos
          title="Nuevo Ensayo"
          setShowDialog={setOpenDialogAdd}
          showDialog={openDialogAdd}
          {...editEnsayo}
        />
        )}
      {openConfirmDelete && (
      <Dialog
        open={openConfirmDelete}
        title="Eliminar Ensayo"
        onClose={() => setOpenConfirmDelete(false)}
        content={(
          <Grid container spacing={2} style={{ textAlign: 'center' }}>
            <Grid item xs={12}>
              <p>¿Está seguro que desea eliminar el ensayo?</p>
            </Grid>
            <Grid item xs={6}>
              <ButtonAdorned
                onClick={() => deleteOneEnsayo(idSelected)}
                color="secondary"
                disabled={isEnsayosLoading}
                loading={isEnsayosLoading}
              >
                Eliminar
              </ButtonAdorned>
            </Grid>
            <Grid item xs={6}>
              <ButtonAdorned
                onClick={() => setOpenConfirmDelete(false)}
                color="primary"
                disabled={isEnsayosLoading}
                loading={isEnsayosLoading}
              >
                Cancelar
              </ButtonAdorned>
            </Grid>

          </Grid>
        )}
      />
      ) }
      <Grid container>
        <Grid item xs={12}>
          <Dialog
            open={showDialog}
            title={title}
            fullWidth
            onClose={() => setShowDialog(false)}
            actions={[
              {
                label: 'Nuevo', onClick: () => actionSelected('add'), color: 'secondary', disabled: data.length === 0 || isEnsayosLoading,
              },
              {
                label: 'Cerrar', onClick: () => setShowDialog(false), color: 'primary', disabled: data.length === 0 || isEnsayosLoading,
              },
            ]}
            content={
            data.length === 0 ? <LinearProgress /> : (
              <DataGrid
                checkboxSelection
                rowsPerPage={5}
                disableSelectionOnClick
                columns={columns}
                rows={filteredData}
                key="id"
                components={{ Toolbar: QuickSearchToolbar }}
                onSelectionModelChange={(rowId) => getSelectedData(rowId)}
                sx={{
                  boxShadow: 1,
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                }}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(''),
                  },
                }}
              />
            )
          }
          />
        </Grid>
      </Grid>
    </>
  );
};

EnsayosModal.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setDataSelected: PropTypes.func,
};
EnsayosModal.defaultProps = {
  setDataSelected: () => {},
};

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default EnsayosModal;
