import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const Subtitle = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} {...rest}>{children}</Typography>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    opacity: '0.8',
    fontWeight: 'bold',
  },
}));

Subtitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Subtitle;
