import { useState, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import i18n from '@i18n';

const useAuth = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const logout = useCallback(() => {
    firebase.auth().signOut();
  }, []);

  const sendEmailVerification = useCallback(async () => {
    setState({ loading: true, error: null });
    try {
      await firebase.auth().currentUser.sendEmailVerification();
    } catch (e) {
      setState({ loading: false, error: null });
    }
  });

  const sendPasswordResetEmail = useCallback(async (email) => {
    setState({ loading: true, error: null });
    try {
      firebase.auth().languageCode = i18n.appLanguage;
      await firebase.auth().sendPasswordResetEmail(email);
      setState({ loading: false, error: null });
    } catch (e) {
      setState({ loading: false, error: null });
    }
  }, []);

  const signInWithEmailAndPassword = useCallback(async (email, password) => {
    setState({ loading: true, error: null });
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      setState({ loading: false, error: null });
    } catch (e) {
      setState({
        loading: false,
        error: {
          type: 'dialog',
          text: i18n.hooks.useAuth[e.code] || e.message,
        },
      });
    }
  }, []);

  return {
    isAuthLoading: state.loading,
    userError: state.error,
    logout,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
  };
};

export default useAuth;
