import React from 'react';
import { Button, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';

const AddButton = ({ onClick, children }) => {
  const AddButtonSmDown = () => (
    <Button variant="contained" color="primary" onClick={onClick}>
      <AddIcon />
    </Button>
  );

  const AddButtonMdUp = () => (
    <Button variant="contained" color="primary" onClick={onClick} startIcon={<AddIcon />}>
      {children}
    </Button>
  );

  return (
    <>
      <Hidden smDown><AddButtonMdUp /></Hidden>
      <Hidden mdUp><AddButtonSmDown /></Hidden>
    </>
  );
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

AddButton.defaultProps = {
  children: undefined,
};

export default AddButton;
