import React, { useEffect, useContext } from 'react';
import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import AuthContext from '@contexts/AuthContext';

const NotAuthLayout = ({ children }) => {
  const history = useHistory();
  const { isLogged, isInitializing, auth } = useContext(AuthContext);

  const redirectTo = {
    employee: '/e/',
    customer: '/c/',
  };

  useEffect(() => {
    if (isLogged) {
      history.push(redirectTo[auth.super_rol]);
    }
  }, [isInitializing, isLogged, history]);

  return (isInitializing ? <LinearProgress /> : children);
};

NotAuthLayout.propTypes = {
  children: PropTypes.node,
};

NotAuthLayout.defaultProps = {
  children: '',
};

export default NotAuthLayout;
