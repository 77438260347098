import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, Grid, TextField,
} from '@material-ui/core';
import {
  MultiFileUpload, Subtitle, SimpleList,
} from '@components/atoms';
import i18n from '@i18n';

const AnalisisDetails = ({
  analisis, hideLaboratoristas,
}) => {
  const { components: { analisisDetails: texts } } = i18n;
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Subtitle>{texts.title}</Subtitle>
        <Grid container spacing={2} style={{ marginTop: '8px' }}>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth>
              <TextField
                label={texts.number}
                variant="outlined"
                value={analisis.number}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth>
              <MultiFileUpload
                title={texts.docsIngreso}
                urls={analisis.docsIngreso}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormControl fullWidth>
              <MultiFileUpload
                title={texts.docsPlantilla}
                urls={analisis.docsPlantilla}
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {!hideLaboratoristas && (
      <Grid item xs={4}>
        <Subtitle>Laboratoristas</Subtitle>
        <Grid container spacing={2} style={{ marginTop: '8px' }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <SimpleList items={analisis.members} />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      )}
    </Grid>
  );
};

AnalisisDetails.propTypes = {
  analisis: PropTypes.shape({
    number: PropTypes.string,
    docsIngreso: PropTypes.arrayOf(PropTypes.shape({})),
    docsPlantilla: PropTypes.arrayOf(PropTypes.shape({})),
    members: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  hideLaboratoristas: PropTypes.bool,
};

AnalisisDetails.defaultProps = {

  hideLaboratoristas: false,
};

export default AnalisisDetails;
