import React from 'react';
import {
  Stepper as MuiStepper, Step, StepLabel, StepConnector, FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  ListAlt as ListAltIcon,
  Ballot as BallotIcon,
  GroupAdd as GroupAddIcon,
  Settings as SettingsIcon,
  BorderColor as BorderColorIcon,
} from '@material-ui/icons';

function ColorlibStepIcon({ active, completed, icon }) {
  const classes = useColorlibStepIconStyles();

  const icons = {
    1: <ListAltIcon />,
    2: <GroupAddIcon />,
    3: <SettingsIcon />,
    4: <BallotIcon />,
    5: <BorderColorIcon />,
    6: <AssignmentTurnedInIcon />,
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(icon)]}
    </div>
  );
}

const Stepper = ({
  activeStep, steps, error, helperText,
}) => {
  const classes = useStyles();
  return (
    <>
      <MuiStepper
        alternativeLabel
        activeStep={steps.findIndex((step) => step.step === activeStep) || 0}
        connector={<ColorlibConnector />}
        className={classes.root}
      >
        {steps.map(({ label }) => (
          <Step key={label} className={classes.step}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </MuiStepper>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </>
  );
};

Stepper.propTypes = {
  activeStep: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape()),
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

Stepper.defaultProps = {
  error: false,
  steps: [],
  helperText: undefined,
};

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    padding: '0px',
    marginBottom: '24px',
  },
  step: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
});

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

ColorlibStepIcon.defaultProps = {
  active: false,
  completed: false,
  icon: PropTypes.node,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

export default Stepper;
