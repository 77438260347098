import * as validator from 'yup';

import i18n from '@i18n';

const editAnalisisSchema = validator.object({
  customer: validator.object({
    id: validator.string().required(i18n.validationSchemas.customer.required),
    name: validator.string(),
  }),
  presupuesto: validator.object({
    id: validator.string().required(i18n.validationSchemas.presupuesto.required),
    number: validator.string(),
  }),
  number: validator
    .string()
    .required(i18n.validationSchemas.analisis.required),
  state: validator.object({
    id: validator.string().required(i18n.validationSchemas.state.required),
    name: validator.string(),
  }),
});

export default editAnalisisSchema;
