import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField, Divider,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import { usePresupuestos, useStates } from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, Select, MultiFileUpload, ChipStep,
} from '@components/atoms';
import { Messaging } from '@components/molecules';
import { editPresupuestoSchema } from '@helpers/schemas';
import constants from '@helpers/constants';
import { getCompleteName, getDate, getStatePresupuesto } from '@helpers/utils';

const Edit = ({ id, setExtraInfo }) => {
  const { pages: { customers: { presupuestos: { edit: texts } } } } = i18n;
  const history = useHistory();
  const {
    getPresupuesto, editPresupuesto, isPresupuestoLoading, presupuestoError,
  } = usePresupuestos();
  const { getStates, isStateLoading, stateError } = useStates();

  const [presupuesto, setPresupuesto] = useState(null);
  const [message, setMessage] = useState('');
  const [states, setStates] = useState([]);
  const [showMessaging, setShowMessaging] = useState(false);

  const handleClickMessage = async () => {
    if (message) {
      await editPresupuesto({ id: presupuesto.id, message });
      setMessage('');
      await refreshPresupuesto();
    }
  };

  const refreshPresupuesto = async () => {
    setExtraInfo([]);
    const serviceStates = await getStates();
    const servicePresupuesto = await getPresupuesto({ id });
    const currentState = serviceStates.find((state) => (state.id === servicePresupuesto.state.id));

    const updated = `Última actualización por: ${getCompleteName(servicePresupuesto.updatedBy)} | ${getDate(servicePresupuesto.updatedAt)}`;
    const created = `Creado por: ${getCompleteName(servicePresupuesto.createdBy)} | ${getDate(servicePresupuesto.createdAt)}`;
    setExtraInfo([created || '', updated || '']);

    setPresupuesto({
      ...presupuesto,
      id: servicePresupuesto.id,
      customer: servicePresupuesto.contact ? servicePresupuesto.contact.customer : '',
      contact: servicePresupuesto.contact || '',
      fileURL: servicePresupuesto.fileURL || '',
      number: servicePresupuesto.number || '',
      description: servicePresupuesto.description || '',
      state: currentState || {},
      comment: servicePresupuesto.comment || '',
      messaging: servicePresupuesto.messaging || [],
    });
    if (currentState.editAllowed) {
      setStates(serviceStates.filter((state) => {
        const isCurrentState = state.id === currentState.id;
        const isNewStateAllowed = state.newStateAllowed.customer;
        return (isCurrentState || isNewStateAllowed);
      }));
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: presupuesto,
    enableReinitialize: true,
    validationSchema: editPresupuestoSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const updated = await editPresupuesto({ id: values.id, state: values.state, comment: values.comment });
      if (updated) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/c/presupuestos');
      }
    },
  });

  useEffect(async () => {
    await refreshPresupuesto();
  }, []);

  useEffect(async () => {
    if (!presupuesto) { return; }
    const hasMessaging = presupuesto.messaging && presupuesto.messaging.length > 0;
    const isStateToMessage = presupuesto.state && presupuesto.state.id === constants.STATE.DISPONIBLE;
    setShowMessaging(isStateToMessage || hasMessaging);
  }, [presupuesto]);

  const Buttons = () => (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <ButtonAdorned color="default" onClick={() => history.push('/c/presupuestos')}>
          {texts.list}
        </ButtonAdorned>
      </Grid>
      {(presupuesto && form.values) && presupuesto.state.editAllowed && (
      <Grid item>
        <form onSubmit={form.handleSubmit}>
          <ButtonAdorned loading={isPresupuestoLoading} disabled={isPresupuestoLoading || presupuesto.state.id === form.values.state.id} type="submit">
            {texts.save}
          </ButtonAdorned>
        </form>
      </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {presupuestoError && <Alert>{presupuestoError.message}</Alert>}
      {stateError && <Alert>{stateError.message}</Alert>}
      {(isPresupuestoLoading || isStateLoading) && <LinearProgress />}
      {(!isPresupuestoLoading && !isStateLoading && presupuesto) && (
        <>
          <Grid container spacing={2}>
            {showMessaging && (
            <>
              <Grid item xs={12} md={4}>
                <Messaging
                  items={presupuesto.messaging}
                  state={presupuesto.state}
                  message={message}
                  setMessage={setMessage}
                  handleClickMessage={handleClickMessage}
                  loading={isPresupuestoLoading}
                />
              </Grid>
              <Grid item container xs={12} md={1} justifyContent="center">
                <Divider orientation="vertical" />
              </Grid>
            </>
            )}
            <Grid item xs={12} md={showMessaging ? 7 : 12}>
              <Grid container spacing={2}>
                {texts.subtitleData && (
                <Grid item xs={12} align="left">
                  <Subtitle>{texts.subtitleData}</Subtitle>
                </Grid>
                )}
                <Grid item xs={12} md={12} lg={4}>
                  <FormControl fullWidth>
                    <TextField
                      label={texts.customer}
                      value={presupuesto.customer.name || ''}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth>
                    <TextField
                      label={texts.contact}
                      value={`${presupuesto.contact.name} ${presupuesto.contact.lastname}` || ' - '}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth>
                    <TextField
                      label={texts.number}
                      value={presupuesto.number || ' - '}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={8}>
                  <FormControl fullWidth>
                    <TextField
                      label={texts.description}
                      value={presupuesto.description || ' - '}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth>
                    <MultiFileUpload
                      title={texts.file}
                      urls={[{ name: 'Presupuesto', url: presupuesto.fileURL }]}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {presupuesto && states.length > 1 ? (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        name="state"
                        label={texts.state}
                        value={form.values.state}
                        onChange={form.handleChange}
                        error={form.touched.state && Boolean(form.errors.state)}
                        disabled={isStateLoading}
                        items={states}
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <ChipStep
                        label={getStatePresupuesto(presupuesto.state.name).step}
                        backgroundColor={getStatePresupuesto(presupuesto.state.name).backgroundColor}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={8}>
                  <FormControl fullWidth>
                    {(presupuesto && presupuesto.comment) && (
                    <TextField
                      label={texts.comment}
                      value={presupuesto.comment}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    )}
                    {(presupuesto && form.values) && presupuesto.state.editAllowed && presupuesto.state.id !== form.values.state.id && (
                    <TextField
                      name="comment"
                      label={texts.comment}
                      variant="outlined"
                      value={form.values.comment}
                      onChange={form.handleChange}
                      error={form.touched.comment && Boolean(form.errors.comment)}
                      helperText={form.touched.comment && form.errors.comment}
                    />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Buttons />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string,
  setExtraInfo: PropTypes.func,
};

Edit.defaultProps = {
  id: '',
  setExtraInfo: () => {},
};

export default Edit;
