import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const GridBorderContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <Box borderRadius={12} className={classes.root}>
        <Grid container direction="column">
          {children}
        </Grid>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '3em',
    padding: '2em',
    border: '1px solid rgba(0,0,0,0.2)',
    [theme.breakpoints.down('xs')]: {
      padding: '1em',
      border: '0',
    },
  },
}));

GridBorderContainer.propTypes = {
  children: PropTypes.node,
};

GridBorderContainer.defaultProps = {
  children: {},
};

export default GridBorderContainer;
