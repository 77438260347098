import * as validator from 'yup';

import i18n from '@i18n';

// regex: at least 1 digit and between 6 and 20 chars
const regex = /^(?=.*\d).{6,20}$/;

const passwordSchema = validator
  .string()
  .matches(regex, i18n.validationSchemas.password.notMatches)
  .required(i18n.validationSchemas.password.required);

export default passwordSchema;
