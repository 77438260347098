import React, { useState, useEffect, useContext } from 'react';
import {
  Grid, Hidden, Menu, MenuItem, IconButton, LinearProgress, useMediaQuery,
} from '@material-ui/core';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton,
} from '@material-ui/data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  VisibilityOutlined as VerIcon,
  ListOutlined as MenuIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Alert, DataGrid } from '@components/atoms';
import getDate from '@helpers/utils/getDate';
import AuthContext from '@contexts/AuthContext';
import i18n from '@i18n';
import useInformes from '@hooks/useInformes';

const List = () => {
  const { pages: { employees: { informe: { list: texts } } } } = i18n;
  const history = useHistory();
  const classes = useStyles();
  const { getInformes, isInformeLoading, informesError } = useInformes();
  const [informes, setInformes] = useState(null);
  const [actionSelected, setActionSelected] = useState(null);
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const ltSmSize = useMediaQuery(theme.breakpoints.down('sm'));
  const onlyxsSize = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(async () => {
    setInformes(await getInformes());
  }, []);

  const handleClose = () => {
    setActionSelected(null);
  };

  const CustomToolbar = () => (
    <>
      <Hidden xsDown>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
      <Hidden smUp>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
    </>
  );

  const menuActions = (values) => (
    <>
      <Hidden xsDown>
        {user && user.permissions.informe.update && (
        <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/informes/edit/${values.row.id}`)}>
          <VerIcon />
        </IconButton>
        )}
      </Hidden>
      <Hidden smUp>
        <IconButton
          className={classes.actionIcons}
          onClick={(event) => setActionSelected({ anchorEl: event.currentTarget, id: values.row.id })}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={actionSelected && actionSelected.anchorEl}
                /* eslint-disable react/destructuring-assignment */
          open={actionSelected && actionSelected.id === values.row.id}
          onClose={handleClose}
        >
          {user && user.permissions.informe.update && (
          <MenuItem onClick={() => history.push(`/e/informes/edit/${values.row.id}`)}>Editar</MenuItem>
          )}
          {user && user.permissions.informe.update && (
          <MenuItem onClick={() => history.push(`/e/informes/view/${values.row.id}`)}>Ver</MenuItem>
          )}
        </Menu>
      </Hidden>
    </>
  );

  const columnsSm = [
    {
      field: 'contact',
      headerName: texts.columns.customer,
      valueGetter: (values) => values.row.contact.customer.name,
    },
    {
      field: 'analisis',
      headerAlign: 'center',
      headerName: texts.columns.number,
      align: 'center',
      valueGetter: (values) => values.row.analisis.number,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: texts.columns.createdAt,
      align: 'center',
      type: 'date',
      valueFormatter: (values) => getDate(values.value),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => menuActions(values),
    },
  ];

  const columns = [
    {
      field: 'contact',
      headerName: texts.columns.customer,
      flex: 0.5,
      valueGetter: (values) => values.row.contact.customer.name,
    },
    {
      field: 'analisis',
      headerAlign: 'center',
      headerName: texts.columns.number,
      align: 'center',
      flex: 0.4,
      valueGetter: (values) => values.row.analisis.number,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.value),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => menuActions(values),
    },
  ];

  return (
    <>
      {informesError && <Alert>{informesError.message}</Alert>}
      {(isInformeLoading) && <LinearProgress />}
      {informes && (
        <Grid container spacing={2}>
          <DataGrid
            sortModel={[{ field: 'createdAt', sort: 'desc' }]}
            columns={onlyxsSize ? columnsSm : columns}
            rows={informes}
            pageSize={ltSmSize ? 8 : 25}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

export default List;
