import React, { useEffect, useState } from 'react';
import {
  Grid, FormControl, TextField, InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import {
  useContacts, useCustomers, useInformes, useAnalisis,
} from '@hooks';
import {
  Alert, ButtonAdorned, MultiFileUpload, Autocomplete,
} from '@components/atoms';
import { createInformeSchema } from '@helpers/schemas';
import { CheckCircleRounded as CheckIcon, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';

const Create = () => {
  const { pages: { employees: { informe: { create: texts } } } } = i18n;
  const history = useHistory();
  const { isContactLoading, contactError, getContacts } = useContacts();
  const { getCustomers, isCustomerLoading, customerError } = useCustomers();
  const { getAnalisisAll, getFiles, isAnalisisLoading } = useAnalisis();
  const { createInforme, isInformeLoading, informeError } = useInformes();
  const [customers, setCustomers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [analisis, setAnalisis] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: {
      customer: undefined,
      contact: undefined,
      analisis: undefined,
      agreement: '',
      amount: 0,
      file: [],
      docInforme: [],
      docInformeCliente: [],
    },
    enableReinitialize: true,
    validationSchema: createInformeSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const created = await createInforme(values);
      if (created) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/informes');
      }
    },
  });

  useEffect(async () => {
    setCustomers(await getCustomers());
  }, []);

  const onChangeCustomer = async (params, value) => {
    form.setFieldValue('docInforme', []);
    form.setFieldValue('customer', value);
    form.setFieldValue('contact', undefined);
    form.setFieldValue('analisis', undefined);
    setAnalisis([]);
    if (value && value.id) {
      const allAnalisis = await getAnalisisAll(`?step=completado&customer.id=${value.id}&archived=false`);
      setAnalisis(allAnalisis.length !== 0 ? allAnalisis.map((analisisItem) => analisisItem) : '');
      form.setFieldValue('analisis', allAnalisis.length !== 0 ? '' : []);
    }
    setContacts([]);

    if (value && value.id) {
      setContacts(await getContacts(`?customer.id=${value.id}`));
    }
  };

  const handleChangeAnalisis = async (_, value) => {
    form.setFieldValue('docInforme', []);
    form.setFieldValue('analisis', value);
    form.setFieldValue('agreement', value.agreement);
    const doc = await getFiles({ customerId: form.values.customer.id || undefined, analisisId: value.id, filesType: 'redaccion' });
    form.setFieldValue('docInforme', doc);
  };

  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {contactError && <Alert>{contactError.message}</Alert>}
      {informeError && <Alert>{informeError.message}</Alert>}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id={texts.customer}
              key={texts.customer}
              label={texts.customer}
              onChange={onChangeCustomer}
              defaultValue={form.values.customer}
              error={form.touched.customer && Boolean(form.errors.customer)}
              disabled={isCustomerLoading || isInformeLoading}
              helperText={form.touched.customer && form.errors.customer && form.errors.customer.id}
              items={customers}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id={texts.contact}
              key={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
              label={texts.contact}
              defaultValue={form.values.contact}
              onChange={(_, value) => form.setFieldValue('contact', value)}
              error={form.touched.contact && Boolean(form.errors.contact)}
              disabled={isContactLoading || isInformeLoading}
              helperText={(form.values.customer && contacts.length === 0 && !isAnalisisLoading && !isContactLoading && texts.noContacts)
                || (form.touched.contact && form.errors.contact && form.errors.contact.id)}
              items={contacts}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id={texts.analisis}
              label={texts.analisis}
              key={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
              onChange={handleChangeAnalisis}
              defaultValue={form.values.analisis}
              error={form.touched.analisis && Boolean(form.errors.analisis)}
              disabled={isCustomerLoading || isInformeLoading}
              helperText={(form.values.contact && analisis.length === 0 && !isInformeLoading && !isAnalisisLoading && texts.noAnalisis)
                || (form.touched.analisis && form.errors.analisis && form.errors.analisis.id && texts.noAnalisis)}
              items={analisis}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          { form.values.analisis?.id
            && (
            <FormControl fullWidth>
              <TextField
                label={texts.agreement}
                variant="outlined"
                disabled
                value={form.values.analisis.agreement ? 'Con convenio' : 'Sin convenio'}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      {(form.values.analisis.agreement ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />)}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <MultiFileUpload
              title={texts.docInforme}
              urls={form.values.docInforme}
              disabled
              loading={(analisis.length !== 0 && isAnalisisLoading) || isInformeLoading}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <MultiFileUpload
              title={texts.docInformeCliente}
              name="docInformeCliente"
              onChange={form.handleChange}
              value={form.values.docInformeCliente}
              error={form.touched.docInformeCliente && Boolean(form.errors.docInformeCliente)}
              helperText={form.touched.docInformeCliente && form.errors.docInformeCliente && form.errors.docInformeCliente}
              multiple
              disabled={isAnalisisLoading || isInformeLoading}
            />
          </FormControl>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <ButtonAdorned color="default" onClick={() => history.push('/e/informes')}>
              {texts.list}
            </ButtonAdorned>
          </Grid>
          <Grid item>
            <form onSubmit={form.handleSubmit}>
              <ButtonAdorned loading={isInformeLoading} disabled={isInformeLoading} type="submit">
                {texts.save}
              </ButtonAdorned>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Create.propTypes = {
};

Create.defaultProps = {
};

export default Create;
