import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Grid, Box, Card, CardContent, CardHeader, Divider, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const PieChart = ({ data: propData, backgroundColor }) => {
  const classes = useStyles();
  const { data: { items } } = propData;

  const data = {
    datasets: [
      {
        data: items.map(({ count }) => count),
        backgroundColor,
        borderWidth: 1,
      },
    ],
    labels: items.map(({ name }) => name),
  };
  return (
    <>
      <Grid item xs={10} sm={4}>
        <Card className={`${classes.border} ${classes.grBox}`}>
          <CardHeader title={propData.name} />
          <Divider />
          <CardContent>
            <Box
              sx={{
                height: 'max-content',
                position: 'relative',
              }}
            >
              <Doughnut
                data={data}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pt: 2,
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  grBox: {
    backgroundColor: '#F4F1DE',

  },
  border: {
    borderRadius: '0 0 .5em .5em',
    margin: '0 0 .5rem',
  },
}));

PieChart.propTypes = {
  data: PropTypes.shape({}),
  backgroundColor: PropTypes.arrayOf(PropTypes.string),
};

PieChart.defaultProps = {
  data: {
    count: {
      items: [],
    },
  },
  backgroundColor: [],
};

export default PieChart;
