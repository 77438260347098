import { useState, useCallback } from 'react';
import { getSortedArrayOfObjects } from '@helpers/utils';
import {
  getServiceCustomers, getServiceCustomer, postServiceCustomer, putServiceCustomer, deleteServiceCustomer,
} from '../services/customers';

const useCustomers = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const getCustomers = useCallback(async () => {
    setState({ loading: true, error: null });
    try {
      const customers = await getServiceCustomers();
      setState({ loading: false, error: null });
      return getSortedArrayOfObjects(customers, 'name');
    } catch (e) {
      setState({ loading: false, error: e });
      return [];
    }
  });

  const getCustomer = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const employee = await getServiceCustomer(data);
      setState({ loading: false, error: null });
      return employee;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const createCustomer = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await postServiceCustomer(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const editCustomer = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await putServiceCustomer(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const deleteCustomer = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const customer = await deleteServiceCustomer(data);
      setState({ loading: false, error: null });
      return customer;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  return {
    isCustomerLoading: state.loading,
    customerError: state.error,
    getCustomers,
    getCustomer,
    createCustomer,
    editCustomer,
    deleteCustomer,
  };
};

export default useCustomers;
