import * as validator from 'yup';

import i18n from '@i18n';

const createAsignacionAnalisisSchema = validator.object({
  docsPlantilla: validator
    .array()
    .min(1, i18n.validationSchemas.file.required),
  members: validator
    .array()
    .min(1, i18n.validationSchemas.members.required),
});

export default createAsignacionAnalisisSchema;
