import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import './index.css';
import { SnackbarProvider } from 'notistack';
import { AuthContextProvider } from '@contexts/AuthContext';
import App from './App';
import Theme from './Theme';
import { FIREBASE_CONFIG } from './infraestructure/configs';

// import reportWebVitals from './reportWebVitals';

firebase.initializeApp(FIREBASE_CONFIG);

ReactDOM.render(
  <AuthContextProvider>
    <ThemeProvider theme={Theme}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </AuthContextProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
