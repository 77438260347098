import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServiceAnalisisAll(data = '') {
  try {
    const response = await API.get(`/analisis${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/analisis/', message: `Hubo un problema al intentar buscar analisis. ${message}` });
  }
}

export async function getServiceAnalisis(data) {
  try {
    const response = await API.get(`/analisis/${data.id}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/analisis/', message: `Hubo un problema al intentar buscar analisis. ${message}` });
  }
}

export async function getServiceAnalisisReport(data = '') {
  try {
    const response = await API.get(`/reports/${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/reports/', message: `Hubo un problema al intentar buscar analisis. ${message}` });
  }
}

export async function postServiceAnalisis(data) {
  try {
    const response = await API.post('/analisis', data);
    if (response && response.headers && response.headers.location) {
      return response.headers.location;
    }
    return '';
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/analisis/', message: `Hubo un problema al intentar crear analisis. ${message}` });
  }
}

export async function putServiceAnalisis(data) {
  try {
    const response = await API.put('/analisis', data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/analisis/', message: `Hubo un problema al intentar editar analisis. ${message}` });
  }
}

export async function getServiceAnalisisCount() {
  try {
    const response = await API.get('/analisis/d/count');
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/analisis/', message: `Hubo un problema al intentar buscar analisis. ${message}` });
  }
}

export async function deleteServiceAnalisis(id) {
  try {
    await API.delete(`/analisis/${id}`);
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/analisis/', message: `Hubo un problema al intentar anular analisis. ${message}` });
  }
}
