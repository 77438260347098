const presupuestos = {
  title: 'Presupuestos',
  list: {
    title: 'Presupuestos',
    columns: {
      customer: 'Cliente',
      contact: 'Contacto',
      file: 'Archivo',
      number: 'Número',
      description: 'Descripción',
      state: 'Estado',
      createdBy: 'Creado por',
      createdAt: 'Fecha creación',
    },
  },
  edit: {
    customer: 'Cliente',
    contact: 'Contacto',
    file: 'Archivo',
    number: 'Nro de presupuesto',
    description: 'Descripción',
    state: 'Estado',
    save: 'Guardar',
    list: 'Volver a la lista',
    subtitleData: 'Datos del presupuesto',
    success: 'Presupuesto editado exitosamente',
    comment: 'Comentario del cambio de estado',
    messaging: 'Mensajería',
    send: 'Enviar',
    showPresupuesto: 'Mostrar anteriores',
    hidePresupuesto: 'Ocultar anteriores',
  },
};

export default presupuestos;
