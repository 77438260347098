import * as validator from 'yup';
import { validateRut } from '@fdograph/rut-utilities';

import i18n from '@i18n';

const createCustomerSchema = validator.object({
  name: validator
    .string()
    .required(i18n.validationSchemas.name.required),
  address: validator
    .string()
    .required(i18n.validationSchemas.address.required),
  rut: validator
    .string()
    .required(i18n.validationSchemas.rut.required)
    .test('rut', i18n.validationSchemas.rut.notValid, validateRut),
  giro: validator
    .string(),
});

export default createCustomerSchema;
