import React, { useState, useEffect, useContext } from 'react';
import {
  Grid, Hidden, IconButton, Menu, MenuItem, LinearProgress, useMediaQuery,
} from '@material-ui/core';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton,
} from '@material-ui/data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  EditOutlined as EditarIcon,
  VisibilityOutlined as VerIcon,
  ListOutlined as MenuIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSolicitudes, useStates } from '@hooks';
import { Alert, ChipStep, DataGrid } from '@components/atoms';
import getDate from '@helpers/utils/getDate';
import constantsSolicitudes from '@helpers/constants/solicitudes';

import AuthContext from '@contexts/AuthContext';
import i18n from '@i18n';

const List = () => {
  const { pages: { employees: { solicitudes: { list: texts } } } } = i18n;
  const history = useHistory();
  const classes = useStyles();
  const { getSolicitudes, isSolicitudesLoading, solicitudesError } = useSolicitudes();
  const { getStates, isStateLoading, stateError } = useStates();
  const [solicitudes, setSolicitudes] = useState(null);
  const [states, setStates] = useState([]);
  const [actionSelected, setActionSelected] = useState(null);
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const ltSmSize = useMediaQuery(theme.breakpoints.down('sm'));
  const onlyxsSize = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(async () => {
    setSolicitudes(await getSolicitudes());
    setStates(await getStates());
  }, []);

  const handleClose = () => {
    setActionSelected(null);
  };

  const CustomToolbar = () => (
    <>
      <Hidden xsDown>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
      <Hidden smUp>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
    </>
  );

  const menuActions = (currentState, values) => (
    <>
      <Hidden xsDown>
        {currentState.editAllowed && user && user.permissions.solicitudes.update && (
        <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/solicitudes/edit/${values.row.id}`)}>
          <EditarIcon />
        </IconButton>
        )}
        {user && user.permissions.presupuesto.read && (
        <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/solicitudes/view/${values.row.id}`)}>
          <VerIcon />
        </IconButton>
        )}
      </Hidden>
      <Hidden smUp>
        <IconButton
          className={classes.actionIcons}
          onClick={(event) => setActionSelected({ anchorEl: event.currentTarget, id: values.row.id })}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={actionSelected && actionSelected.anchorEl}
                /* eslint-disable react/destructuring-assignment */
          open={actionSelected && actionSelected.id === values.row.id}
          onClose={handleClose}
        >
          {currentState.editAllowed && user && user.permissions.solicitudes.update && (
          <MenuItem onClick={() => history.push(`/e/solicitudes/edit/${values.row.id}`)}>Editar</MenuItem>
          )}
          {user && user.permissions.presupuesto.read && (
          <MenuItem onClick={() => history.push(`/e/solicitudes/view/${values.row.id}`)}>Ver</MenuItem>
          )}
        </Menu>
      </Hidden>
    </>
  );

  const columnsSm = [
    {
      field: 'title',
      headerName: texts.columns.title,
      width: 100,
      // flex: 0.5,
      valueGetter: (values) => values.row.title,
    },
    {
      field: 'customer',
      headerAlign: 'center',
      headerName: texts.columns.customer,
      align: 'center',
      // flex: 0.6,
      valueGetter: (values) => (values.row.contact.customer.name),
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: texts.columns.createdAt,
      align: 'center',
      // flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.value),
    },
    {
      field: 'state',
      headerName: texts.columns.state,
      renderCell: (values) => {
        const { row: { state: { name } } } = values;
        const color = constantsSolicitudes.STATES_SOLICITUDES.find((item) => item.step === name);

        return (name ? (
          <ChipStep label={name} backgroundColor={color.backgroundColor} />
        ) : null);
      },
      // flex: 0.5,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => {
        const currentState = states.find((state) => state.id === values.row.state.id);
        return (menuActions(currentState, values));
      },
    },
  ];

  const columns = [
    {
      field: 'title',
      headerName: texts.columns.title,
      width: 100,
      flex: 0.5,
      valueGetter: (values) => values.row.title,
    },
    {
      field: 'customer',
      headerAlign: 'center',
      headerName: texts.columns.customer,
      align: 'center',
      flex: 0.6,
      valueGetter: (values) => (values.row.contact.customer.name),
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.value),
    },
    {
      field: 'state',
      headerName: texts.columns.state,
      renderCell: (values) => {
        const { row: { state: { name } } } = values;
        const color = constantsSolicitudes.STATES_SOLICITUDES.find((item) => item.step === name);

        return (name ? (
          <ChipStep label={name} backgroundColor={color.backgroundColor} />
        ) : null);
      },
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: (values) => {
        const currentState = states.find((state) => state.id === values.row.state.id);
        return (menuActions(currentState, values));
      },
    },
  ];

  return (
    <>
      {solicitudesError && <Alert>{solicitudesError.message}</Alert>}
      {stateError && <Alert>{stateError.message}</Alert>}
      {(isSolicitudesLoading || isStateLoading) && <LinearProgress />}
      {solicitudes && states.length > 0 && (
        <Grid container spacing={2}>
          <DataGrid
            sortModel={[{ field: 'createdAt', sort: 'desc' }]}
            columns={onlyxsSize ? columnsSm : columns}
            rows={solicitudes}
            pageSize={ltSmSize ? 8 : 25}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

export default List;
