import React, { useEffect, useState } from 'react';
import {
  Grid, LinearProgress, FormControl, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { getRutFormatted } from '@helpers/utils';
import { useEmployees } from '@hooks';
import i18n from '@i18n';
import {
  Alert, ButtonAdorned, Subtitle, ChipDisabled,
} from '@components/atoms';

const View = ({ id }) => {
  const classes = useStyles();
  const { pages: { employees: { employees: { view: texts } } } } = i18n;
  const history = useHistory();
  const { getEmployee, isEmployeeLoading, employeeError } = useEmployees();
  const [employee, setEmployee] = useState();

  useEffect(async () => {
    setEmployee(await getEmployee({ id }));
  }, []);

  return (
    <>
      {employeeError && <Alert>{employeeError.message}</Alert>}
      {isEmployeeLoading && <LinearProgress />}
      {employee && (
        <>
          <Grid container spacing={2}>
            {texts.subtitleData && (
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.rut}
                  value={employee.rut ? getRutFormatted(employee.rut) : ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.title}
                  value={employee.title || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.name}
                  value={employee.name || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.lastname}
                  value={employee.lastname || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.phoneNumber}
                  value={employee.phoneNumber || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  label={texts.rol}
                  value={employee.roles && employee.roles.length > 0 ? employee.roles.map((rol) => rol.name).join(', ') : ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleAccount}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.email}
                  value={employee.email || ' - '}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
              {employee.disabled && (
                <Grid item>
                  <ChipDisabled />
                </Grid>
              )}
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push('/e/colaboradores')}>
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <ButtonAdorned loading={isEmployeeLoading} disabled={isEmployeeLoading} onClick={() => history.push(`/e/colaboradores/edit/${employee.id}`)}>
                  {texts.edit}
                </ButtonAdorned>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

View.propTypes = {
  id: PropTypes.string,
};

View.defaultProps = {
  id: '',
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
}));

export default View;
