import React from 'react';
import {
  Grid, TextField, FormControl, InputAdornment,
} from '@material-ui/core';
import { CheckCircleRounded as CheckIcon, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const Form = ({ user, auth, texts }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.title}
          value={user.title || ' - '}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.name}
          value={user.name || ' - '}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.lastname}
          value={user.lastname || ' - '}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.email}
          value={user.email || ' - '}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.phoneNumber}
          value={user.phoneNumber || ' - '}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.emailVerified}
          value={auth && auth.email_verified ? 'Verificado' : 'No verificado'}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                {auth && auth.email_verified ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  </Grid>
);

Form.propTypes = {
  user: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    lastname: PropTypes.string,
    rut: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }),
  auth: PropTypes.shape({
    email_verified: PropTypes.bool,
  }),
  texts: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    lastname: PropTypes.string,
    rut: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    roles: PropTypes.string,
    emailVerified: PropTypes.string,
  }),
};

Form.defaultProps = {
  user: {
    title: ' - ',
    name: ' - ',
    lastname: ' - ',
    rut: ' - ',
    email: ' - ',
    phoneNumber: ' - ',
    roles: [],
  },
  auth: {
    emailVerified: false,
  },
  texts: {
    title: '',
    name: '',
    lastname: '',
    rut: '',
    email: '',
    phoneNumber: '',
    roles: '',
  },
};

export default Form;
