import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, List, ListItemText, FormHelperText, CardContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const SimpleList = ({ items, error, helperText }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <CardContent className={classes.reset}>
          <List component="nav">
            {items.map((item) => <ListItemText primary={`${item.name} ${item.lastname}`} key={item.id} />)}
          </List>
        </CardContent>
      </Card>
      {error && <FormHelperText variant="outlined" error>{helperText}</FormHelperText>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  reset: {
    padding: '0px 0.5rem ',
    paddingBottom: '0px!important',

  },
}));

SimpleList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

SimpleList.defaultProps = {
  items: [],
  error: false,
  helperText: undefined,
};
export default SimpleList;
