import React from 'react';
import { Dialog, ButtonAdorned } from '@components/atoms';
import PropTypes from 'prop-types';
import {
  Grid, TextField, FormControl,
} from '@material-ui/core';
import { useEnsayos } from '@hooks';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { createMetodoEnsayoSchema } from '@helpers/schemas';
import i18n from '@i18n';

const AddEnsayos = ({
  showDialog, setShowDialog, title, action, data, refresh,
}) => {
  const { pages: { employees: { analisis: { metodoEnsayo: { create: texts } } } } } = i18n;
  const {
    createEnsayo, updateEnsayo, isEnsayosLoading,
  } = useEnsayos();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    id: action === 'edit' ? data.id : '',
    ensayo: action === 'edit' ? data.ensayo : '',
    material: action === 'edit' ? data.material : '',
    metodo: action === 'edit' ? data.metodo : '',
  };

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: createMetodoEnsayoSchema,
    validateOnChange: true,
    onSubmit: async ({ metodo, material, ensayo }) => {
      const valuesFormatted = {
        metodo: metodo.toUpperCase(),
        material: material.toUpperCase(),
        ensayo: ensayo.toUpperCase(),
      };
      if (action === 'edit') {
        const updated = await updateEnsayo({ ...valuesFormatted, id: data.id });
        if (updated) {
          enqueueSnackbar(texts.updated, { variant: 'success' });
        }
      } else if (action === 'add') {
        const created = await createEnsayo(valuesFormatted);
        if (created) {
          enqueueSnackbar(texts.success, { variant: 'success' });
        }
      }
      refresh();
      setShowDialog(false);
    },
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Dialog
          open={showDialog}
          title={title}
          onClose={() => setShowDialog(false)}
          content={(
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      name="material"
                      label={texts.material}
                      size="small"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      placeholder={texts.material}
                      variant="outlined"
                      value={form.values.material}
                      onChange={form.handleChange}
                      error={form.touched.material && Boolean(form.errors.material)}
                      helperText={form.touched.material && form.errors.material}
                      disabled={isEnsayosLoading}
                      autoComplete="new-material"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      name="ensayo"
                      label={texts.ensayo}
                      size="small"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      placeholder={texts.ensayo}
                      variant="outlined"
                      value={form.values.ensayo}
                      onChange={form.handleChange}
                      error={form.touched.ensayo && Boolean(form.errors.ensayo)}
                      helperText={form.touched.ensayo && form.errors.ensayo}
                      disabled={isEnsayosLoading}
                      autoComplete="new-ensayo"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      name="metodo"
                      label={texts.metodo}
                      size="small"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      placeholder={texts.metodo}
                      variant="outlined"
                      value={form.values.metodo}
                      onChange={form.handleChange}
                      error={form.touched.metodo && Boolean(form.errors.metodo)}
                      helperText={form.touched.metodo && form.errors.metodo}
                      disabled={isEnsayosLoading}
                      autoComplete="new-metodo"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <ButtonAdorned
                    color="secondary"
                    onClick={() => setShowDialog(false)}
                    disabled={isEnsayosLoading}
                  >
                    {texts.close}
                  </ButtonAdorned>
                </Grid>
                <Grid item>
                  <form onSubmit={form.handleSubmit}>
                    <ButtonAdorned
                      type="submit"
                      disabled={isEnsayosLoading}
                      loading={isEnsayosLoading}
                    >
                      {texts.save}
                    </ButtonAdorned>
                  </form>
                </Grid>
              </Grid>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};

AddEnsayos.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func.isRequired,
};
AddEnsayos.defaultProps = {
  data: null,
};

export default AddEnsayos;
