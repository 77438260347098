import { useState, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import constants from '@helpers/constants';
import {
  getServiceAnalisisAll, getServiceAnalisis, postServiceAnalisis, putServiceAnalisis, getServiceAnalisisCount, deleteServiceAnalisis,
} from '../services/analisis';

const moment = require('moment-timezone');

const useAnalisis = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const uploadFiles = useCallback(async ({
    files, filesType, customerId, analisisId,
  }) => {
    const promises = files.map(async (file) => {
      const refStorage = firebase.storage().ref(`${customerId}/analisis/${analisisId}/${filesType}/${file.name}`);
      await refStorage.put(file);
      const fileURL = await refStorage.getDownloadURL();
      return fileURL;
    });
    const fileURLs = await Promise.all(promises);
    return fileURLs;
  }, []);

  const removeFiles = useCallback(async ({
    filesType, customerId, analisisId,
  }) => {
    const refStorage = firebase.storage().ref(`${customerId}/analisis/${analisisId}/${filesType}`);
    const listResults = await refStorage.listAll();
    const promises = listResults.items.map((item) => item.delete());
    Promise.all(promises);
  }, []);

  const getAnalisisAll = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const analisisAll = await getServiceAnalisisAll(data);
      setState({ loading: false, error: null });
      return analisisAll;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const getAnalisis = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const analisis = await getServiceAnalisis(data);
      setState({ loading: false, error: null });
      return analisis;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const getFiles = useCallback(async ({ customerId, analisisId, filesType }) => {
    setState({ loading: true, error: null });
    try {
      const refStorage = firebase.storage().ref();
      const folder = refStorage.child(`${customerId}/analisis/${analisisId}/${filesType}`);
      const getData = (await folder.listAll()).items.map(async (detail) => {
        const metaData = await detail.getMetadata();
        const url = await detail.getDownloadURL();
        return {
          timeCreated: metaData.timeCreated, url, id: url, name: metaData.name,
        };
      });
      const result = await Promise.all(getData);
      setState({ loading: false, error: null });
      return result;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  }, []);

  const createAnalisis = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const { docsFichaIngreso, typeArea, ...analisis } = data;
      const analisisId = await postServiceAnalisis(analisis);
      if (docsFichaIngreso && docsFichaIngreso.length > 0 && docsFichaIngreso[0].size) {
        await uploadFiles({
          files: docsFichaIngreso,
          filesType: 'ingreso',
          analisisId,
          customerId: analisis.customer.id,
        });
      }
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const editAnalisis = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const {
        files, filesType, anexos, id, step, members, customer, presupuesto, number, agreement, area, sample, executeDate, limitDate, ensayos, qtyMuestras,
      } = data;
      if (files && files.length > 0 && files[0].size) {
        await removeFiles({
          filesType,
          analisisId: id,
          customerId: customer.id,
        });
        await uploadFiles({
          files,
          filesType,
          analisisId: id,
          customerId: customer.id,
        });
      }
      if (anexos && anexos.length > 0 && anexos[0].size) {
        await removeFiles({
          filesType: 'anexos',
          analisisId: id,
          customerId: customer.id,
        });
        await uploadFiles({
          files: anexos,
          filesType: 'anexos',
          analisisId: id,
          customerId: customer.id,
        });
      }

      const stepObject = constants.STATE_DATES.find((item) => item.step === step);
      const dateName = stepObject ? stepObject.dateName : null;

      await putServiceAnalisis({
        id,
        ...(dateName && { [dateName]: moment.tz('America/Santiago').format() }),
        ...(qtyMuestras && { qtyMuestras }),
        ...(limitDate && { limitDate }),
        ...(executeDate && { executeDate }),
        ...(step && { step }),
        ...(members && { members }),
        ...(customer && { customer }),
        ...(presupuesto && { presupuesto }),
        ...(number && { number }),
        ...(agreement && { agreement }),
        ...(area && { area }),
        ...(sample && { sample }),
        ...(ensayos && { ensayos }),
      });
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const archiveAnalisis = useCallback(async (id) => {
    setState({ loading: true, error: null });
    try {
      await deleteServiceAnalisis(id);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const getAnalisisCount = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const analisisCount = await getServiceAnalisisCount(data);
      setState({ loading: false, error: null });
      return analisisCount;
    } catch (e) {
      setState({ loading: false, error: e });
      return [];
    }
  });

  return {
    isAnalisisLoading: state.loading,
    analisisError: state.error,
    getAnalisisAll,
    getAnalisis,
    createAnalisis,
    editAnalisis,
    getFiles,
    getAnalisisCount,
    archiveAnalisis,
  };
};

export default useAnalisis;
