import React, { useContext, useState } from 'react';
import {
  makeStyles, Hidden, Drawer, useTheme, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Collapse,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  AccountCircleOutlined as PerfilIcon,
  ExitToAppOutlined as LogoutIcon,
  InsertDriveFileOutlined as PresupuestoIcon,
  DescriptionOutlined as AnalisisIcon,
  CreditCardOutlined as FacturacionIcon,
  ReceiptOutlined as InformeIcon,
  WorkOutlineOutlined as ClientesIcon,
  ContactPhoneOutlined as ContactosIcon,
  PeopleAltOutlined as ColaboradoresIcon,
  HelpOutline as HelpIcon,
  PostAdd as SolicitudIcon,
  VerifiedUser as VerifiedUserIcon,
  AssessmentOutlined as AssessmentOutlinedIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import { useAuth } from '@hooks';
import AuthContext from '@contexts/AuthContext';

const EmployeeNavBar = ({ mobileOpen, setMobileOpen, permissions }) => {
  const { components: { employeeNavBar: texts } } = i18n;
  const { pages: { employees: { reporteria: SubReportsTexts } } } = i18n;
  const { logout } = useAuth();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { user } = useContext(AuthContext);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const ulStyle = {
    padding: '3px 0px 3px 16px',
  };

  const Loading = () => (
    <List subheader={(
      <ListSubheader component="div"><Skeleton /></ListSubheader>
    )}
    >
      {[1, 2, 3].map((index) => (
        <ListItem button component={Link} to="" key={index}>
          <ListItemIcon><Skeleton /></ListItemIcon>
          <ListItemText primary={<Skeleton />} />
        </ListItem>
      ))}
    </List>
  );

  const General = () => (
    <List subheader={(
    !mobileOpen && (
      <ListSubheader component="div">General</ListSubheader>)
    )}
    >
      <ListItem button component={Link} style={ulStyle} to="/e/perfil" selected={location.pathname.includes('/e/perfil')}>
        <ListItemIcon><PerfilIcon color="primary" /></ListItemIcon>
        <ListItemText
          primary={`${user.name} ${user.lastname}`}
          secondary={(
            <>
              {user.roles.map((rol) => `${rol.name} `)}
            </>
          )}
        />
      </ListItem>
    </List>

  );

  const Admin = () => (
    <List subheader={(
      <ListSubheader component="div">Administración</ListSubheader>
      )}
    >
      <>
        <ListItem button component={Link} style={ulStyle} to="/e/colaboradores" selected={location.pathname.includes('/e/colaboradores')}>
          <ListItemIcon><ColaboradoresIcon color="primary" /></ListItemIcon>
          <ListItemText primary={texts.colaboradores} />
        </ListItem>
        <ListItem button component={Link} style={ulStyle} to="/e/clientes" selected={location.pathname.includes('/e/clientes')}>
          <ListItemIcon><ClientesIcon color="primary" /></ListItemIcon>
          <ListItemText primary={texts.clientes} />
        </ListItem>
        <ListItem button component={Link} style={ulStyle} to="/e/contactos" selected={location.pathname.includes('/e/contactos')}>
          <ListItemIcon><ContactosIcon color="primary" /></ListItemIcon>
          <ListItemText primary={texts.contactos} />
        </ListItem>
      </>
    </List>
  );

  const ListMenu = () => (
    <div>
      {permissions.isLoading && <Loading />}
      {!permissions.isLoading && (
        <>
          <General />
          {permissions.admin && permissions.admin.read && (<Admin />)}
          <List subheader={(
            <ListSubheader component="div">Gestión</ListSubheader>
          )}
          >
            {permissions.solicitudes && permissions.solicitudes.read && (
            <ListItem button component={Link} style={ulStyle} to="/e/solicitudes" selected={location.pathname.includes('/e/solicitudes')}>
              <ListItemIcon><SolicitudIcon color="primary" /></ListItemIcon>
              <ListItemText primary={texts.solicitudes} />
            </ListItem>
            )}
            {permissions.presupuesto && permissions.presupuesto.read && (
            <ListItem button component={Link} style={ulStyle} to="/e/presupuestos" selected={location.pathname.includes('/e/presupuestos')}>
              <ListItemIcon><PresupuestoIcon color="primary" /></ListItemIcon>
              <ListItemText primary={texts.presupuestos} />
            </ListItem>
            )}
            {permissions.analisis && permissions.analisis.read && (
            <ListItem button component={Link} style={ulStyle} to="/e/analisis" selected={location.pathname.includes('/e/analisis')}>
              <ListItemIcon><AnalisisIcon color="primary" /></ListItemIcon>
              <ListItemText primary={texts.analisis} />
            </ListItem>
            )}
            {permissions.informe && permissions.informe.read && (
            <ListItem button component={Link} style={ulStyle} to="/e/informes" selected={location.pathname.includes('/e/informes')}>
              <ListItemIcon><InformeIcon color="primary" /></ListItemIcon>
              <ListItemText primary={texts.informe} />
            </ListItem>
            )}
            {permissions.facturacion && permissions.facturacion.read && (
            <ListItem button component={Link} style={ulStyle} to="/e/facturacion" selected={location.pathname.includes('/e/facturacion')}>
              <ListItemIcon><FacturacionIcon color="primary" /></ListItemIcon>
              <ListItemText primary={texts.facturacion} />
            </ListItem>
            )}
            {permissions.informe.read && (
              <>
                <ListItem button onClick={() => setOpenSubmenu(!openSubmenu)}>
                  <ListItemIcon><AssessmentOutlinedIcon color="primary" /></ListItemIcon>
                  <ListItemText primary={texts.reporteria} />
                  {openSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
                {/* MENU DESPLEGABLE PARA SUBREPORTES */}
                <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button component={Link} to="/e/reporteria/ingresosxarea" selected={location.pathname.includes('/e/reporteria/ingresosxarea')} className={classes.nested}>
                      <ListItemIcon><AssessmentOutlinedIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={SubReportsTexts.subreports.ingresosxarea.title} />
                    </ListItem>
                    <ListItem button component={Link} to="/e/reporteria/ensayosbyqty" selected={location.pathname.includes('/e/reporteria/ensayosbyqty')} className={classes.nested}>
                      <ListItemIcon><AssessmentOutlinedIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={SubReportsTexts.subreports.ensayosbyqty.title} />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/e/reporteria/ensayosbylaboratorista"
                      selected={location.pathname.includes('/e/reporteria/ensayosbylaboratorista')}
                      className={classes.nested}
                    >
                      <ListItemIcon><AssessmentOutlinedIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={SubReportsTexts.subreports.ensayosbylaboratorista.title} />
                    </ListItem>
                    <ListItem button component={Link} to="/e/reporteria/muestrasbylab" selected={location.pathname.includes('/e/reporteria/muestrasbylab')} className={classes.nested}>
                      <ListItemIcon><AssessmentOutlinedIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={SubReportsTexts.subreports.muestrasbylab.title} />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/e/reporteria/ensayosbydesviacion"
                      selected={location.pathname.includes('/e/reporteria/ensayosbydesviacion')}
                      className={classes.nested}
                    >
                      <ListItemIcon><AssessmentOutlinedIcon color="primary" /></ListItemIcon>
                      <ListItemText primary={SubReportsTexts.subreports.ensayosbydesviacion.title} />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
            {permissions.informe && permissions.informe.read && (
            <ListItem button component={Link} style={ulStyle} to="/e/validacion" selected={location.pathname.includes('/e/validacion')}>
              <ListItemIcon><VerifiedUserIcon color="primary" /></ListItemIcon>
              <ListItemText primary={texts.validacion} />
            </ListItem>
            )}
          </List>
        </>
      )}
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          container={undefined}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaperMobile,
          }}
          ModalProps={{ keepMounted: true }}
        >
          <ListMenu />
          <div>
            <ListItem button component={Link} style={ulStyle} to="/e/tutoriales" selected={location.pathname.includes('/e/tutoriales')}>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary={texts.help} />
            </ListItem>
            <ListItem button component="label" onClick={logout} className={classes.logout}>
              <ListItemIcon><LogoutIcon color="primary" /></ListItemIcon>
              <ListItemText primary={texts.logout} />
            </ListItem>
          </div>
        </Drawer>
      </Hidden>
      <Hidden only={['xs', 'sm']} implementation="css">
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          <ListMenu />
        </Drawer>
      </Hidden>
    </nav>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    top: 64,
    paddingTop: 8,
  },
  logout: {
    paddingBottom: 15,
    cursor: 'pointer',
  },
  drawerPaperMobile: {
    width: drawerWidth,
    display: 'flex',
    justifyContent: 'space-between',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    height: '100%',
  },
  ul: {
    padding: '4px 0px 0px 16px',
  },
}));

EmployeeNavBar.propTypes = {
  mobileOpen: PropTypes.bool,
  setMobileOpen: PropTypes.func,
  auth: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
  }),
  permissions: PropTypes.shape({
    isLoading: PropTypes.bool,
    presupuesto: PropTypes.shape({
      create: PropTypes.bool,
      read: PropTypes.bool,
      update: PropTypes.bool,
      delete: PropTypes.bool,
    }),
    solicitudes: PropTypes.shape({
      create: PropTypes.bool,
      read: PropTypes.bool,
      update: PropTypes.bool,
      delete: PropTypes.bool,
    }),
    analisis: PropTypes.shape({
      create: PropTypes.bool,
      read: PropTypes.bool,
      update: PropTypes.bool,
      delete: PropTypes.bool,
    }),
    facturacion: PropTypes.shape({
      create: PropTypes.bool,
      read: PropTypes.bool,
      update: PropTypes.bool,
      delete: PropTypes.bool,
    }),
    informe: PropTypes.shape({
      create: PropTypes.bool,
      read: PropTypes.bool,
      update: PropTypes.bool,
      delete: PropTypes.bool,
    }),
    admin: PropTypes.shape({
      create: PropTypes.bool,
      read: PropTypes.bool,
      update: PropTypes.bool,
      delete: PropTypes.bool,
    }),
  }),
};

EmployeeNavBar.defaultProps = {
  mobileOpen: false,
  setMobileOpen: () => {},
  auth: {
    displayName: '',
    email: '',
  },
  permissions: {
    isLoading: true,
  },
};

export default EmployeeNavBar;
