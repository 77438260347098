const analisis = {
  title: 'Ingreso',
  add: 'Agregar Ingreso',
  list: {
    title: 'Ingreso',
    columns: {
      customer: 'Cliente',
      number: 'Número',
      description: 'Descripción',
      step: 'Estado',
      createdAt: 'Fecha Creación',
      createdBy: 'Creado por',
      sample: 'Identificación de Muestra',
    },
  },
  fichaingreso: {
    customer: 'Cliente *',
    presupuesto: 'Presupuesto *',
    docsFichaIngreso: 'Ficha de ingreso *',
    number: 'Nro de ingreso *',
    save: 'Guardar',
    saved: 'Ingreso guardado exitosamente',
    list: 'Volver a la lista',
    success: 'Ingreso confirmado exitosamente',
    noPresupuestos: 'Cliente no tiene presupuestos',
    agreement: 'Activar convenio',
    archive: 'Anular',
    archived: 'Ingreso anulado exitosamente',
    continue: 'Confirmar y continuar',
    typeArea: 'Área *',
  },
  create: {
    customer: 'Cliente *',
    presupuesto: 'Presupuesto *',
    docsFichaIngreso: 'Ficha de ingreso *',
    number: 'Nro de ingreso *',
    save: 'Crear',
    list: 'Volver a la lista',
    success: 'Ingreso creado exitosamente',
    noPresupuestos: 'Cliente no tiene presupuestos',
    agreement: 'Activar convenio',
    archive: 'Anular',
    archived: 'Ingreso anulado exitosamente',
    sample: 'Identificación de Muestra *',
    typeArea: 'Área *',
    limitDate: 'Fecha entrega cliente *',
    qtyMuestras: 'Nº de muestras destructiva *',
  },
  asignacion: {
    presupuesto: 'Presupuesto',
    docsFichaIngreso: 'Ficha de ingreso',
    number: 'Nro de ingreso',
    docsPlantilla: 'Plantilla de trabajo',
    save: 'Continuar',
    list: 'Volver a la lista',
    success: 'Ingreso editado exitosamente',
    again: 'Volver atrás',
    archive: 'Anular',
    archived: 'Ingreso anulado exitosamente',
    limitDate: 'Fecha entrega cliente',
    executeDate: 'Fecha termino de análisis *',
    selectEnsayos: 'Seleccione ensayos a utilizar',
    addEnsayos: 'Agregar ensayos',
  },
  ejecucion: {
    docsResultado: 'Resultado análisis *',
    anexos: 'Anexos',
    save: 'Continuar',
    list: 'Volver a la lista',
    again: 'Volver atrás',
    success: 'Ingreso editado exitosamente',
    archive: 'Anular',
    archived: 'Ingreso anulado exitosamente',
    limitDate: 'Fecha entrega cliente',
    executeDate: 'Fecha termino de análisis',
  },
  revision: {
    docsResultado: 'Resultado análisis',
    reDocsResultado: 'Volver a subir',
    anexos: 'Anexos',
    save: 'Continuar',
    list: 'Volver a la lista',
    success: 'Ingreso editado exitosamente',
    again: 'Volver atrás',
    archive: 'Anular',
    archived: 'Ingreso anulado exitosamente',
    limitDate: 'Fecha entrega cliente',
    executeDate: 'Fecha termino de análisis',
  },
  redaccion: {
    docsResultado: 'Resultado análisis',
    docsRedaccion: 'Redacción informe *',
    anexos: 'Anexos',
    save: 'Continuar',
    list: 'Volver a la lista',
    success: 'Ingreso editado exitosamente',
    archive: 'Anular',
    archived: 'Ingreso anulado exitosamente',
    again: 'Volver atrás',
    limitDate: 'Fecha entrega cliente',
    executeDate: 'Fecha termino de análisis',
  },
  completado: {
    docsResultado: 'Resultado análisis',
    docsRedaccion: 'Redacción informe',
    anexos: 'Anexos',
    list: 'Volver a la lista',
    success: 'Ingreso editado exitosamente',
    again: 'Volver atrás',
    archive: 'Anular',
    archived: 'Ingreso anulado exitosamente',
    limitDate: 'Fecha entrega cliente',
    executeDate: 'Fecha termino de análisis',
  },
  metodoEnsayo: {
    create: {
      material: 'Material *',
      ensayo: 'Ensayo *',
      metodo: 'Método *',
      save: 'Guardar',
      close: 'Cerrar',
      success: 'Método de Ensayo creado exitosamente',
      updated: 'Método de Ensayo actualizado exitosamente',
    },
  },
};

export default analisis;
