import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardHeader, Grid, List, ListItem, ListItemText, ListItemIcon, Checkbox, Button, Divider, FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  checkBoxs: {
    color: theme.palette.primary,
  },
  list: {
    width: 280,
    height: 250,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const union = (a, b) => [...a, ...not(b, a)];

const TransferList = ({
  itemList, onChange, error, helperText, rightSide,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState(rightSide || []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    // setLeft(itemList.map((item) => `${item.name ? item.name : item.number} ${item.lastname ? item.lastname : ''}`));
    setLeft(itemList);
  }, [itemList]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  useEffect(() => {
    onChange(right);
  }, [right]);

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={(
          <Checkbox
            className={classes.checkBoxs}
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'Todos seleccionados' }}
          />
        )}
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} seleccionados`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem key={value.name} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name} ${value.lastname}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      {error && <FormHelperText variant="outlined" error>{helperText}</FormHelperText>}
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>{customList('Disponibles', left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="mover a la derecha"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="mover a la izquierda"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList('Seleccionados', right)}</Grid>
      </Grid>
    </>
  );
};

TransferList.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  rightSide: PropTypes.arrayOf(),
};

TransferList.defaultProps = {
  itemList: [],
  onChange: () => {},
  error: false,
  helperText: undefined,
  rightSide: [],
};

export default TransferList;
