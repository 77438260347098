import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServiceFacturaciones(data = '') {
  try {
    const response = await API.get(`/facturacion${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/facturacion/', message: `Hubo un problema al intentar buscar el informe. ${message}` });
  }
}

export async function getServiceFacturacion(data) {
  try {
    const response = await API.get(`/facturacion/${data.id}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/facturacion/', message: `Hubo un problema al intentar buscar el informe. ${message}` });
  }
}

export async function getServiceFacturacionNoAuth(data) {
  try {
    const response = await API.get(`/validation?id=${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/facturacion/', message: `Hubo un problema al intentar buscar el informe. ${message}` });
  }
}

export async function postServiceFacturacion(data) {
  try {
    const response = await API.post('/facturacion', data);
    if (response && response.headers && response.headers.location) {
      return response.headers.location;
    }
    return '';
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/facturacion/', message: `Hubo un problema al intentar crear el informe. ${message}` });
  }
}

export async function putServiceFacturacion(data) {
  try {
    const response = await API.put('/facturacion', data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/facturacion/', message: `Hubo un problema al intentar editar el informe. ${message}` });
  }
}

export async function getServiceFacturacionCount() {
  try {
    const response = await API.get('/facturacion/d/count');
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/facturacion/', message: `Hubo un problema al intentar buscar facturacion. ${message}` });
  }
}
