import React, { useEffect, useState } from 'react';
import {
  Grid, FormControl, TextField, InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import {
  useCustomers, useFacturacion, useAnalisis, useStatesFacturacion, useInformes,
} from '@hooks';
import {
  Alert, ButtonAdorned, MultiFileUpload,
  Autocomplete, DatePicker, Select, Subtitle,
} from '@components/atoms';
import { createFacturacionSchema } from '@helpers/schemas';
import constants from '@helpers/constants';
import { getDateCustom } from '@helpers/utils';
import { CheckCircleRounded as CheckIcon, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';

const Create = () => {
  const { pages: { employees: { facturacion: { create: texts } } } } = i18n;
  const history = useHistory();
  const { getCustomers, isCustomerLoading, customerError } = useCustomers();
  const { getStatesFacturacion } = useStatesFacturacion();
  const { getFiles, isAnalisisLoading } = useAnalisis();
  const { getFiles: getFileInforme, getInformes, isInformeLoading } = useInformes();

  const {
    editFacturacion, isFacturacionLoading, facturacionError,
  } = useFacturacion();
  const [customers, setCustomers] = useState([]);
  const [facturaciones, setFacturaciones] = useState([]);
  const [statesFacturacion, setStatesFacturacion] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: {
      customer: {},
      facturacion: {},
      amount: 0,
      docInforme: [],
      docInformeCliente: [],
      docFactura: [],
      folio: '',
      expiredDate: getDateCustom(new Date(), 'yyyy-MM-DD'),
      stateFacturacion: '',
    },
    enableReinitialize: true,
    validationSchema: createFacturacionSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const edited = await editFacturacion(values);
      if (edited) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/facturacion');
      }
    },
  });

  useEffect(async () => {
    setCustomers(await getCustomers());
    setStatesFacturacion(await getStatesFacturacion());
  }, []);

  const onChangeCustomer = async (params, value) => {
    form.setFieldValue('customer', value);
    form.setFieldValue('docInforme', []);
    form.setFieldValue('docInformeCliente', []);
    form.setFieldValue('facturacion', {});
    form.setFieldValue('stateFacturacion', statesFacturacion.find((state) => state.id === constants.STATE_FACTURACION.PENDIENTE_PAGO.id));
    if (value && value.id) {
      setFacturaciones(await getInformes(`?contact.customer.id=${value.id}`));
    }
  };

  const onChangeFacturacion = async (_, value) => {
    form.setFieldValue('facturacion', value);
    form.setFieldValue('docInforme', []);
    form.setFieldValue('docInformeCliente', []);
    if (value) {
      const docInforme = await getFiles({
        customerId: value.analisis.customer.id || undefined,
        analisisId: value.analisis.id,
        filesType: 'redaccion',
      });
      const docInformeCliente = await getFileInforme({
        customerId: value.analisis.customer.id || undefined,
        facturacionId: value.id,
      });
      form.setFieldValue('docInforme', docInforme);
      form.setFieldValue('docInformeCliente', docInformeCliente);
    }
  };

  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {facturacionError && <Alert>{facturacionError.message}</Alert>}
      <Grid container spacing={2}>
        <Grid item xs={12} align="left">
          <Subtitle>{texts.subtitleInforme}</Subtitle>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id={texts.customer}
              label={texts.customer}
              onChange={onChangeCustomer}
              error={form.touched.customer && Boolean(form.errors.customer)}
              disabled={isCustomerLoading}
              helperText={form.touched.customer && form.errors.customer && form.errors.customer.id}
              items={customers}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id={texts.facturacion}
              label={texts.facturacion}
              onChange={onChangeFacturacion}
              error={form.touched.facturacion && Boolean(form.errors.facturacion?.id)}
              disabled={isCustomerLoading}
              helperText={form.touched.facturacion && form.errors.facturacion && form.errors.facturacion.id}
              items={facturaciones}
              getOptionLabel={(value) => `${value?.analisis?.number}`}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          { form.values.facturacion?.analisis && (
            <FormControl fullWidth>
              <TextField
                label={texts.agreement}
                variant="outlined"
                disabled
                value={form.values.facturacion.analisis.agreement ? 'Con convenio' : 'Sin convenio'}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      {(form.values.facturacion.analisis.agreement ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />)}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <MultiFileUpload
              title={texts.docInforme}
              urls={form.values.docInforme}
              disabled
              loading={form.values.facturacion?.id && isAnalisisLoading}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <MultiFileUpload
              title={texts.docInformeCliente}
              urls={form.values.docInformeCliente}
              disabled
              loading={form.values.facturacion?.id && isInformeLoading}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} align="left">
          <Subtitle>{texts.subtitleFacturacion}</Subtitle>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <MultiFileUpload
              title={texts.docFactura}
              name="docFactura"
              accepted={['pdf']}
              onChange={form.handleChange}
              value={form.values.docFactura}
              error={form.touched.docFactura && Boolean(form.errors.docFactura)}
              helperText={form.touched.docFactura && form.errors.docFactura && form.errors.docFactura}
              multiple
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              name="folio"
              label={texts.folio}
              placeholder={texts.folioPlaceholder}
              variant="outlined"
              value={form.values.folio}
              onChange={form.handleChange}
              error={form.touched.folio && Boolean(form.errors.folio)}
              helperText={form.touched.folio && form.errors.folio}
              // disabled={isFacturacionLoading}
              autoComplete="new-folio"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              name="amount"
              label={texts.amount}
              placeholder={texts.amountPlaceholder}
              type="number"
              variant="outlined"
              value={form.values.amount}
              onChange={form.handleChange}
              error={form.touched.amount && Boolean(form.errors.amount)}
              helperText={form.touched.amount && form.errors.amount}
              // disabled={isFacturacionLoading}
              autoComplete="new-amount"
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <DatePicker
              label={texts.fechaExp}
              value={form.values.expiredDate}
              onChange={(value) => form.setFieldValue('expiredDate', value.target.value)}
              disable={isFacturacionLoading}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined" fullWidth>
            <Select
              name="stateFacturacion"
              label={texts.state}
              onChange={form.handleChange}
              value={form.values.stateFacturacion}
              error={form.touched.stateFacturacion && Boolean(form.errors.stateFacturacion)}
              // disabled={!form.values.analisis || isFacturacionLoading}
              helperText={form.touched.stateFacturacion && form.errors.stateFacturacion && form.errors.stateFacturacion.id}
              items={statesFacturacion}
            />
          </FormControl>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <ButtonAdorned color="default" onClick={() => history.push('/e/facturacion')}>
              {texts.list}
            </ButtonAdorned>
          </Grid>
          <Grid item>
            <form onSubmit={form.handleSubmit}>
              <ButtonAdorned loading={form.values.facturacion?.id && isFacturacionLoading} disabled={form.values.facturacion?.id && isFacturacionLoading} type="submit">
                {texts.save}
              </ButtonAdorned>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Create.propTypes = {
};

Create.defaultProps = {
};

export default Create;
