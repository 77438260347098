import React, { useEffect, useState } from 'react';
import {
  Grid, FormControl, TextField, InputLabel, Select, MenuItem, ListItemText, Checkbox, InputAdornment, IconButton, OutlinedInput,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Alert, ButtonAdorned, Subtitle } from '@components/atoms';
import { createEmployeeSchema } from '@helpers/schemas';
import { getRutNotFormatted } from '@helpers/utils';
import { useEmployees, useRoles } from '@hooks';
import i18n from '@i18n';

const Create = () => {
  const classes = useStyles();
  const { pages: { employees: { employees: { create: texts } } } } = i18n;
  const history = useHistory();
  const { getRoles, rolesError } = useRoles();
  const { createEmployee, isEmployeeLoading, employeeError } = useEmployees();
  const [roles, setRoles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(async () => {
    setRoles(await getRoles());
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const form = useFormik({
    initialValues: {
      title: '',
      name: '',
      lastname: '',
      rut: '',
      phoneNumber: '',
      roles: [],
      email: '',
      password: '',
    },
    validationSchema: createEmployeeSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const created = await createEmployee({
        ...values,
        rut: getRutNotFormatted(values.rut),
      });
      if (created) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/colaboradores');
      }
    },
  });

  return (
    <>
      {rolesError && <Alert>{rolesError.message}</Alert>}
      {employeeError && <Alert>{employeeError.message}</Alert>}
      <Grid container spacing={2}>
        <Grid item xs={12} align="left">
          <Subtitle>{texts.subtitleData}</Subtitle>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              name="rut"
              label={texts.rut}
              placeholder={texts.rutPlaceholder}
              variant="outlined"
              value={form.values.rut}
              onChange={form.handleChange}
              error={form.touched.rut && Boolean(form.errors.rut)}
              helperText={form.touched.rut && form.errors.rut}
              disabled={isEmployeeLoading}
              autoComplete="new-rut"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              name="title"
              label={texts.title}
              placeholder={texts.titlePlaceholder}
              variant="outlined"
              value={form.values.title}
              onChange={form.handleChange}
              error={form.touched.title && Boolean(form.errors.title)}
              helperText={form.touched.title && form.errors.title}
              disabled={isEmployeeLoading}
              autoComplete="new-title"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              name="name"
              label={texts.name}
              placeholder={texts.namePlaceholder}
              variant="outlined"
              value={form.values.name}
              onChange={form.handleChange}
              error={form.touched.name && Boolean(form.errors.name)}
              helperText={form.touched.name && form.errors.name}
              disabled={isEmployeeLoading}
              autoComplete="new-name"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              name="lastname"
              label={texts.lastname}
              placeholder={texts.lastnamePlaceholder}
              variant="outlined"
              value={form.values.lastname}
              onChange={form.handleChange}
              error={form.touched.lastname && Boolean(form.errors.lastname)}
              helperText={form.touched.lastname && form.errors.lastname}
              disabled={isEmployeeLoading}
              autoComplete="new-lastname"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              name="phoneNumber"
              label={texts.phoneNumber}
              placeholder={texts.phoneNumberPlaceholder}
              variant="outlined"
              value={form.values.phoneNumber}
              onChange={form.handleChange}
              error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
              helperText={form.touched.phoneNumber && form.errors.phoneNumber}
              disabled={isEmployeeLoading}
              autoComplete="new-phoneNumber"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id={texts.rol}>{texts.rol}</InputLabel>
            <Select
              name="roles"
              label={texts.rol}
              value={form.values.roles}
              multiple
              onChange={form.handleChange}
              error={form.touched.roles && Boolean(form.errors.roles)}
              disabled={isEmployeeLoading}
              renderValue={(rolesForm) => rolesForm.map((rol) => rol.name).join(', ')}
            >
              {roles.map((rol) => (
                <MenuItem key={rol.id} value={rol}>
                  <Checkbox checked={!!form.values.roles.find((rolForm) => rolForm.id === rol.id)} />
                  <ListItemText primary={rol.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} align="left">
          <Subtitle>{texts.subtitleAccount}</Subtitle>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              type="email"
              name="email"
              label={texts.email}
              placeholder={texts.emailPlaceholder}
              variant="outlined"
              value={form.values.email}
              onChange={form.handleChange}
              error={form.touched.email && Boolean(form.errors.email)}
              helperText={form.touched.email && form.errors.email}
              disabled={isEmployeeLoading}
              autoComplete="new-email"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="contact-password">{texts.password}</InputLabel>
            <OutlinedInput
              id="contact-password"
              name="password"
              type={!showPassword ? 'text' : 'password'}
              inputProps={{
                autoComplete: 'new-password',
              }}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                  )}
              placeholder={texts.passwordPlaceholder}
              value={form.values.password}
              onChange={form.handleChange}
              error={form.touched.password && Boolean(form.errors.password)}
              helperText={form.touched.password && form.errors.password}
              disabled={isEmployeeLoading}
              autoComplete="new-password"
              labelWidth={200}
            />
          </FormControl>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <ButtonAdorned color="default" onClick={() => history.push('/e/colaboradores')}>
              {texts.list}
            </ButtonAdorned>
          </Grid>
          <Grid item>
            <form onSubmit={form.handleSubmit}>
              <ButtonAdorned loading={isEmployeeLoading} disabled={isEmployeeLoading} type="submit">
                {texts.save}
              </ButtonAdorned>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Create.propTypes = {
};

Create.defaultProps = {
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
}));

export default Create;
