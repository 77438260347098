import { useState, useCallback } from 'react';
import {
  getServiceEmployees, getServiceEmployee, postServiceEmployee, putServiceEmployee, changeDisabledServiceEmployee,
} from '../services/employees';

const useEmployees = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const getEmployees = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const employees = await getServiceEmployees(data);
      setState({ loading: false, error: null });
      return employees;
    } catch (e) {
      setState({ loading: false, error: e });
      return [];
    }
  });

  const getEmployee = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const employee = await getServiceEmployee(data);
      setState({ loading: false, error: null });
      return employee;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const createEmployee = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await postServiceEmployee(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const editEmployee = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await putServiceEmployee(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const changeDisabledEmployee = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await changeDisabledServiceEmployee(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  return {
    isEmployeeLoading: state.loading,
    employeeError: state.error,
    getEmployees,
    getEmployee,
    createEmployee,
    editEmployee,
    changeDisabledEmployee,
  };
};

export default useEmployees;
