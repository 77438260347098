import presupuestos from './presupuestos';
import profile from './profile';
import facturacion from './facturacion';
import solicitudes from './solicitudes';
import validacion from './validacion';

export default {
  dashboard: {
    title: 'Dashboard',
  },
  facturacion,
  presupuestos,
  profile,
  validacion,
  solicitudes,
};
