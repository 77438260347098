import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  makeStyles, AppBar, Toolbar,
} from '@material-ui/core';
import images from '../../../assets/images';

const MainHeaderNoAuth = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <RouterLink to="/">
          <img
            src={images.logo2}
            alt="Logo Caltex"
            className={classes.logo}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      marginLeft: drawerWidth,
    },
  },
  logo: {
    width: 52,
    height: 52,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default MainHeaderNoAuth;
