import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const Title = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root}>{children}</Typography>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    opacity: '0.8',
  },
}));

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
