import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField, InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import { useAnalisis, useInformes } from '@hooks';
import { CheckCircleRounded as CheckIcon, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';
import {
  Alert, ButtonAdorned, MultiFileUpload,
} from '@components/atoms';
import { editInformeSchema } from '@helpers/schemas';
import { getCompleteName, getDate } from '@helpers/utils';

const Edit = ({
  id, setExtraInfo, setAnalisisId,
}) => {
  const { pages: { employees: { informe: { edit: texts } } } } = i18n;
  const history = useHistory();
  const { getFiles: getFilesAnalisis, isAnalisisLoading, getAnalisis } = useAnalisis();
  const {
    getFiles: getFileInforme, isInformeLoading, editInforme, getInforme, informeError,
  } = useInformes();

  const [facturacion, setFacturacion] = useState({
    customer: '',
    contact: '',
    analisis: '',
    docInforme: [],
    docInformeCliente: [],
    number: '',
    description: '',
  });
  const [analisis, setAnalisis] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: facturacion,
    enableReinitialize: true,
    validationSchema: editInformeSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const updated = await editInforme(values);
      if (updated) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/informes');
      }
    },
  });

  useEffect(async () => {
    setExtraInfo([]);
    const serviceFacturacion = await getInforme({ id });
    const serviceAnalisis = await getAnalisis({ id: serviceFacturacion.analisis.id });
    setAnalisis(serviceAnalisis);
    setAnalisisId(serviceAnalisis.id);
    const docInforme = await getFilesAnalisis({
      customerId: (serviceFacturacion && serviceFacturacion.contact && serviceFacturacion.contact.customer.id) || undefined,
      analisisId: serviceFacturacion.analisis.id,
      filesType: 'redaccion',
    });

    const docInformeCliente = await getFileInforme({
      customerId: (serviceFacturacion && serviceFacturacion.contact && serviceFacturacion.contact.customer.id) || undefined,
      facturacionId: serviceFacturacion.id,
    });

    const updated = `Última actualización por: ${getCompleteName(serviceFacturacion.updatedBy)} | ${getDate(serviceFacturacion.updatedAt)}`;
    const created = `Creado por: ${getCompleteName(serviceFacturacion.createdBy)} | ${getDate(serviceFacturacion.createdAt)}`;
    setExtraInfo([created || '', updated || '']);
    setFacturacion({
      ...facturacion,
      id: serviceFacturacion.id,
      customer: serviceFacturacion.contact ? serviceFacturacion.contact.customer : '',
      contact: serviceFacturacion.contact || '',
      docInforme: docInforme || '',
      docInformeCliente: docInformeCliente || '',
      number: serviceFacturacion.number || '',
      analisis: serviceFacturacion.analisis || '',
    });
  }, []);

  return (
    <>
      {informeError && <Alert>{informeError.message}</Alert>}
      { (isAnalisisLoading || isInformeLoading) ? <LinearProgress /> : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  variant="outlined"
                  name="customer"
                  label={texts.customer}
                  value={form.values.customer.name}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  name="contact"
                  variant="outlined"
                  value={`${form.values.contact.name} ${form.values.contact.lastname}`}
                  label={texts.contact}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {analisis.presupuesto ? (
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    name="presupuesto"
                    variant="outlined"
                    label={texts.presupuesto}
                    value={analisis.presupuesto.number}
                    disabled
                  />
                </FormControl>
              ) : (
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    name="analisis"
                    variant="outlined"
                    label={texts.analisis}
                    value={analisis && analisis.number}
                    disabled
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label={texts.agreement}
                  variant="outlined"
                  disabled
                  value={analisis.agreement ? 'Con convenio' : 'Sin convenio'}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {(analisis.agreement ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />)}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docInforme}
                  urls={form.values.docInforme}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docInformeCliente}
                  name="docInformeCliente"
                  onChange={form.handleChange}
                  value={form.values.docInformeCliente}
                  error={form.touched.docInformeCliente && Boolean(form.errors.docInformeCliente)}
                  helperText={form.touched.docInformeCliente && form.errors.docInformeCliente && form.errors.docInformeCliente}
                  multiple
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <ButtonAdorned color="default" onClick={() => history.push('/e/informes')}>
                {texts.list}
              </ButtonAdorned>
            </Grid>
            <Grid item>
              <form onSubmit={form.handleSubmit}>
                <ButtonAdorned loading={isInformeLoading} disabled={isInformeLoading} type="submit">
                  {texts.save}
                </ButtonAdorned>
              </form>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string,
  setExtraInfo: PropTypes.func,
  setAnalisisId: PropTypes.func,
};

Edit.defaultProps = {
  id: '',
  setExtraInfo: () => {},
  setAnalisisId: () => {},
};

export default Edit;
