import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServiceMessagings(data = '') {
  try {
    const response = await API.get(`/messaging/${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/messaging/', message: `Hubo un problema al intentar buscar mensajes. ${message}` });
  }
}

export async function postServiceMessaging(id, data) {
  try {
    const response = await API.post(`/messaging/${id}`, data);
    if (response && response.headers && response.headers.location) {
      return response.headers.location;
    }
    return '';
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/messaging/', message: `Hubo un problema al intentar agregar el mensaje. ${message}` });
  }
}
