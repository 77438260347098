import React from 'react';
import {
  TextField, FormHelperText,
} from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

const Autocomplete = ({
  id, label, onChange, error, disabled, items, helperText, defaultValue, key, getOptionLabel,
}) => {
  const getOptionLabelDefault = (value) => (value && Object.keys(value).length !== 0 && `${value.name ? value.name : value.number} ${value.lastname ? value.lastname : ''}`);
  return (
    <>
      <MuiAutocomplete
        key={key}
        id={id}
        label={label}
        options={items}
        value={defaultValue}
        getOptionLabel={getOptionLabel || getOptionLabelDefault}
        onChange={onChange}
        disabled={disabled || items.length === 0}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            label={label}
            variant="outlined"
          />
        )}
      />
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </>
  );
};

Autocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  key: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  helperText: PropTypes.string,
  defaultValue: PropTypes.shape(),
  getOptionLabel: PropTypes.func,
};

Autocomplete.defaultProps = {
  onChange: () => {},
  key: '0',
  error: false,
  disabled: false,
  items: [],
  helperText: undefined,
  defaultValue: undefined,
  getOptionLabel: undefined,
};

export default Autocomplete;
