import { useState, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { getNewFilename } from '@helpers/utils';
import {
  getServicePresupuestos, getServicePresupuesto, postServicePresupuesto, putServicePresupuesto, getServicePresupuestoCount,
} from '../services/presupuestos';

const usePresupuestos = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const uploadFile = useCallback(async ({ file, customerId, presupuestoId }) => {
    const filename = getNewFilename(file.name);
    const refStorage = firebase.storage().ref(`${customerId}/presupuestos/${presupuestoId}/${filename}`);
    await refStorage.put(file);
    const fileURL = await refStorage.getDownloadURL();
    return fileURL;
  }, []);

  const uploadBkpFile = useCallback(async ({ file, customerId, presupuestoId }) => {
    const filename = getNewFilename(file.name);
    const refStorage = firebase.storage().ref(`${customerId}/presupuestos/${presupuestoId}/ApproveBkp/${filename}`);
    await refStorage.put(file);
    const fileURL = await refStorage.getDownloadURL();
    return fileURL;
  }, []);

  const getHistory = useCallback(async ({ customerId, presupuestoId }) => {
    setState({ loading: true, error: null });
    try {
      const refStorage = firebase.storage().ref();
      const folder = refStorage.child(`${customerId}/presupuestos/${presupuestoId}`);
      const getData = (await folder.listAll()).items.map(async (detail) => {
        const metaData = await detail.getMetadata();
        const url = await detail.getDownloadURL();
        return { timeCreated: metaData.timeCreated, url, id: url };
      });
      const result = await Promise.all(getData);
      setState({ loading: false, error: null });
      return result;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  }, []);

  const getPresupuestos = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const presupuestos = await getServicePresupuestos(data);
      setState({ loading: false, error: null });
      return presupuestos;
    } catch (e) {
      setState({ loading: false, error: e });
      return [];
    }
  });

  const getPresupuesto = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const presupuesto = await getServicePresupuesto(data);
      setState({ loading: false, error: null });
      return presupuesto;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const createPresupuesto = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const { file, ...presupuesto } = data;
      const presupuestoId = await postServicePresupuesto(presupuesto);
      const fileURL = file && file.length > 0 && file[0].size ? await uploadFile({
        file: file[0],
        presupuestoId,
        customerId: presupuesto.contact.customer.id,
      }) : undefined;
      await putServicePresupuesto({ id: presupuestoId, fileURL });
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const editPresupuesto = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const { file, fileBkp, ...presupuesto } = data;
      const fileURL = file && file.length > 0 && file[0].size ? await uploadFile({
        file: file[0],
        presupuestoId: presupuesto.id,
        customerId: presupuesto.contact.customer.id,
      }) : undefined;

      const fileBkpURL = fileBkp && fileBkp.length > 0 && fileBkp[0].size ? await uploadBkpFile({
        file: fileBkp[0],
        presupuestoId: presupuesto.id,
        customerId: presupuesto.contact.customer.id,
      }) : undefined;
      await putServicePresupuesto({ ...presupuesto, fileURL, fileBkpURL });
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const getPresupuestoCount = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const presupuestoCount = await getServicePresupuestoCount(data);
      setState({ loading: false, error: null });
      return presupuestoCount;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  return {
    isPresupuestoLoading: state.loading,
    presupuestoError: state.error,
    getPresupuestos,
    getPresupuesto,
    getHistory,
    createPresupuesto,
    editPresupuesto,
    getPresupuestoCount,
  };
};

export default usePresupuestos;
