import React, { useEffect, useContext, useState } from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import AuthContext from '@contexts/AuthContext';
import { CustomerNavBar, MainHeader } from '@components/organisms';
import { NotEmailVerified } from '@components/molecules';

const CustomerLayout = ({ children }) => {
  const history = useHistory();
  const classes = useStyles();
  const { isLogged, isInitializing, auth } = useContext(AuthContext);
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (!isLogged && !isInitializing) {
      history.push('/n/login');
    }
    if (auth && !isInitializing && auth.super_rol !== 'customer') {
      history.push('/forbidden');
    }
  }, [isLogged, history]);

  return (
    isInitializing ? <LinearProgress /> : (
      <div className={classes.root}>
        <MainHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <CustomerNavBar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <main className={classes.main}>
          <div className={classes.toolbar} />
          {auth && (auth.email_verified || location.pathname.indexOf('/perfil') !== -1 ? children : <NotEmailVerified />) }
        </main>
      </div>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#fcfcfc',
    height: '100%',
  },
  toolbar: theme.mixins.toolbar,
  main: {
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '15px',
  },
}));

CustomerLayout.propTypes = {
  children: PropTypes.node,
};

CustomerLayout.defaultProps = {
  children: '',
};

export default CustomerLayout;
