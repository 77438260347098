import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import { getAuth } from '../services/auth';

const AuthContext = React.createContext({});

export function AuthContextProvider({ children }) {
  const [auth, setAuth] = useState(() => ({ initializing: true }));
  const [user, setUser] = useState(() => (null));

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged(
      (_auth) => {
        if (_auth) {
          firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            setAuth(idTokenResult.claims);
          }).catch(() => {
          });
        } else {
          setAuth(null);
        }
      },
    );
    return () => { unlisten(); };
  }, []);

  useEffect(() => {
    const fill = async () => {
      if (auth && !auth.initializing) {
        try {
          setUser(await getAuth());
        } catch (error) {
          setUser({ error });
        }
      } else {
        setUser(null);
      }
    };
    fill();
  }, [auth]);

  return (
    <AuthContext.Provider value={{
      auth,
      user,
      isInitializing: Boolean(auth && auth.initializing),
      isLogged: (auth && !auth.initializing) && Boolean(auth),
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
