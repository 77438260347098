export default {
  administracion: {
    crearContacto: {
      title: 'Crear contacto',
      description: 'Se podra crear un contacto siempre y cuando exista un cliente. Debe considerar que el mail no debe estar previamente utilizado.',
    },
    verContacto: {
      title: 'Ver contacto',
      description: 'Para poder encontrar un contacto deberá ir a la sección de contactos o puede presionar en la acción de contactos en el cliente correspondiente.',
    },
    inhabilitarContacto: {
      title: 'Inhabilitar/Habilitar contacto',
      description: 'Esta función tiene como finalidad bloquear el acceso de un contacto en específico. También, de la misma manera, es posible volver a permitirle el acceso.',
    },
    crearColaborador: {
      title: 'Creación de un colaborador',
      description: 'Para crear un colaborador debe procurar que el correo a utilizar y el RUT de la persona no esten utilizados previamente por otro colaborador.',
    },
    crearCliente: {
      title: 'Creación de un cliente',
      description: 'Para crear un cliente debe procurar que el correo a utilizar y el RUT de la persona no esten utilizados previamente por otro cliente.',
    },
    editarCliente: {
      title: 'Edición de un cliente',
      description: 'Existen datos que no pueden ser editados, como el RUT del cliente. Si este desea cambiarlo, debera informar al administrador.',
    },
    editarInfoCambioPassword: {
      title: 'Editar información y Cambio de contraseña',
      description: `Existen dos maneras de realizar el cambio de contraseña. Primero puede realizarse a través del perfil del usuario, en el botón de "Solicitar Cambio de contraseña".
      Segundo, puede realizar al editar la información de un colaborador, donde también aparecerá su usuario. Si cambia su propia contraseña, será cerrada su sesión del portal.`,
    },
  },
  secretaria: {
    crearPresupuesto: {
      title: 'Agregar Presupuesto',
      description: `Al agregar el presupuesto deberá especificar el cliente y contacto a quien estará enlazado. Este cliente será quien posteriormente recibirá y podrá comunicarse con 
      ustedes a través de la comunicación (Emails).`,
    },
    comunicaciones: {
      title: 'Comunicaciones',
      description: 'Las comunicaciones serán utilizadas para realizar preguntas y aclaraciones entre cliente y Caltex. Este a su vez enviara un correo electrónico al mail registrado.',
    },
    agregarIngreso: {
      title: 'Agregar ingreso',
      description: `Al crear un ingreso aparecerán los presupuestos aprobados del cliente seleccionado. 
      Si el cliente no tiene ningún presupuesto aprobado no podrá realizar el ingreso correspondiente.`,
    },
  },
  general: {
    cambioContrasena: {
      title: 'Cambiar contraseña',
      description: 'La contraseña deberá cumplir con la seguridad mínima requerida.',
    },
    validarMailUsuario: {
      title: 'Validar Email',
      description: 'Este paso es necesario para obtener el acceso completo al portal.',
    },
  },
  cliente: {
    aprobarPresupuesto: {
      title: 'Aprobar presupuesto',
      description: 'Puedes aprobar/rechazar el presupuesto, según sea el caso. Antes de rechazarlo o ante alguna duda puedes usar la sección de comunicaciones para resolver las dudas.',
    },
    crearEditarSolicitudes: {
      title: 'Creación y Edición Solicitudes',
      description: 'Las solicitudes son el primer contacto de comunicación con Caltex. Aqui verá como crear y editar una solicitud.',
    },
  },
  jefeLaboratorio: {
    asignarLaboratorista: {
      title: 'Asignar laboratorista',
      description: `Se debe asignar los laboratoristas para realizar los trabajos asociados al ingreso. Para finalizar la etapa, 
      debe agregar la plantilla de trabajo en la cual trabajaran los técnicos.`,
    },
    revisionAnalisis: {
      title: 'Revisión Análisis',
      description: `Se deberá realizar la revisión del análisis ejecutado por los laboratoristas. En caso de que sea desee volver a ejecutar el análisis 
      deberá seleccionar la opción de "Volver a ejecutar", de lo contrario podrá continuar para cerrar la etapa.`,
    },
  },
  laboratoristas: {
    ejecucionAnalisis: {
      title: 'Ejecución Análisis',
      description: `Los técnicos designados verán en su inicio los análisis en los que deberán trabajar, previamente informados por el Jefe de Laboratorio.
      Deben bajar la plantilla asignada e ir completándola acorde a sus avances.`,
    },
  },
  ingenieros: {
    redaccionInforme: {
      title: 'Ingresar resumen analisis y cierre de analisis',
      description: `Los ingenieros estarán encargados de realizar la redacción del informe, según los análisis realizados. En caso de que el Ingeniero determine
      que los resultados deben volver a ejecutarse podrá presionar en la acción de "Volver a ejecutar" para devolver el trabajo a los Laboratoristas. En caso de
      que todo esté en norma, deberá redactar el informe descargando la plantilla correspondiente y completando el ciclo.`,
    },
  },
};
