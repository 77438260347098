import { useState, useCallback } from 'react';
import {
  getServiceContacts, getServiceContact, postServiceContact, putServiceContact, changeDisabledServiceContact, deleteServiceContact,
} from '../services/contacts';

const useContacts = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const getContacts = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const contacts = await getServiceContacts(data);
      setState({ loading: false, error: null });
      return contacts;
    } catch (e) {
      setState({ loading: false, error: e });
      return [];
    }
  });

  const getContact = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const contact = await getServiceContact(data);
      setState({ loading: false, error: null });
      return contact;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const createContact = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await postServiceContact(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const editContact = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await putServiceContact(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const changeDisabledContact = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await changeDisabledServiceContact(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const deleteContact = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await deleteServiceContact(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  return {
    isContactLoading: state.loading,
    contactError: state.error,
    getContacts,
    getContact,
    createContact,
    editContact,
    changeDisabledContact,
    deleteContact,
  };
};

export default useContacts;
