import React from 'react';
import { getRutFormatted } from '@helpers/utils';
import {
  Grid, TextField, FormControl, InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { CheckCircleRounded as CheckIcon, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';

const CustomerForm = ({ customer, texts }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.rut}
          value={customer.rut ? getRutFormatted(customer.rut) : ' - '}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.name}
          value={customer.name || ' - '}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.giro}
          value={customer.giro || ' - '}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <FormControl fullWidth>
        <TextField
          label={texts.address}
          value={customer.address || ' - '}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <FormControl fullWidth>
        <TextField
          label={texts.agreement}
          value={customer.agreement ? 'Con convenio' : 'Sin convenio'}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                {customer.agreement ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Grid>
  </Grid>
);

CustomerForm.propTypes = {
  customer: PropTypes.shape({
    rut: PropTypes.string,
    name: PropTypes.string,
    giro: PropTypes.string,
    address: PropTypes.string,
    agreement: PropTypes.string,
  }),
  texts: PropTypes.shape({
    rut: PropTypes.string,
    name: PropTypes.string,
    giro: PropTypes.string,
    address: PropTypes.string,
    agreement: PropTypes.string,
  }),
};

CustomerForm.defaultProps = {
  customer: {
    rut: ' - ',
    name: ' - ',
    giro: ' - ',
    address: ' - ',
    agreement: false,
  },
  texts: {
    rut: '',
    name: '',
    giro: '',
    address: '',
  },
};

export default CustomerForm;
