import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServiceCustomers(data = '') {
  try {
    const response = await API.get(`/customer${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/customer/', message: `Hubo un problema al intentar buscar cliente. ${message}` });
  }
}

export async function getServiceCustomer(data) {
  try {
    const response = await API.get(`/customer/${data.id}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/customer/', message: `Hubo un problema al intentar buscar cliente. ${message}` });
  }
}

export async function postServiceCustomer(data) {
  try {
    const response = await API.post('/customer', data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/customer/', message: `Hubo un problema al intentar crear cliente. ${message}` });
  }
}

export async function putServiceCustomer(data) {
  try {
    const response = await API.put('/customer', data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/customer/', message: `Hubo un problema al intentar editar cliente. ${message}` });
  }
}

export async function deleteServiceCustomer(data) {
  try {
    const response = await API.delete(`/customer/delete/${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/customer/', message: `Hubo un problema al intentar eliminar cliente. ${message}` });
  }
}
