import React from 'react';
import PropTypes from 'prop-types';
import IngresosByArea from './IngresosByArea';
import EnsayosByQty from './EnsayosByQty';
import EnsayosByLaboratorista from './EnsayosByLaboratorista';
import EnsayosByDesviacion from './EnsayosByDesviacion';
import MuestrasByLaboratorista from './MuestrasByLaboratorista';

const Reporteria = ({ match: { params: { reporte } } }) => (
  <>
    {reporte === 'ingresosxarea' && <IngresosByArea />}
    {reporte === 'ensayosbyqty' && <EnsayosByQty />}
    {reporte === 'ensayosbylaboratorista' && <EnsayosByLaboratorista />}
    {reporte === 'ensayosbydesviacion' && <EnsayosByDesviacion />}
    {reporte === 'muestrasbylab' && <MuestrasByLaboratorista />}
  </>
);

Reporteria.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      reporte: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Reporteria;
