import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField, FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import { useCustomers } from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, Switch,
} from '@components/atoms';
import { editCustomerSchema } from '@helpers/schemas';
import { getRutNotFormatted, getRutFormatted } from '@helpers/utils';

const Edit = ({ id }) => {
  const classes = useStyles();
  const { pages: { employees: { customers: { edit: texts } } } } = i18n;
  const history = useHistory();
  const {
    getCustomer, editCustomer, isCustomerLoading, customerError, deleteCustomer,
  } = useCustomers();
  const { enqueueSnackbar } = useSnackbar();

  const [customer, setCustomer] = useState({
    id,
    rut: '',
    name: '',
    giro: '',
    address: '',
    agreement: '',
  });

  useEffect(async () => {
    const serviceCustomer = await getCustomer({ id });
    setCustomer({
      ...customer,
      rut: serviceCustomer.rut || '',
      name: serviceCustomer.name || '',
      giro: serviceCustomer.giro || '',
      address: serviceCustomer.address || '',
      agreement: serviceCustomer.agreement || false,
    });
  }, []);

  const form = useFormik({
    initialValues: customer,
    enableReinitialize: true,
    validationSchema: editCustomerSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const edited = await editCustomer({
        id: values.id,
        rut: getRutNotFormatted(values.rut),
        name: values.name,
        giro: values.giro,
        address: values.address,
        agreement: values.agreement,
      });
      if (edited) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/e/clientes');
      }
    },
  });

  const onChangeAgreement = async (_, value) => form.setFieldValue('agreement', value);

  const onClickDelete = async () => {
    const delCustomer = await deleteCustomer(id);
    if (delCustomer) {
      history.push('/e/clientes');
      enqueueSnackbar(texts.deleteSuccess, { variant: 'success' });
    }
  };

  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {isCustomerLoading ? <LinearProgress /> : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.rut}
                  variant="outlined"
                  value={getRutFormatted(form.values.rut)}
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  name="name"
                  label={texts.name}
                  placeholder={texts.namePlaceholder}
                  variant="outlined"
                  value={form.values.name}
                  onChange={form.handleChange}
                  error={form.touched.name && Boolean(form.errors.name)}
                  helperText={form.touched.name && form.errors.name}
                  disabled={isCustomerLoading}
                  autoComplete="new-name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  name="giro"
                  label={texts.giro}
                  placeholder={texts.giroPlaceholder}
                  variant="outlined"
                  value={form.values.giro}
                  onChange={form.handleChange}
                  error={form.touched.giro && Boolean(form.errors.giro)}
                  helperText={form.touched.giro && form.errors.giro}
                  disabled={isCustomerLoading}
                  autoComplete="new-giro"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  name="address"
                  label={texts.address}
                  placeholder={texts.addressPlaceholder}
                  variant="outlined"
                  value={form.values.address}
                  onChange={form.handleChange}
                  error={form.touched.address && Boolean(form.errors.address)}
                  helperText={form.touched.address && form.errors.address}
                  disabled={isCustomerLoading}
                  autoComplete="new-address"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.switch}>
              <FormControlLabel
                control={(
                  <Switch
                    onChange={onChangeAgreement}
                    checked={form.values.agreement}
                    disabled={isCustomerLoading}
                  />
              )}
                label={texts.agreement}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <ButtonAdorned color="secondary" onClick={onClickDelete}>
                  {texts.todelete}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push('/e/clientes')}>
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <form onSubmit={form.handleSubmit}>
                  <ButtonAdorned loading={isCustomerLoading} disabled={isCustomerLoading} type="submit">
                    {texts.save}
                  </ButtonAdorned>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string,
};

Edit.defaultProps = {
  id: '',
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
  },
  switch: {
    alignSelf: 'center',
  },
}));

export default Edit;
