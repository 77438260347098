import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { Alert } from '@components/atoms';
import { useAnalisis } from '@hooks';
import {
  AnalisisAsignacion, AnalisisEjecucion, AnalisisRevision, AnalisisRedaccion, AnalisisCompletado, FichaIngreso,
} from '@components/organisms';
import { getCompleteName, getDate } from '@helpers/utils';

const Edit = ({ id, setExtraInfo }) => {
  const {
    getAnalisis, isAnalisisLoading, analisisError,
  } = useAnalisis();
  const [analisis, setAnalisis] = useState({
    customer: '',
    presupuesto: {},
    docsFichaIngreso: [],
    number: '',
    members: [],
    docsPlantilla: [],
  });

  useEffect(async () => {
    setExtraInfo([]);
    const serviceAnalisis = await getAnalisis({ id });
    const updated = `Última actualización por: ${getCompleteName(serviceAnalisis.updatedBy)} | ${getDate(serviceAnalisis.updatedAt)}`;
    const created = `Creado por: ${getCompleteName(serviceAnalisis.createdBy)} | ${getDate(serviceAnalisis.createdAt)}`;
    setExtraInfo([created || '', updated || '']);

    setAnalisis(serviceAnalisis);
  }, []);

  return (
    <>
      {analisisError && <Alert>{analisisError.message}</Alert>}
      { (isAnalisisLoading) ? <LinearProgress /> : (
        <>
          {analisis.step === 'fichaingreso' && <FichaIngreso data={analisis} />}
          {analisis.step === 'asignacionlab' && <AnalisisAsignacion data={analisis} />}
          {analisis.step === 'ejecucion' && <AnalisisEjecucion data={analisis} />}
          {analisis.step === 'revision' && <AnalisisRevision data={analisis} />}
          {analisis.step === 'redaccion' && <AnalisisRedaccion data={analisis} />}
          {analisis.step === 'completado' && <AnalisisCompletado data={analisis} />}
        </>
      )}
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string,
  setExtraInfo: PropTypes.func,
};

Edit.defaultProps = {
  id: '',
  setExtraInfo: () => {},
};

export default Edit;
