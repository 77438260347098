import * as validator from 'yup';
import i18n from '@i18n';

const createSolicitudSchema = validator.object({
  customer: validator.object({
    id: validator.string().required(i18n.validationSchemas.customer.required),
    name: validator.string(),
  }),
  contact: validator.object({
    id: validator.string().required(i18n.validationSchemas.contact.required),
    name: validator.string(),
    lastname: validator.string(),
  }),
  files: validator
    .array(),
  // .min(1, i18n.validationSchemas.file.required),
  title: validator.string().required(i18n.validationSchemas.title.required),
  description: validator.string().required(i18n.validationSchemas.title.required),
  state: validator.object({
    id: validator.string().required(i18n.validationSchemas.state.required),
    name: validator.string(),
  }),
});

export default createSolicitudSchema;
