import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import i18n from '@i18n';
import { Alert, DataGrid } from '@components/atoms';
import { Chart } from 'react-google-charts';
import { Grid, LinearProgress, IconButton } from '@material-ui/core';
import { useReports } from '@hooks';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Filtros from './Filtros';

const MuestrasByLaboratorista = () => {
  const { pages: { employees: { reporteria: SubReportsTexts } } } = i18n;
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { getMetodosByLaboratorista, reportError, isReportLoading } = useReports();
  const [report, setReport] = useState(null);
  const [filters, setFilters] = useState({
    dateInit: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    all: false,
    archivados: false,
    generate: false,
    ejecucion: false,
  });

  useEffect(async () => {
    if (filters.generate) {
      if (moment(filters.dateEnd).isBefore(filters.dateInit) && !filters.all) {
        enqueueSnackbar('La fecha de inicio no puede ser mayor a la fecha final', { variant: 'error' });
      } else {
        const result = await getMetodosByLaboratorista(filters);
        setReport(result);
      }
    }
    setFilters({ ...filters, generate: false });
  },
  [filters.generate]);

  const options = {
    title: 'Muestras por Laboratorista',
  };

  const columns = [{
    field: 'lab',
    headerName: 'Laboratorista',
    flex: 1,
    align: 'left',
    headerAlign: 'center',
  },
  {
    field: 'Cantidad',
    headerName: 'Cantidad',
    flex: 0.5,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
    renderCell: (data) => {
      const { id, row } = data;
      return (
        <IconButton
          className={classes.actionIcons}
          onClick={() => history.push(`/e/reporteria/ensayosbylaboratorista/${id}`, { data: report.data, from: 'muestrasbylab' })}
        >
          <span style={{ textDecoration: 'underline' }}>{row.Cantidad}</span>
        </IconButton>
      );
    },
  }];

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${SubReportsTexts.subreports.ingresosxarea.title}`}</title>
      </Helmet>
      <Filtros filters={filters} setFilters={setFilters} />
      {reportError && <Alert>{reportError.message}</Alert>}
      {isReportLoading && <LinearProgress />}
      {report && (
        <Grid container spacing={0} classes={classes.container} style={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={report.dataGraph}
              options={options}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rowsPerPage={10}
              rowHeight={25}
              columns={columns}
              rows={report.dataTable}
              key="id"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
  changeContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
}));

MuestrasByLaboratorista.propTypes = {
};

MuestrasByLaboratorista.defaultProps = {
};

export default MuestrasByLaboratorista;
