const employees = {
  title: 'Colaboradores',
  add: 'Agregar colaborador',
  list: {
    title: 'Colaboradores',
    columns: {
      title: 'Título',
      name: 'Nombre',
      lastname: 'Apellido',
      completeName: 'Nombre completo',
      rut: 'Rut',
      email: 'Email',
      rol: 'Rol',
      phoneNumber: 'Teléfono',
    },
  },
  create: {
    title: 'Título',
    titlePlaceholder: 'Ej: Sr, Sra',
    name: 'Nombre *',
    namePlaceholder: 'Ej: Juan',
    lastname: 'Apellido *',
    lastnamePlaceholder: 'Ej: González',
    rut: 'Rut *',
    rutPlaceholder: 'Ej: 17111111-0',
    rol: 'Rol',
    phoneNumber: 'Teléfono',
    phoneNumberPlaceholder: 'Ej: 971111111',
    password: 'Contraseña *',
    email: 'Email *',
    emailPlaceholder: 'Ej: juan@caltex.cl',
    passwordPlaceholder: 'Procure que sea segura',
    save: 'Crear',
    list: 'Volver a la lista',
    subtitleData: 'Datos del colaborador',
    subtitleAccount: 'Cuenta del colaborador',
    success: 'Colaborador creado exitosamente',
  },
  view: {
    title: 'Título',
    name: 'Nombre',
    lastname: 'Apellido',
    rut: 'Rut',
    rol: 'Rol',
    phoneNumber: 'Teléfono',
    password: 'Contraseña',
    email: 'Email',
    edit: 'Editar',
    list: 'Volver a la lista',
    subtitleData: 'Datos del colaborador',
    subtitleAccount: 'Cuenta del colaborador',
  },
  edit: {
    title: 'Título',
    titlePlaceholder: 'Ej: Sr, Sra',
    name: 'Nombre *',
    namePlaceholder: 'Ej: Juan',
    lastname: 'Apellido *',
    lastnamePlaceholder: 'Ej: González',
    rut: 'Rut *',
    rutPlaceholder: 'Ej: 17111111-0',
    rol: 'Rol',
    phoneNumber: 'Teléfono',
    phoneNumberPlaceholder: 'Ej: 971111111',
    email: 'Email *',
    emailPlaceholder: 'Ej: juan@caltex.cl',
    password: 'Contraseña (solo si desea cambiarla)',
    passwordPlaceholder: 'Procure que sea segura',
    todisable: 'Inhabilitar',
    toenable: 'Habilitar',
    save: 'Guardar',
    list: 'Volver a la lista',
    subtitleData: 'Datos del colaborador',
    subtitleAccount: 'Cuenta del colaborador',
    success: 'Colaborador editado exitosamente',
  },
};

export default employees;
