import React from 'react';
import {
  ListItem, ListItemText, ListItemSecondaryAction, IconButton, FormHelperText, Chip,
} from '@material-ui/core';
import {
  PublishOutlined as UploadIcon,
  InsertDriveFileOutlined as PreviewIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import constants from '@helpers/constants';

const MAX_LENGTH = 10;
const getTruncated = (text) => (text && text.length > MAX_LENGTH ? `${text.substring(0, MAX_LENGTH)}...` : text);

const FileUpload = (props) => {
  const {
    title, value, onChange, name, disabled, error, helperText, url,
  } = props;
  const classes = useStyles();
  const uploadIcon = <UploadIcon />;
  const previewIcon = <PreviewIcon />;

  const handleDelete = () => {
    onChange({
      target: {
        value: [],
        name,
      },
    });
  };

  const handlePreview = () => {
    window.open(url, '_blank');
  };

  const ListItemChips = () => (
    <>
      {value && value.map((item) => (
        <Chip
          color="secondary"
          className={classes.chip}
          label={getTruncated(item.name)}
          key={item.name}
          onDelete={() => handleDelete(item)}
          disabled={disabled}
        />
      ))}
    </>
  );

  const onInternalChange = (event) => {
    if (event.target.files.length === 0) {
      onChange(event); return;
    }
    const selectedValues = Array.from(event.target.files);
    onChange({
      ...event,
      target: {
        ...event.target,
        value: selectedValues,
        name,
      },
    });
  };
  return (
    <>
      <ListItem className={classes.root} style={error ? { border: '1px solid #f44336' } : null}>
        <ListItemText
          primary={(
            <div className={classes.listItemTextPrimary}>
              {title}
              <ListItemChips />
            </div>
          )}
        />
        <ListItemSecondaryAction>
          {name && (
          <>
            <input
              disabled={disabled}
              accept={constants.FILE_TYPE}
              name={name}
              className={classes.input}
              id={name}
              type="file"
              onChange={onInternalChange}
            />
            <label htmlFor={name}>
              <IconButton edge="end" aria-label={title} component="span" disabled={disabled} className={classes.icon}>
                {uploadIcon}
              </IconButton>
            </label>
          </>
          )}
          {url && (
          <IconButton edge="end" aria-label={title} component="span" className={classes.icon} onClick={handlePreview}>
            {previewIcon}
          </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {error && <FormHelperText variant="outlined" error>{helperText}</FormHelperText>}
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    background: '#F4F2F2',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
    marginTop: '2px',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  input: {
    display: 'none',
  },
  listItemTextPrimary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingRight: '2rem',
  },
}));

FileUpload.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

FileUpload.defaultProps = {
  title: 'Archivo',
  name: undefined,
  url: '',
  value: [],
  onChange: undefined,
  disabled: false,
  error: false,
  helperText: undefined,
};

export default FileUpload;
