import { useState, useCallback } from 'react';
import { getServiceStatesFacturacion } from '../services/statesFacturacion';

const useStatesFacturacion = () => {
  const [state, setState] = useState({ loading: false, error: null, states: [] });

  const getStatesFacturacion = useCallback(async () => {
    setState({ loading: true, error: null, roles: [] });
    try {
      const states = await getServiceStatesFacturacion();
      setState({ loading: false, error: null, states });
      return states;
    } catch (e) {
      setState({ loading: false, error: e, roles: [] });
      return [];
    }
  });

  return {
    isStateLoading: state.loading,
    statesError: state.error,
    getStatesFacturacion,
  };
};

export default useStatesFacturacion;
