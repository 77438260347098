import { useState, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import {
  getServiceSolicitud, getServiceSolicitudes, postServiceSolicitud, putServiceSolicitud,
} from '../services/solicitudes';

const useSolicitudes = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const uploadFiles = useCallback(async ({ files, customerId, solicitudId }) => {
    const promises = files.map(async (file) => {
      const refStorage = firebase.storage().ref(`${customerId}/solicitudes/${solicitudId}/${file.name}`);
      await refStorage.put(file);
      const fileURL = await refStorage.getDownloadURL();
      return fileURL;
    });
    const fileURLs = await Promise.all(promises);
    return fileURLs;
  }, []);

  const removeFiles = useCallback(async ({
    customerId, solicitudId,
  }) => {
    const refStorage = firebase.storage().ref(`${customerId}/solicitudes/${solicitudId}/`);
    const listResults = await refStorage.listAll();
    const promises = listResults.items.map((item) => item.delete());
    Promise.all(promises);
  }, []);

  const getFiles = useCallback(async ({ customerId, solicitudId }) => {
    setState({ loading: true, error: null });
    try {
      const refStorage = firebase.storage().ref();
      const folder = refStorage.child(`${customerId}/solicitudes/${solicitudId}/`);
      const getData = (await folder.listAll()).items.map(async (detail) => {
        const metaData = await detail.getMetadata();
        const url = await detail.getDownloadURL();
        return {
          timeCreated: metaData.timeCreated, url, id: url, name: metaData.name,
        };
      });
      const result = await Promise.all(getData);
      setState({ loading: false, error: null });
      return result;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  }, []);

  const getSolicitudes = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const solicitudes = await getServiceSolicitudes(data);
      setState({ loading: false, error: null });
      return solicitudes;
    } catch (e) {
      setState({ loading: false, error: e });
      return [];
    }
  });

  const getSolicitud = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const solicitud = await getServiceSolicitud(data);
      setState({ loading: false, error: null });
      return solicitud;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const createSolicitud = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const { files, ...solicitud } = data;
      const solicitudId = await postServiceSolicitud(solicitud);
      if (files && files.length > 0 && files[0].size) {
        await uploadFiles({
          files,
          solicitudId,
          customerId: solicitud.contact.customer.id,
        });
      }
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const editSolicitud = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const { files, ...solicitud } = data;
      if (files && files.length > 0 && files[0].size) {
        await removeFiles({
          solicitudId: solicitud.id,
          customerId: solicitud.contact.customer.id,
        });

        await uploadFiles({
          files,
          solicitudId: solicitud.id,
          customerId: solicitud.contact.customer.id,
        });
      }

      await putServiceSolicitud({ ...solicitud });
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  return {
    isSolicitudLoading: state.loading,
    solicitudError: state.error,
    getSolicitudes,
    getSolicitud,
    createSolicitud,
    editSolicitud,
    getFiles,
    removeFiles,
  };
};

export default useSolicitudes;
