import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Menu, MenuItem, LinearProgress, IconButton, Hidden, useMediaQuery,
} from '@material-ui/core';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton,
} from '@material-ui/data-grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  EditOutlined as EditarIcon,
  VisibilityOutlined as VerIcon,
  ListOutlined as MenuIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Alert, DataGrid } from '@components/atoms';
import { useContacts } from '@hooks';
import AuthContext from '@contexts/AuthContext';

import i18n from '@i18n';

const List = ({ search }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pages: { employees: { contacts: { list: texts } } } } = i18n;
  const { getContacts, contactError, isContactLoading } = useContacts();
  const [contacts, setContacts] = useState([]);
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const ltSmSize = useMediaQuery(theme.breakpoints.down('sm'));
  const onlyxsSize = useMediaQuery(theme.breakpoints.only('xs'));

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(async () => {
    setContacts(await getContacts(search));
  }, []);

  const menuActions = (values) => (
    <>
      <Hidden xsDown>
        {user && user.permissions.admin.update && (
        <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/contactos/edit/${values.row.id}`)}>
          <EditarIcon />
        </IconButton>
        )}
        {user && user.permissions.admin.read && (
        <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/contactos/view/${values.row.id}`)}>
          <VerIcon />
        </IconButton>
        )}
      </Hidden>
      <Hidden smUp>
        <IconButton className={classes.actionIcons} onClick={(event) => setAnchorEl(event.currentTarget)}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {user && user.permissions.admin.update && (
          <MenuItem onClick={() => history.push(`/e/contactos/edit/${values.row.id}`)}>Editar</MenuItem>
          )}
          {user && user.permissions.admin.read && (
          <MenuItem onClick={() => history.push(`/e/contactos/view/${values.row.id}`)}>Ver</MenuItem>
          )}
        </Menu>
      </Hidden>
    </>
  );

  const columnsSm = [
    {
      field: 'customer',
      headerName: texts.columns.customer,
      valueGetter: (values) => values.row.customer.name,
    },
    {
      field: 'title',
      headerName: texts.columns.title,
    },
    {
      field: 'name',
      headerName: texts.columns.name,
    },
    {
      field: 'lastname',
      headerName: texts.columns.lastname,
    },
    {
      field: 'email',
      headerName: texts.columns.email,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => menuActions(values),
    },
  ];

  const columns = [
    {
      field: 'customer',
      headerName: texts.columns.customer,
      flex: 1,
      valueGetter: (values) => values.row.customer.name,
    },
    {
      field: 'title',
      headerName: texts.columns.title,
      flex: 1,
    },
    {
      field: 'name',
      headerName: texts.columns.name,
      flex: 1,
    },
    {
      field: 'lastname',
      headerName: texts.columns.lastname,
      flex: 1,
    },
    {
      field: 'email',
      headerName: texts.columns.email,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => menuActions(values),
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const CustomToolbar = () => (
    <>
      <Hidden xsDown>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
      <Hidden smUp>
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
        </GridToolbarContainer>
      </Hidden>
    </>
  );
  return (
    <>
      {contactError && <Alert>{contactError.message}</Alert>}
      {(isContactLoading) ? <LinearProgress /> : (
        <Grid container spacing={2}>
          <DataGrid
            columns={onlyxsSize ? columnsSm : columns}
            rows={contacts}
            pageSize={ltSmSize ? 8 : 25}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
    </>
  );
};

List.propTypes = {
  search: PropTypes.string,
};

List.defaultProps = {
  search: undefined,
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

export default List;
