const facturacion = {
  title: 'Facturación',
  add: 'Agregar facturación',
  list: {
    title: 'Facturación',
    columns: {
      customer: 'Cliente',
      contact: 'Contacto',
      file: 'Archivo',
      number: 'Número',
      ingreso: 'No. Ingreso',
      description: 'Descripción',
      state: 'Estado',
      createdBy: 'Creado por',
      createdAt: 'Fecha creación',
    },
  },
  create: {
    subtitleInforme: 'Datos Informe',
    subtitleFacturacion: 'Datos facturación',
    customer: 'Cliente *',
    contact: 'Contacto *',
    facturacion: 'Análisis con informe *',
    noFacturacion: 'Cliente no tiene análisis con informe disponible',
    agreement: 'Tipo Acuerdo',
    save: 'Crear',
    list: 'Volver a la lista',
    success: 'Facturación creada exitosamente',
    noAnalisis: 'Cliente no tiene analisis',
    docFactura: 'Factura *',
    docInforme: 'Informe',
    docInformeCliente: 'Informe Cliente *',
    folio: 'Folio Factura *',
    amount: 'Monto $ *',
    fechaExp: 'Fecha Expiración *',
    state: 'Estado',
  },
  edit: {
    subtitleInforme: 'Datos Informe',
    subtitleFacturacion: 'Datos Facturación',
    customer: 'Cliente *',
    contact: 'Contacto *',
    analisis: 'Análisis con informe *',
    agreement: 'Tipo Acuerdo',
    save: 'Guardar',
    list: 'Volver a la lista',
    success: 'Facturación modificada exitosamente',
    docFactura: 'Factura *',
    docInforme: 'Informe',
    docInformeCliente: 'Informe Cliente *',
    folio: 'Folio Factura *',
    amount: 'Monto $ *',
    fechaExp: 'Fecha Expiración *',
    state: 'Estado',
    presupuesto: 'Presupuesto',
  },
  view: {
    subtitleInforme: 'Datos Informe',
    subtitleFacturacion: 'Datos Facturación',
    customer: 'Cliente *',
    contact: 'Contacto *',
    analisis: 'Análisis con informe *',
    agreement: 'Tipo Acuerdo',
    save: 'Guardar',
    list: 'Volver a la lista',
    success: 'Facturación modificada exitosamente',
    docFactura: 'Factura *',
    docInforme: 'Informe',
    docInformeCliente: 'Informe Cliente *',
    folio: 'Folio Factura *',
    amount: 'Monto $ *',
    fechaExp: 'Fecha Expiración *',
    state: 'Estado',
    statusAccept: 'Estado informe',
    accept: 'Aceptar Informe',
    presupuesto: 'Presupuesto',
  },
};

export default facturacion;
