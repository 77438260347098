import * as validator from 'yup';

import emailSchema from './emailSchema';
import passwordSchema from './passwordSchema';

const loginSchema = validator.object({
  email: emailSchema,
  password: passwordSchema,
});

export default loginSchema;
