import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServiceContacts(data = '') {
  try {
    const response = await API.get(`/contact${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/contact/', message: `Hubo un problema al intentar buscar contactos. ${message}` });
  }
}

export async function getServiceContact(data) {
  try {
    const response = await API.get(`/contact/${data.id}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/contact/', message: `Hubo un problema al intentar buscar contactos. ${message}` });
  }
}

export async function postServiceContact(data) {
  try {
    const response = await API.post('/contact', data);
    if (response && response.headers && response.headers.location) {
      return response.headers.location;
    }
    return '';
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/contact/', message: `Hubo un problema al intentar crear contacto. ${message}` });
  }
}

export async function putServiceContact(data) {
  try {
    const response = await API.put('/contact', data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/contact/', message: `Hubo un problema al intentar editar contacto. ${message}` });
  }
}

export async function changeDisabledServiceContact(data) {
  try {
    const response = await API.put('/contact/disabled', data);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/contact/', message: `Hubo un problema al intentar habilitar/inhabilitar contacto. ${message}` });
  }
}

export async function deleteServiceContact(data) {
  try {
    const response = await API.delete(`/contact/delete/${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/contact/', message: `Hubo un problema al intentar eliminar contacto. ${message}` });
  }
}
