import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title, Subtitle, AddButton } from '@components/atoms';
import i18n from '@i18n';
import AuthContext from '@contexts/AuthContext';
import List from './List';
import Create from './Create';
import View from './View';
import Edit from './Edit';

const Employee = ({ match: { params: { action = 'list', id = '' } } }) => {
  const { pages: { employees: { employees: texts } } } = i18n;
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const handleAddClick = () => {
    history.push('/e/colaboradores/create');
  };

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      <Grid
        container
        spacing={0}
        className={classes.container}
        alignItems="center"
      >
        {texts.title && (
        <>
          <Grid item xs={6} md={8} align="left">
            <Title>{texts.title}</Title>
          </Grid>
          {action === 'list' && user && user.permissions.admin.create && (
          <Grid item xs={6} md={4} align="right">
            <AddButton onClick={handleAddClick}>{texts.add}</AddButton>
          </Grid>
          )}
        </>
        )}
      </Grid>
      {texts.subtitle && (
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={12} align="left">
          <Subtitle>{texts.subtitle}</Subtitle>
        </Grid>
      </Grid>
      )}
      <Divider />
      <div className={classes.container}>
        {action === 'list' && user && user.permissions.admin.read && <List />}
        {action === 'create' && user && user.permissions.admin.create && <Create />}
        {action === 'view' && user && user.permissions.admin.read && <View id={id} />}
        {action === 'edit' && user && user.permissions.admin.update && <Edit id={id} />}
      </div>
      <Divider />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
}));

Employee.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

Employee.defaultProps = {
  match: {
    params: {
      action: 'list',
      id: '',
    },
  },
};

export default Employee;
