import React from 'react';
import { Divider as MuiDivider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Divider = () => {
  const classes = useStyles();

  return (
    <MuiDivider className={classes.root} />
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '1em',
    marginBottom: '1em',
  },
}));

Divider.propTypes = {
};

export default Divider;
