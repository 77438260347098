import { useState, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';

import {
  getServiceFacturaciones, getServiceFacturacion, postServiceFacturacion,
} from '../services/facturacion';

import { getServiceFacturacionNoAuth, getServiceDocumentNoAuth } from '../services/facturacionNoAuth';

const useInformes = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const uploadFiles = useCallback(async ({
    files, customerId, facturacionId,
  }) => {
    const promises = files.map(async (file) => {
      const refStorage = firebase.storage().ref(`${customerId}/informes/${facturacionId}/${file.name}`);
      await refStorage.put(file);
      const fileURL = await refStorage.getDownloadURL();
      return fileURL;
    });
    const fileURLs = await Promise.all(promises);
    return fileURLs;
  }, []);

  const removeFiles = useCallback(async ({
    customerId, facturacionId,
  }) => {
    const refStorage = firebase.storage().ref(`${customerId}/informes/${facturacionId}`);
    const listResults = await refStorage.listAll();
    const promises = listResults.items.map((item) => item.delete());
    Promise.all(promises);
  }, []);

  const getInformes = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const facturaciones = await getServiceFacturaciones(data);
      setState({ loading: false, error: null });
      return facturaciones;
    } catch (e) {
      setState({ loading: false, error: e });
      return [];
    }
  });

  const getInforme = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const facturacion = await getServiceFacturacion(data);
      setState({ loading: false, error: null });
      return facturacion;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const getInformeNoAuth = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const facturacion = await getServiceFacturacionNoAuth(data);
      setState({ loading: false, error: null });
      return facturacion;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const getFiles = useCallback(async ({ customerId, facturacionId }) => {
    try {
      setState({ loading: true, error: null });
      const refStorage = firebase.storage().ref();
      const folder = refStorage.child(`${customerId}/informes/${facturacionId}`);
      const getData = (await folder.listAll()).items.map(async (detail) => {
        const metaData = await detail.getMetadata();
        const url = await detail.getDownloadURL();
        return {
          timeCreated: metaData.timeCreated, url, id: url, name: metaData.name,
        };
      });
      const result = await Promise.all(getData);
      setState({ loading: false, error: null });
      return result;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  }, []);

  const getFilesCert = useCallback(async ({ customerId, facturacionId, name }) => {
    try {
      setState({ loading: true, error: null });
      const docPath = `${customerId}/informes/${facturacionId}/${name}`;
      const document = await getServiceDocumentNoAuth({ docPath, id: facturacionId });
      setState({ loading: false, error: null });
      return document;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  }, []);

  const createInforme = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const {
        analisis, contact, customer, docInformeCliente,
      } = data;
      const formattedFacturacion = {
        analisis,
        contact: {
          ...contact,
          customer,
        },
      };
      const facturacionId = await postServiceFacturacion(formattedFacturacion);

      if (docInformeCliente && docInformeCliente.length > 0 && docInformeCliente[0].size) {
        await uploadFiles({
          files: docInformeCliente,
          facturacionId,
          customerId: formattedFacturacion.contact.customer.id,
        });
      }
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const editInforme = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const {
        docInformeCliente, analisis, id,
      } = data;
      if (docInformeCliente && docInformeCliente.length > 0 && docInformeCliente[0].size) {
        await removeFiles({
          facturacionId: id,
          customerId: analisis.customer.id,
        });
        await uploadFiles({
          files: docInformeCliente,
          facturacionId: id,
          customerId: analisis.customer.id,
        });
      }
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  return {
    isInformeLoading: state.loading,
    informeError: state.error,
    getInformes,
    getInforme,
    getInformeNoAuth,
    createInforme,
    editInforme,
    getFiles,
    getFilesCert,
  };
};

export default useInformes;
