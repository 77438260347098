export default {
  title: 'Perfil',
  subtitle: 'Información de tu perfil. Esta no puede ser modificada.',
  logout: 'Cerrar Sesión',
  resendEmail: 'Enviar email de verificación ',
  sentEmail: 'Email enviado ',
  changePassword: 'Solicitar cambio de contraseña',
  changedPassword: 'Se ha enviado un correo a su bandeja de entrada para cambiar su contraseña',
  form: {
    title: 'Título',
    name: 'Nombre',
    lastname: 'Apellido',
    rut: 'Rut',
    email: 'Correo',
    phoneNumber: 'Teléfono',
    roles: 'Roles',
    emailVerified: 'Email verificado',
  },
};
