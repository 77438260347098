import * as React from 'react';
import {
  Switch, Route, BrowserRouter, Redirect,
} from 'react-router-dom';
import './App.css';
import ValidationPage from '@components/pages/Validation';
import DetalleEnsayosLab from '@components/pages/Employees/Reporteria/DetalleEnsayosLab';
import { CustomerLayout, EmployeeLayout, NotAuthLayout } from './components/templates';
import {
  Dashboard as CDashboard,
  Profile as CProfile,
  Presupuestos as CPresupuestos,
  Facturacion as CFacturacion,
  Tutorials as CTutorials,
  Solicitudes as CSolicitudes,
  Validation as CValidacion,
} from './components/pages/Customers';
import {
  Analisis as EAnalisis,
  Dashboard as EDashboard,
  Employees as EEmployees,
  Customers as ECustomers,
  Contacts as EContacts,
  Profile as EProfile,
  Presupuestos as EPresupuestos,
  Facturacion as EFacturacion,
  Informe as EInforme,
  Tutorials as ETutorials,
  Solicitudes as ESolicitudes,
  Validacion as EValidacion,
  Reporteria as EReporteria,
} from './components/pages/Employees';
import { NotFound, Forbidden } from './components/pages/General';
import Login from './components/pages/Login';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/n/validation" component={ValidationPage} />
      <Route exact path="/e/:path1?/:path2?/:path3?">
        <EmployeeLayout>
          <Switch>
            <Route exact path="/e/" component={EDashboard} />
            <Route exact path="/e/perfil" component={EProfile} />
            <Route exact path="/e/tutoriales" component={ETutorials} />
            <Route exact path="/e/presupuestos/:action?/:id?" component={EPresupuestos} />
            <Route exact path="/e/analisis/:action?/:id?" component={EAnalisis} />
            <Route exact path="/e/facturacion/:action?/:id?" component={EFacturacion} />
            <Route exact path="/e/informes/:action?/:id?" component={EInforme} />
            <Route exact path="/e/colaboradores/:action?/:id?" component={EEmployees} />
            <Route exact path="/e/clientes/:action?/:id?" component={ECustomers} />
            <Route exact path="/e/contactos/:action?/:id?" component={EContacts} />
            <Route exact path="/e/solicitudes/:action?/:id?" component={ESolicitudes} />
            <Route exact path="/e/validacion/:action?/:id?" component={EValidacion} />
            <Route exact path="/e/reporteria/:reporte?" component={EReporteria} />
            <Route exact path="/e/reporteria/ensayosbylaboratorista/:id?" component={DetalleEnsayosLab} />
            <Route component={NotFound} />
          </Switch>
        </EmployeeLayout>
      </Route>
      <Route exact path="/c/:path1?/:path2?/:path3?">
        <CustomerLayout>
          <Switch>
            <Route exact path="/c/" component={CDashboard} />
            <Route exact path="/c/perfil" component={CProfile} />
            <Route exact path="/c/tutoriales" component={CTutorials} />
            <Route exact path="/c/presupuestos/:action?/:id?" component={CPresupuestos} />
            <Route exact path="/c/facturacion/:action?/:id?" component={CFacturacion} />
            <Route exact path="/c/solicitudes/:action?/:id?" component={CSolicitudes} />
            <Route exact path="/c/validacion/:action?/:id?" component={CValidacion} />
            <Route component={NotFound} />
          </Switch>
        </CustomerLayout>
      </Route>
      <Route exact path="/n/:path?/:subpath?">
        <NotAuthLayout>
          <Switch>
            <Route exact path="/n/login" component={Login} />
            <Route component={NotFound} />
          </Switch>
        </NotAuthLayout>
      </Route>
      <Route exact path="/forbidden" component={Forbidden} />
      <Route exact path="/">
        <Redirect to="/n/login" />
      </Route>
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;
