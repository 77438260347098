import * as validator from 'yup';

import i18n from '@i18n';

const createMetodoEnsayoSchema = validator.object({
  metodo: validator.string().required(i18n.validationSchemas.metodo.required),
  ensayo: validator.string().required(i18n.validationSchemas.ensayo.required),
  material: validator.string().required(i18n.validationSchemas.material.required),
});

export default createMetodoEnsayoSchema;
