import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import { useFacturacion, useInformes } from '@hooks';
import {
  Grid,
  FormControl,
  TextField,
  LinearProgress,
  Divider,
  InputAdornment,
} from '@material-ui/core';
import { CheckCircleRounded as CheckIcon, WarningOutlined as UnCkeckIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  ButtonAdorned,
  Subtitle,
  MultiFileUpload,
  ChipStep,
  DatePicker,
  Alert,
} from '@components/atoms';
import { Messaging } from '@components/molecules';
import {
  getCompleteName, getDate, getPriceFormatted, getStateFacturacion,
} from '@helpers/utils';
import constants from '@helpers/constants';

const View = ({ id, setExtraInfo }) => {
  const { pages: { employees: { facturacion: { view: texts } } } } = i18n;
  const history = useHistory();
  const [facturacion, setFacturacion] = useState(null);
  const [message, setMessage] = useState('');
  const {
    getFacturacion, editFacturacion, isFacturacionLoading, facturacionError, getFiles: getFilesFacturacion,
  } = useFacturacion();
  const { getFiles: getFileInformeCliente, isInformeLoading } = useInformes();

  const hanndleAccepted = async () => {
    await editFacturacion({ facturacion: { id }, accepted: true });
    history.push('/e/facturacion');
  };

  const refreshFacturacion = async () => {
    setExtraInfo([]);
    const serviceFacturacion = await getFacturacion({ id });
    const docInformeCliente = await getFileInformeCliente({
      customerId: serviceFacturacion?.contact?.customer?.id,
      facturacionId: id,
    });
    const docFactura = await getFilesFacturacion({
      customerId: serviceFacturacion?.contact?.customer?.id,
      facturacionId: id,
    });

    const updated = `Última actualización por: ${getCompleteName(serviceFacturacion.updatedBy)} | ${getDate(serviceFacturacion.updatedAt)}`;
    const created = `Creado por: ${getCompleteName(serviceFacturacion.createdBy)} | ${getDate(serviceFacturacion.createdAt)}`;
    setExtraInfo([created || '', updated || '']);
    setFacturacion({
      ...serviceFacturacion,
      docInformeCliente,
      docFactura,
      expiredDate: serviceFacturacion.expiredDate?.substring(0, 10),
    });
  };

  const handleClickMessage = async () => {
    if (message) {
      await editFacturacion({ facturacion: { id }, message });
      setMessage('');
      await refreshFacturacion();
    }
  };
  useEffect(async () => {
    await refreshFacturacion();
  }, []);

  return (
    <>
      {facturacionError && <Alert>{facturacionError.message}</Alert>}
      {(isFacturacionLoading || isInformeLoading) && <LinearProgress />}
      {!isFacturacionLoading && !isInformeLoading && facturacion && (
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Messaging
            items={facturacion.messaging}
            state={facturacion.state}
            message={message}
            setMessage={setMessage}
            accepted={facturacion.accepted}
            handleClickMessage={handleClickMessage}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={1}
          justifyContent="center"
        >
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={2}>
            {texts.subtitleInforme && (
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleInforme}</Subtitle>
            </Grid>
            )}
            <Grid item xs={12} sm={12} md={4}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id={texts.customer}
                  label={texts.customer}
                  variant="outlined"
                  defaultValue={facturacion?.contact.customer.name}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id={texts.contact}
                  label={texts.contact}
                  variant="outlined"
                  defaultValue={`${facturacion.contact.name} ${facturacion.contact.lastname}`}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.agreement}
                  variant="outlined"
                  disabled
                  value={facturacion.analisis?.agreement ? 'Con convenio' : 'Sin convenio'}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {(facturacion.analisis?.agreement ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />)}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id={texts.presupuesto}
                  label={texts.presupuesto}
                  variant="outlined"
                  defaultValue={facturacion.analisis.presupuesto?.number}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docInformeCliente}
                  urls={facturacion.docInformeCliente}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label={texts.statusAccept}
                  variant="outlined"
                  disabled
                  value={facturacion && (facturacion.accepted ? 'Aceptado' : 'Sin Aceptar')}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        {facturacion && (facturacion.accepted ? <CheckIcon style={{ color: '#007f5f' }} /> : <UnCkeckIcon style={{ color: '#FFCC00' }} />)}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleFacturacion}</Subtitle>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id={texts.folio}
                  label={texts.folio}
                  variant="outlined"
                  defaultValue={facturacion.folio}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  id={texts.amount}
                  label={texts.amount}
                  variant="outlined"
                  defaultValue={getPriceFormatted(facturacion.amount)}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <DatePicker
                  label={texts.fechaExp}
                  value={facturacion.expiredDate}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docFactura}
                  urls={facturacion.docFactura || []}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ alignSelf: 'center' }}>
              <FormControl variant="outlined" fullWidth>
                <ChipStep
                  label={facturacion.stateFacturacion.name}
                  backgroundColor={getStateFacturacion(facturacion.stateFacturacion.id).backgroundColor}
                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <ButtonAdorned
                  color="default"
                  onClick={() => history.push('/e/facturacion')}
                >
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              { facturacion && !facturacion.accepted && (
              <Grid item>
                <ButtonAdorned
                  color="primary"
                  onClick={hanndleAccepted}
                  disabled={facturacion.stateFacturacion?.id === constants.STATE_FACTURACION.PENDIENTE_PAGO.id}
                >
                  {texts.accept}
                </ButtonAdorned>
              </Grid>
              )}
            </Grid>
          </Grid>

        </Grid>
      </Grid>
      )}
    </>
  );
};

View.propTypes = {
  id: PropTypes.string,
  setExtraInfo: PropTypes.func,
};

View.defaultProps = {
  id: '',
  setExtraInfo: () => {},
};
export default View;
