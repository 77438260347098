export default {
  useAuth: {
    'auth/popup-closed-by-user': '',
    'auth/wrong-password': 'La contraseña no es válida o el usuario no existe.',
    'auth/user-not-found': 'La contraseña no es válida o el usuario no existe.',
    'auth/internal-error': 'Hubo un error interno.',
    'auth/user-disabled': 'Su cuenta ha sido inhabilitada', // The user account has been disabled by an administrator.
    'storage/unauthorized': 'Hubo un error interno.',
    'api/user/': 'Hubo un error interno.',
    'api/user/400': 'Hubo un error. Revise que los campos estén correctos.',
    'api/user/500': 'Hubo un error interno.',
  },
};
