import React, { useState, useEffect } from 'react';
import {
  Grid, Hidden, Menu, MenuItem, IconButton, LinearProgress,
} from '@material-ui/core';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton,
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  VisibilityOutlined as VerIcon,
  ListOutlined as MenuIcon,
} from '@material-ui/icons';

import { useHistory } from 'react-router-dom';
import { useFacturacion } from '@hooks';
import { Alert, ChipStep, DataGrid } from '@components/atoms';
import getDate from '@helpers/utils/getDate';
import constants from '@helpers/constants';

import i18n from '@i18n';

const List = () => {
  const { pages: { customers: { facturacion: { list: texts } } } } = i18n;
  const history = useHistory();
  const classes = useStyles();
  const { getFacturaciones, isFacturacionLoading, facturacionError } = useFacturacion();
  const [facturacion, setFacturacion] = useState(null);
  const [actionSelected, setActionSelected] = useState(null);

  useEffect(async () => {
    setFacturacion(await getFacturaciones());
  }, []);
  const handleClose = () => {
    setActionSelected(null);
  };

  const CustomToolbar = () => (
    <>
      <Hidden xsDown>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
      <Hidden smUp>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
    </>
  );

  const columns = [
    {
      field: 'contact',
      headerName: texts.columns.customer,
      flex: 0.5,
      valueGetter: (values) => values.row.contact.customer.name,
    },
    {
      field: 'sample',
      headerName: texts.columns.sample,
      flex: 0.5,
      valueGetter: (values) => values.row.analisis.sample,
    },
    {
      field: 'folio',
      headerAlign: 'center',
      headerName: texts.columns.number,
      align: 'center',
      flex: 0.4,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.value),
    },
    {
      field: 'state',
      headerName: texts.columns.state,
      renderCell: (values) => {
        const { row: { stateFacturacion } } = values;
        const color = Object.values(constants.STATE_FACTURACION).find((item) => item.id === stateFacturacion?.id);

        return (stateFacturacion ? (
          <ChipStep label={stateFacturacion.name} backgroundColor={color.backgroundColor} />
        ) : null);
      },
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => (
        <>
          <Hidden xsDown>
            <IconButton className={classes.actionIcons} onClick={() => history.push(`/c/facturacion/view/${values.row.id}`)}>
              <VerIcon />
            </IconButton>
          </Hidden>
          <Hidden smUp>
            <IconButton
              className={classes.actionIcons}
              onClick={(event) => setActionSelected({ anchorEl: event.currentTarget, id: values.row.id })}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={actionSelected && actionSelected.anchorEl}
                /* eslint-disable react/destructuring-assignment */
              open={actionSelected && actionSelected.id === values.row.id}
              onClose={handleClose}
            >
              <MenuItem onClick={() => history.push(`/c/facturacion/view/${values.row.id}`)}>Ver</MenuItem>

            </Menu>
          </Hidden>
        </>
      ),
    },
  ];

  return (
    <>
      {facturacionError && <Alert>{facturacionError.message}</Alert>}
      {(isFacturacionLoading) && <LinearProgress />}
      {facturacion && (
        <Grid container spacing={2}>
          <DataGrid
            sortModel={[{ field: 'createdAt', sort: 'desc' }]}
            columns={columns}
            rows={facturacion}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

export default List;
