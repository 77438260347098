import React from 'react';
import { SwipeableDrawer, makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    top: 64,
    paddingTop: 8,
  },
  drawerPaperMobile: {
    maxWidth: '100%',
    width: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Drawer = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <SwipeableDrawer
      classes={{
        paper: classes.drawerPaperMobile,
      }}
      ModalProps={{ keepMounted: true }}
      {...props}
    >
      <Box padding={1} style={{ width: '400px' }}>
        {children}
      </Box>
    </SwipeableDrawer>
  );
};

Drawer.propTypes = {
  children: PropTypes.node,
  anchor: PropTypes.string,
};

Drawer.defaultProps = {
  children: <></>,
  anchor: 'right',
};

export default Drawer;
