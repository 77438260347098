import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServiceRoles(data = '') {
  try {
    const response = await API.get(`/rol${data}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/roles/', message: `Hubo un problema al intentar buscar roles. ${message}` });
  }
}
