import * as validator from 'yup';

import { validateRut } from '@fdograph/rut-utilities';
import i18n from '@i18n';
import emailSchema from './emailSchema';

export default validator.object({
  title: validator
    .string(),
  name: validator
    .string()
    .required(i18n.validationSchemas.name.required),
  lastname: validator
    .string()
    .required(i18n.validationSchemas.lastname.required),
  rut: validator
    .string()
    .required(i18n.validationSchemas.rut.required)
    .test('rut', i18n.validationSchemas.rut.notValid, validateRut),
  phoneNumber: validator
    .string().min(7, i18n.validationSchemas.phoneNumber.required)
    .max(14, i18n.validationSchemas.phoneNumber.required),
  email: emailSchema,
  password: validator
    .string(),
});
