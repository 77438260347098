import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, Grid, TextField, IconButton,
} from '@material-ui/core';
import {
  Send as SendIcon,
} from '@material-ui/icons';
import { ChipMessage, Subtitle } from '@components/atoms';
import i18n from '@i18n';
import AuthContext from '@contexts/AuthContext';

const Messaging = ({
  items, message, setMessage, handleClickMessage, loading, accepted, title, placeholder,
}) => {
  const { components: { messaging: texts } } = i18n;
  const { user } = useContext(AuthContext);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [message]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} style={{ maxHeight: '80vh', overflow: 'auto' }}>
          <Grid item xs={12} align="left">
            <Subtitle>{title || texts.title}</Subtitle>
          </Grid>
          {user && items.map((item) => (
            item && (
              <Grid container item xs={12} key={item._id}>
                <ChipMessage
                  align={item.createdBy.id === user.id ? 'right' : 'left'}
                  title={`${item.createdBy.name} ${item.createdBy.lastname}`}
                  label={item.content}
                />
              </Grid>
            )
          ))}
          <Grid
            item
            ref={messagesEndRef}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {!accepted && (
          <>
            <Grid item xs={10} sm={9} md={9} lg={10}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  name="message"
                  variant="outlined"
                  placeholder={placeholder}
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  multiline
                  rows={3}
                  disabled={loading}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={3} md={3} lg={2}>
              <IconButton edge="end" disabled={loading} color="primary" onClick={handleClickMessage}>
                <SendIcon />
              </IconButton>
            </Grid>
          </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Messaging.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  handleClickMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  accepted: PropTypes.bool,
  title: PropTypes.string,
  placeholder: PropTypes.string,
};

Messaging.defaultProps = {
  items: [],
  loading: false,
  accepted: false,
  title: '',
  placeholder: '',
};

export default Messaging;
