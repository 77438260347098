import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Title, Subtitle, AddButton } from '@components/atoms';
import { DrawerChat } from '@components/molecules';
import { Grid, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '@i18n';
import AuthContext from '@contexts/AuthContext';
import List from './List';
import Create from './Create';
import Edit from './Edit';

const Analisis = ({ location: { search }, match: { params: { action = 'list', id = '' } } }) => {
  const { pages: { employees: { analisis: texts } } } = i18n;
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [extraInfo, setExtraInfo] = useState([]);

  const handleAddClick = () => {
    history.push('/e/analisis/create');
  };

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      <Grid
        container
        className={classes.container}
        alignItems="baseline"
        direction="row"
      >
        <Grid item xs={12} sm={3} align="left">
          <Title>
            {texts.title}
            {id && <DrawerChat analisisId={id} />}
          </Title>
        </Grid>
        {action === 'list' && user && user.permissions.analisis.create && (
          <Grid item xs={12} sm={9} align="right">
            <AddButton onClick={handleAddClick}>{texts.add}</AddButton>
          </Grid>
        )}
        {action === 'edit' && (
          <Grid item xs={12} sm={9} align="right">
            {extraInfo.map((info) => <Typography noWrap key={info}>{info}</Typography>)}
          </Grid>
        )}
      </Grid>
      {texts.subtitle && (
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={12} align="left">
            <Subtitle>{texts.subtitle}</Subtitle>
          </Grid>
        </Grid>
      )}
      <Divider />
      <div className={classes.container}>
        {action === 'list' && user && user.permissions.analisis.read && <List search={search} />}
        {action === 'create' && user && user.permissions.analisis.create && <Create />}
        {action === 'edit' && user && user.permissions.analisis.update && <Edit id={id} setExtraInfo={setExtraInfo} />}
      </div>
      <Divider />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
}));

Analisis.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

Analisis.defaultProps = {
  location: {
    search: '',
  },
  match: {
    params: {
      action: 'list',
      id: '',
    },
  },
};

export default Analisis;
