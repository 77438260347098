import React from 'react';
import {
  Dialog, DialogContent, DialogActions, Button, DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '@i18n';

const AlertDialog = ({
  onClose, open, text, children, extraOption,
}) => {
  const { components: { alertDialog: texts } } = i18n;
  return (
    <Dialog onClose={onClose} maxWidth="xs" open={open}>
      {text && <DialogTitle>{text}</DialogTitle>}
      {children && (<DialogContent>{children}</DialogContent>)}
      <DialogActions>
        {extraOption}
        <Button onClick={onClose} color="secondary" variant="contained">{texts.close}</Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.node,
  extraOption: PropTypes.node,
};

AlertDialog.defaultProps = {
  onClose: () => {},
  open: false,
  text: '',
  children: undefined,
  extraOption: undefined,
};

export default AlertDialog;
