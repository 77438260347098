import { useState, useCallback } from 'react';
import {
  getServiceEnsayosAll, postServiceEnsayos, putServiceEnsayos, deleteServiceEnsayos,
} from '../services/ensayos';

const useEnsayos = () => {
  const [state, setState] = useState({ loading: false, error: null });

  const getEnsayosAll = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      const ensayosAll = await getServiceEnsayosAll(data);
      setState({ loading: false, error: null });
      return ensayosAll;
    } catch (e) {
      setState({ loading: false, error: e });
      return null;
    }
  });

  const createEnsayo = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await postServiceEnsayos(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const updateEnsayo = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await putServiceEnsayos(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  const deleteEnsayo = useCallback(async (data) => {
    setState({ loading: true, error: null });
    try {
      await deleteServiceEnsayos(data);
      setState({ loading: false, error: null });
      return true;
    } catch (e) {
      setState({ loading: false, error: e });
      return false;
    }
  });

  return {
    isEnsayosLoading: state.loading,
    ensayosError: state.error,
    getEnsayosAll,
    createEnsayo,
    updateEnsayo,
    deleteEnsayo,
  };
};

export default useEnsayos;
