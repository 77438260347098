import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, LinearProgress, FormControl, TextField, TextareaAutosize,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import {
  useContacts, useCustomers, useSolicitudes, useStates,
} from '@hooks';
import AuthContext from '@contexts/AuthContext';
import {
  Alert, ButtonAdorned, Subtitle, MultiFileUpload,
} from '@components/atoms';
import { editSolicitudesSchema } from '@helpers/schemas';
import { getDateCustom } from '@helpers/utils';

const Edit = ({ id, setExtraInfo }) => {
  const { pages: { customers: { solicitudes: { edit: texts } } } } = i18n;
  const history = useHistory();
  const { isContactLoading, contactError } = useContacts();
  const { isCustomerLoading, customerError } = useCustomers();
  const { getStates, isStateLoading, stateError } = useStates();
  const {
    getSolicitud, editSolicitud, isSolicitudLoading, solicitudError, getFiles,
  } = useSolicitudes();
  const { user } = useContext(AuthContext);

  const [solicitud, setSolicitud] = useState({
    customer: '',
    contact: '',
    files: [],
    title: '',
    description: '',
    newDescription: '',
    state: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: solicitud,
    enableReinitialize: true,
    validationSchema: editSolicitudesSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const {
        customer, description, newDescription, ...rest
      } = values;
      const newDescr = `${description} \n${newDescription}\n[${user.name} ${user.lastname} - ${getDateCustom(new Date(), 'yyyy-MM-D HH:mm:ss')}]\n`;
      const sendEdit = { description: newDescr, ...rest };
      const updated = await editSolicitud(sendEdit);
      if (updated) {
        enqueueSnackbar(texts.success, { variant: 'success' });
        history.push('/c/solicitudes');
      }
    },
  });

  useEffect(async () => {
    setExtraInfo([]);
    const serviceSolicitud = await getSolicitud({ id });
    const serviceStates = await getStates();
    const currentState = serviceStates.find((state) => (state.id === serviceSolicitud.state.id));
    const docsFiles = await getFiles({ customerId: serviceSolicitud.contact.customer.id, solicitudId: id });

    if (currentState.editAllowed) {
      setSolicitud({
        ...solicitud,
        id: serviceSolicitud.id,
        customer: serviceSolicitud.contact ? serviceSolicitud.contact.customer : '',
        contact: serviceSolicitud.contact || '',
        files: docsFiles || [],
        title: serviceSolicitud.title || '',
        description: serviceSolicitud.description || '',
        state: currentState || {},
      });
    }
  }, []);

  return (
    <>
      {solicitudError && <Alert>{solicitudError.message}</Alert>}
      {contactError && <Alert>{contactError.message}</Alert>}
      {customerError && <Alert>{customerError.message}</Alert>}
      {stateError && <Alert>{stateError.message}</Alert>}
      { (isCustomerLoading || isContactLoading || isStateLoading || isSolicitudLoading) ? <LinearProgress /> : (
        <>
          <Grid container spacing={2}>
            {texts.subtitleData && (
            <Grid item xs={12} align="left">
              <Subtitle>{texts.subtitleData}</Subtitle>
            </Grid>
            )}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  name="title"
                  placeholder={texts.title}
                  value={form.values.title}
                  variant="outlined"
                  onChange={form.handleChange}
                  error={form.touched.title && Boolean(form.errors.title)}
                  helperText={form.touched.title && form.errors.title}
                  disabled={isSolicitudLoading}
                  autoComplete="new-title"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField value={texts.titles.history} />
              <FormControl fullWidth>
                <TextareaAutosize
                  style={{ borderColor: '#e0e0e0', fontFamily: 'Arial, Roboto', overflow: 'auto' }}
                  name="description"
                  minRows={10}
                  value={solicitud.description}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField value={texts.titles.comments} />
              <FormControl fullWidth>
                <TextareaAutosize
                  style={{ borderColor: '#e0e0e0', fontFamily: 'Arial, Roboto', overflow: 'auto' }}
                  name="newDescription"
                  placeholder={texts.descriptionPlaceholder}
                  minRows={10}
                  value={form.values.newDescription}
                  onChange={form.handleChange}
                  error={form.touched.newDescription && Boolean(form.errors.newDescription)}
                  helperText={form.touched.newDescription && form.errors.description}
                  disabled={isSolicitudLoading}
                  autoComplete="new-description"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.file}
                  name="files"
                  onChange={form.handleChange}
                  value={form.values.files}
                  multiple
                  error={form.touched.files && Boolean(form.errors.files)}
                  helperText={form.touched.files && form.errors.files && form.errors.files}
                  disabled={isSolicitudLoading}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <ButtonAdorned color="default" onClick={() => history.push('/c/solicitudes')}>
                {texts.list}
              </ButtonAdorned>
            </Grid>
            <Grid item>
              <form onSubmit={form.handleSubmit}>
                <ButtonAdorned loading={isSolicitudLoading} disabled={isSolicitudLoading} type="submit">
                  {texts.save}
                </ButtonAdorned>
              </form>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

Edit.propTypes = {
  id: PropTypes.string,
  setExtraInfo: PropTypes.func,
};

Edit.defaultProps = {
  id: '',
  setExtraInfo: () => {},
};

export default Edit;
