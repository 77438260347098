import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import i18n from '@i18n';
import { GridBorderContainer } from '@components/atoms';

const NotFound = () => {
  const { pages: { general: { notFound: texts } } } = i18n;
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${texts.title}`}</title>
      </Helmet>
      <Grid container spacing={0} className={classes.container}>
        <GridBorderContainer>
          <Grid item xs={12} align="center">
            <Typography className={classes.title}>{texts.title}</Typography>
          </Grid>
        </GridBorderContainer>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
}));

export default NotFound;
