const presupuestos = {
  title: 'Presupuestos',
  add: 'Agregar Presupuesto',
  list: {
    title: 'Presupuestos',
    columns: {
      customer: 'Cliente',
      contact: 'Contacto',
      file: 'Archivo',
      number: 'Número',
      description: 'Descripción',
      state: 'Estado',
      createdBy: 'Creado por',
      createdAt: 'Fecha creación',
    },
  },
  create: {
    customer: 'Cliente *',
    contact: 'Contacto *',
    noContacts: 'Cliente no tiene contactos',
    file: 'Archivo *',
    number: 'Nro de presupuesto *',
    description: 'Descripción',
    state: 'Estado',
    save: 'Crear',
    list: 'Volver a la lista',
    success: 'Presupuesto creado exitosamente',
  },
  view: {
    customer: 'Cliente',
    contact: 'Contacto',
    file: 'Archivo',
    fileBkp: 'Respaldo de aprobación',
    number: 'Nro de presupuesto',
    description: 'Descripción',
    state: 'Estado',
    edit: 'Editar',
    comment: 'Comentario del cambio de estado',
    list: 'Volver a la lista',
    subtitleData: 'Datos del presupuesto',
    history: {
      show: 'Mostrar todos los archivos',
      hide: 'Ocultar todos los archivos',
    },
    updatedAt: 'Ultima actualizacion',
  },
  edit: {
    customer: 'Cliente *',
    contact: 'Contacto *',
    noContacts: 'Cliente no tiene contactos',
    file: 'Archivo *',
    fileBkp: 'Respaldo de aprobación *',
    number: 'Nro de presupuesto *',
    description: 'Descripción',
    state: 'Estado',
    save: 'Guardar',
    list: 'Volver a la lista',
    comment: 'Comentario del cambio de estado',
    subtitleData: 'Datos del presupuesto',
    success: 'Presupuesto editado exitosamente',
  },

};

export default presupuestos;
